import { Instance } from 'mobx-state-tree';
import { withTypedRefs } from 'mst-gql';

import { FieldConfigModelType } from './FieldConfigModel';
import { ProductConfigModelBase } from './ProductConfigModel.base';

/* The TypeScript type of an instance of ProductConfigModel */
export interface ProductConfigModelType extends Instance<typeof ProductConfigModel.Type> {}

/* A graphql query fragment builders for ProductConfigModel */
export {
  selectFromProductConfig,
  productConfigModelPrimitives,
  ProductConfigModelSelector,
} from './ProductConfigModel.base';

type Refs = {
  formFieldsConfigs: FieldConfigModelType[];
};

/**
 * ProductConfigModel
 */
export const ProductConfigModel = withTypedRefs<Refs>()(ProductConfigModelBase);
