import { ListItem } from '@/types';

import { ItemLabel, ItemRow, ItemValue, ListContainer } from './styled';

interface ListProps {
  items: ListItem[];
  'data-testid'?: string;
  rowHeight?: string;
  labelStyles?: React.CSSProperties;
  valueStyles?: React.CSSProperties;
  rowStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
}

const List: React.FC<ListProps> = ({
  'data-testid': dataTestId,
  items,
  rowHeight,
  containerStyles,
  labelStyles,
  valueStyles,
  rowStyles,
}) => {
  return (
    <ListContainer style={containerStyles} data-testid={dataTestId}>
      {items.length &&
        items.map(
          (item) =>
            !item.hidden && (
              <ItemRow
                data-testid={item.dataTestId}
                key={item.key ?? item.label}
                style={{ ...rowStyles, height: rowHeight }}
              >
                <ItemLabel style={labelStyles} invalid={item.invalid}>
                  {item.label}
                </ItemLabel>
                {item.value !== undefined && (
                  <ItemValue style={valueStyles} invalid={item.invalid}>
                    {item.value}
                  </ItemValue>
                )}
              </ItemRow>
            ),
        )}
    </ListContainer>
  );
};

export default List;
