/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum AndonLightColor {
  gray="gray",
green="green",
red="red",
yellow="yellow"
}

/**
* AndonLightColor
*/
export const AndonLightColorEnumType = types.enumeration("AndonLightColor", [
        "gray",
  "green",
  "red",
  "yellow",
      ])
