/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * StandardRateBase
 * auto generated base class for the model StandardRateModel.
 */
export const StandardRateModelBase = ModelBase
  .named('StandardRate')
  .props({
    __typename: types.optional(types.literal("StandardRate"), "StandardRate"),
    id: types.identifier,
    label: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class StandardRateModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get label() { return this.__attr(`label`) }
}
export function selectFromStandardRate() {
  return new StandardRateModelSelector()
}

export const standardRateModelPrimitives = selectFromStandardRate().label
