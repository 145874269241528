import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Col, Row } from 'styled-bootstrap-grid';

import { Button } from '../Button';
import { Dialog, DialogMessage } from '../Dialog';

export interface ConfirmationModalProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  title: string;
  message?: string;
  onSuccess?: () => void;
  onFailure?: () => void;
  'data-testid'?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  children,
  isOpen,
  title,
  message,
  onSuccess,
  onFailure,
  'data-testid': dataTestID,
}): ReactElement => {
  const intl = useIntl();
  return (
    <Dialog open={isOpen} title={title} data-testid={dataTestID}>
      {message && <DialogMessage>{message}</DialogMessage>}
      {children}
      <Row>
        <Col col={6}>
          <Button data-testid="reject-button" onClick={onFailure}>
            {intl.formatMessage({ defaultMessage: 'No', description: 'Confirmation Modal rejection button text' })}
          </Button>
        </Col>
        <Col col={6}>
          <Button data-testid="confirm-button" primary onClick={onSuccess}>
            {intl.formatMessage({ defaultMessage: 'Yes', description: 'Confirmation Modal confirmation button text' })}
          </Button>
        </Col>
      </Row>
    </Dialog>
  );
};
