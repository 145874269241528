import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { Dialog } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { CompanyConfigModelType, ProductionMode } from '@/models';
import { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

import ManualModeView from './ManualModeView';
import PlannedModeView from './PlannedModeView';

export interface OrderExecutionModalProps {
  state: OrderExecutionModalState;
  companyConfig: CompanyConfigModelType;
}

const OrderExecutionModal: React.FC<OrderExecutionModalProps> = observer(({ state, companyConfig }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const workplaceConfig = getWorkplaceConfig(companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const productionMode = workplaceConfig?.productionMode;
  const intl = useIntl();
  const { orderExecutionModalOpened } = state;

  const renderModalView = () => {
    return productionMode === ProductionMode.MANUAL ? (
      <ManualModeView state={state} companyConfig={companyConfig} />
    ) : (
      <PlannedModeView state={state} companyConfig={companyConfig} />
    );
  };

  return (
    !!orderConfig && (
      <Dialog
        title={
          !!orderConfig?.alternativeName
            ? intl.formatMessage({
                defaultMessage: 'Execution of the order',
                description: 'Order execution modal alternative title',
              })
            : intl.formatMessage({
                defaultMessage: 'Execution of the order',
                description: 'Order execution modal title',
              })
        }
        open={orderExecutionModalOpened}
        contentPadding="0 40px 20px 40px"
        data-testid="order-execution-modal"
      >
        {renderModalView()}
      </Dialog>
    )
  );
});

export default OrderExecutionModal;
