import React from 'react';

import { useLocalObservable } from 'mobx-react-lite';

import { ModalStore, ModalStoreState } from '@/types';

const useHistoryItemModal = (): ModalStore => {
  return useLocalObservable<ModalStore>(() => ({
    state: { open: false, details: [] } as ModalStoreState,
    toggleOpen() {
      this.state.open = !this.state.open;
    },
    setDetails(details) {
      this.state.details = details;
    },
  }));
};
export const modalContext = React.createContext<ModalStore | null>(null);
export default useHistoryItemModal;
