import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Modal from 'styled-react-modal';

import { ThemeInterface } from '@/theme';

interface DialogContentProps {
  padding?: string;
}

export const Dialog = Modal.styled`
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.white};
  border-radius: 4px;
  background-color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.mineSchaftDark};
`;

export const DialogTitle = styled.h4`
  margin: 0;
  line-height: 2.5em;
  text-align: center;
  font-weight: normal;
  border-radius: 4px 4px 0 0;
  background: ${({ theme }) => theme.gradients.reasonsHeader};
`;

export const DialogSubtitle = styled.h4`
  margin-top: 0;
  font-weight: normal;
  > strong {
    font-weight: bold;
  }
  > span {
    display: block;
    margin-top: 0.5em;
  }
`;

export const DialogContent = styled(Container)<DialogContentProps>`
  min-width: 500px;
  padding: ${(props) => props.padding || '20px 40px'};
`;

export const DialogMessage = styled.div`
  margin: 1.5em;
  text-align: center;
`;
