import styled, { css } from 'styled-components';

import { theme } from '@/theme';

interface ICounterWrapper {
  blurred?: boolean;
  disabled?: boolean;
}

export const Title = styled.h3`
  color: white;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  padding: 8px 0;
  grid-area: 'title';
`;
export const CounterActions = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto 90px 90px 1fr auto;
  grid-template-areas: 'value decreaseAction increaseAction ';
  align-items: center;
  margin-right: 20px;
`;
export const CounterWrapper = styled.div<ICounterWrapper>`
  padding: 20px;
  margin: 2px 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'counterActions title kindIndicator';
  background-color: ${theme.colors.youtubeBlack};
  align-items: center;
  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.1;
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

interface ActionButtonProps {
  marginRight?: string;
  gridArea: string;
  secondary?: boolean;
  selected?: boolean;
}
export const ActionButton = styled.button<ActionButtonProps>`
  background: rgba(118, 118, 128, 0.24);
  border-radius: 6px;
  padding: 20px 35px;
  color: rgba(235, 235, 245, 0.6);
  font-size: 20px;
  line-height: 26px;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
  ${({ secondary }) =>
    secondary &&
    css`
      background: #4f4f4f;
      padding: 22px 35px;
      line-height: 0;
    `}
  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}
  ${({ selected }) =>
    selected &&
    css`
      padding: 17px 32px;
      border: 3px solid #3897fe;
    `};
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}
`;

export const KindIndicator = styled.img`
  width: 24px;
  grid-area: 'kindIndicator';
`;
