import { FormattedMessage, useIntl } from 'react-intl';

import { Col, Row } from 'styled-bootstrap-grid';

import { Button, Dialog } from '@/components/core';
import type { HistoryItemDetail } from '@/types';

import { DetailLabel, DetailRow, DetailsContainer, DetailValue } from './styled';

interface HistoryItemModalProps {
  details: HistoryItemDetail[];
  open: boolean;
  handleClose: () => void;
}

const HistoryItemModal: React.FC<HistoryItemModalProps> = ({ details, open, handleClose }) => {
  const intl = useIntl();

  const renderRow = (detail: HistoryItemDetail) => (
    <DetailRow key={detail.label}>
      <DetailLabel col={6}>{detail.label}</DetailLabel>
      <DetailValue col={6}>{detail.format ? detail.format(detail.value) : (detail.value as string)}</DetailValue>
    </DetailRow>
  );

  return (
    <Dialog
      title={intl.formatMessage({
        defaultMessage: 'Details',
        description: 'History item modal title',
      })}
      open={open}
      data-testid="history-item-modal"
    >
      <DetailsContainer data-testid="history-item-modal-details">
        {details.map((detail) => renderRow(detail))}
        <Row>
          <Col col={6} offset={6} style={{ paddingRight: 20 }}>
            <Button data-testid="history-item-modal-close-button" onClick={handleClose} primary>
              <FormattedMessage defaultMessage="Close" description="History item modal close button text" />
            </Button>
          </Col>
        </Row>
      </DetailsContainer>
    </Dialog>
  );
};

export default HistoryItemModal;
