import React from 'react';
import { IntlShape } from 'react-intl';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip, Typography } from '@mui/material';
import { captureException, notify } from 'andoncloud-sdk';
import { ClientError } from 'graphql-request';

import { MutationErrorModelType } from '@/models';

export const getClientErrorText = (error: ClientError): string => {
  const { errors } = error?.response || {};

  return errors ? errors[0].message : '';
};

export const isValidationError = (error: MutationErrorModelType): boolean => !!error.field;

export const getMutationErrorText = (error: MutationErrorModelType): string => {
  const { messages } = error;

  return messages ? messages[0] : '';
};

export const handleQueryError = (
  error: ClientError | Record<string, unknown>,
  onError?: (error: string) => void,
): void => {
  logError(error);

  if (onError) {
    const clientErrorText = getClientErrorText(error as ClientError);

    if (clientErrorText) {
      onError(clientErrorText);
    } else {
      onError('message' in error ? (error.message as string) : 'Internal server error');
    }
  }
};

export const notifyServerError = (error: string, intl: IntlShape) => {
  notify.error(
    <React.Fragment>
      <Typography mr={3}>
        {intl.formatMessage({
          defaultMessage: 'Oops... something went wrong',
          description: 'Notify server error message',
        })}
      </Typography>
      <Tooltip title={error} arrow>
        <HelpOutlineIcon />
      </Tooltip>
    </React.Fragment>,
  );
};

export const logError = (error: unknown) => {
  if (process.env.NODE_ENV === 'production') {
    captureException(error);
  } else {
    console.error(error);
  }
};
