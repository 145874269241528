import styled from 'styled-components';

interface TextTruncateProps {
  lines?: number;
}

export const TextTruncate = styled.div<TextTruncateProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
