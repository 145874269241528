import { IHasNestedPermittedChildrenTransitions } from '@/types';

import { isTransitionPermitted } from './isTransitionPermitted';

export const hasNestedPermittedChildrenTransitions = ({
  toReason,
  reasons,
  statusChangeTransitionPermissions,
}: IHasNestedPermittedChildrenTransitions): boolean => {
  if (toReason.leaf) return isTransitionPermitted({ statusChangeTransitionPermissions, toReasonId: toReason.id });
  const currentReasonChildrenLeafs = reasons?.filter((reason) => reason.parentId === toReason.id && toReason.leaf);
  const currentReasonChildrenFolders = reasons?.filter((reason) => reason.parentId === toReason.id && !toReason.leaf);
  if (
    currentReasonChildrenLeafs?.some((reason) =>
      isTransitionPermitted({ statusChangeTransitionPermissions, toReasonId: reason.id }),
    )
  )
    return true;
  if (
    currentReasonChildrenFolders?.some((reason) =>
      hasNestedPermittedChildrenTransitions({
        toReason: reason,
        reasons,
        statusChangeTransitionPermissions,
      }),
    )
  )
    return true;
  return false;
};
