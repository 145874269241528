import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { CenteringContainer } from '@/components/core';
import { ReasonModelType } from '@/models';
import type { ColumnsStore, NullableID } from '@/types';

import ReasonRow from '../ReasonRow';

import { Column, ColumnBody, ColumnHeader } from './styled';

interface ReasonsColumnProps {
  parentID: NullableID;
  columnID: string;
  sortedReasons: ReasonModelType[];
  columns: ColumnsStore;
  isLast: boolean;
}

const ReasonsColumn: React.FC<ReasonsColumnProps> = observer(
  ({ parentID, columnID, sortedReasons, columns, isLast: isLastColumn }: ReasonsColumnProps): React.ReactElement => {
    const intl = useIntl();
    const reasons = useMemo<ReasonModelType[]>(
      () => sortedReasons.filter((reason) => reason.parentId === parentID),
      [sortedReasons, parentID],
    );
    const emptyColumnMessage = intl.formatMessage({
      defaultMessage: 'No reasons to be displayed here',
      description: 'Reasons column message when no content',
    });
    const columnTitle =
      sortedReasons.find((reason) => reason.id === parentID)?.name ||
      intl.formatMessage({ defaultMessage: 'All', description: 'Reasons column title all' });

    return (
      <Column transition={{ duration: 0.55 }} initial="hidden" animate="visible" exit="hidden" data-testid={columnID}>
        <ColumnHeader data-testid={`column-title-${columnTitle}`}>{columnTitle}</ColumnHeader>
        <ColumnBody>
          {reasons.length ? (
            reasons.map((reason) => (
              <ReasonRow
                isLastColumn={isLastColumn}
                key={reason.id}
                data-testid={`row-${reason.id}`}
                reason={reason}
                selected={columns.isSelected(parentID, reason.id)}
                columns={columns}
              />
            ))
          ) : (
            <CenteringContainer data-testid="empty-column-message">{emptyColumnMessage}</CenteringContainer>
          )}
        </ColumnBody>
      </Column>
    );
  },
);

export default ReasonsColumn;
