import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import {
  AndonLightColor,
  OrderExecutionModelType,
  ShiftModelType,
  StatusChangeModelType,
  UserPresenceModelType,
} from '@/models';
import { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import { getHistoryRowTestId } from '@/testing';

import HistoryOrderExecution from '../HistoryOrderExecution';
import Shift from '../Shift';
import StatusChange from '../StatusChange';
import UserPresence from '../UserPresence';

import animationVariants from './animationVariants';
import { DateSeparator, HistoryItemContainer, HistoryItemContent, HistoryItemTime } from './styled';

export interface HistoryRowProps {
  item: HistoryItemModelType;
  groupedCount?: number;
  toggleCollapsed?: () => void;
  collapsed?: boolean;
}

const HistoryRow: React.FC<HistoryRowProps> = ({ item, groupedCount, toggleCollapsed, collapsed }) => {
  if (item.type === HistoryItemType.ORDER_EXECUTION_START || item.type === HistoryItemType.ORDER_EXECUTION_END) {
    return (
      <HistoryItemContainer $withoutStyles>
        <HistoryOrderExecution orderExecution={item.instance as OrderExecutionModelType} type={item.type} />
      </HistoryItemContainer>
    );
  }
  if (item.type === HistoryItemType.DATE_SEPARATOR) {
    return (
      <HistoryItemContainer $withoutStyles>
        <DateSeparator
          initial="hidden"
          animate="visible"
          variants={animationVariants}
          data-testid={getHistoryRowTestId(item)}
        >
          {item.date?.format('YYYY-MM-DD')}
        </DateSeparator>
      </HistoryItemContainer>
    );
  }
  const renderItem = () => {
    switch (item.type) {
      case HistoryItemType.SHIFT_START:
      case HistoryItemType.SHIFT_END:
        return <Shift shift={item.instance as ShiftModelType} type={item.type} />;
      case HistoryItemType.CHECK_IN:
      case HistoryItemType.CHECK_OUT:
        return (
          <UserPresence
            userPresence={item.instance as UserPresenceModelType}
            type={item.type}
            groupedCount={groupedCount}
            toggleCollapsed={toggleCollapsed}
            collapsed={collapsed}
          />
        );
      default:
        return <StatusChange status={item.instance as StatusChangeModelType} />;
    }
  };

  return (
    <HistoryItemContainer
      initial="hidden"
      animate="visible"
      variants={animationVariants}
      data-testid={getHistoryRowTestId(item, !!groupedCount)}
      color={
        // eslint-disable-next-line no-underscore-dangle
        item?.instance?.__typename === 'StatusChange'
          ? mapStatusColorToColorString(item.instance.reason.statusColor as AndonLightColor)
          : 'transparent'
      }
    >
      <HistoryItemTime $groupedCount={groupedCount}>{item.date?.format('HH:mm:ss')}</HistoryItemTime>
      <HistoryItemContent>{renderItem()}</HistoryItemContent>
    </HistoryItemContainer>
  );
};

export default HistoryRow;
