import styled, { css } from 'styled-components';

interface ButtonProps {
  readOnly?: boolean;
  arrowUp?: boolean;
  arrowDown?: boolean;
}

export const HistoryItemButton = styled.button<ButtonProps>`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 52px 1fr 22px;
  align-items: center;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  background: transparent;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  &:hover {
    transition: filter 0.4s;
    filter: brightness(1.3);
    cursor: pointer;
  }
  ${({ readOnly }) =>
    readOnly &&
    css`
      &:hover {
        cursor: unset;
      }
    `}
  ${({ arrowDown, arrowUp }) =>
    (arrowUp || arrowDown) &&
    css`
      grid-template-columns: 52px 1fr 22px;
    `}
`;
export const HistoryItemIcon = styled.img`
  width: 18px;
  margin: 0 10px;
`;

export const HistoryButtonText = styled.span`
  font-size: 16px;
  display: flex;
  margin: 6px 0;
  line-height: 20px;
  word-wrap: break-word;
`;

export const HistoryButtonIcon = styled.img``;
