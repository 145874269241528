import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Row } from 'styled-bootstrap-grid';

import { mapScreenToPath } from '@/helpers';
import { ScreenEnum, WorkplaceConfigModelType } from '@/models';
import { useWorkplaceData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

import { Button } from '../core';

import { FooterCol } from './styled';

interface WorkplaceFooterProps {
  workplaceConfig: WorkplaceConfigModelType | null;
  currentScreen?: string;
}

const WorkplaceFooter: React.FC<WorkplaceFooterProps> = ({ workplaceConfig, currentScreen }) => {
  const {
    data: { counters },
    loading,
  } = useWorkplaceData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const menuDisplayItems = workplaceConfig?.menuDisplayItems?.filter((item) => {
    if (loading) return true;

    if (item === 'COUNTERS') {
      return !!counters.length;
    }
    return true;
  }) || ['STATUS_SCREEN', 'STATUSES', 'COUNTERS', 'WORKPLACES'];
  const navigate = useNavigate();
  const intl = useIntl();

  const getMenuItemText = (item: keyof typeof ScreenEnum) => {
    switch (item) {
      case ScreenEnum.STATUS_SCREEN:
        return intl.formatMessage({
          defaultMessage: 'Status screen',
          description: 'Workplace footer status screen button text',
        });
      case ScreenEnum.STATUSES:
        return intl.formatMessage({
          defaultMessage: 'Change status',
          description: 'Workplace footer statuses button text',
        });
      case ScreenEnum.COUNTERS:
        return intl.formatMessage({
          defaultMessage: 'Counters',
          description: 'Workplace footer counters button text',
        });
      case ScreenEnum.WORKPLACES:
        return intl.formatMessage({
          defaultMessage: 'Workplaces',
          description: 'Workplace footer workplaces button text',
        });
      default:
        return '';
    }
  };

  const renderMenuButton = (item: keyof typeof ScreenEnum) => (
    <Button
      data-testid={`workplace-footer-${item}-button`}
      style={{ margin: 0, paddingLeft: '10px', textAlign: 'left', height: '50px' }}
      onClick={() => {
        navigate(mapScreenToPath(item, workplaceID));
      }}
      selected={currentScreen === item}
    >
      {getMenuItemText(item)}
    </Button>
  );

  return (
    <Row style={{ margin: '10px -10px 0 -10px' }}>
      {menuDisplayItems?.length ? (
        <>
          <FooterCol col={4} style={{ paddingLeft: '10px' }}>
            {renderMenuButton(menuDisplayItems[0])}
          </FooterCol>
          {menuDisplayItems.length >= 2 && <FooterCol col={2}>{renderMenuButton(menuDisplayItems[1])}</FooterCol>}
          {menuDisplayItems.length >= 3 && <FooterCol col={2}>{renderMenuButton(menuDisplayItems[2])}</FooterCol>}
          {menuDisplayItems.length >= 4 && <FooterCol col={4}>{renderMenuButton(menuDisplayItems[3])}</FooterCol>}
        </>
      ) : null}
    </Row>
  );
};

export default WorkplaceFooter;
