import { createContext, useContext, useState } from 'react';

interface LastWorkplace {
  lastWorkplaceId?: string;
  setLastWorkplaceId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface LastWorkplaceProviderProps {
  children: React.ReactNode;
}

export const LastWorkplaceContext = createContext<LastWorkplace>({
  lastWorkplaceId: undefined,
  setLastWorkplaceId: () => {},
});

export const LastWorkplaceProvider: React.FC<LastWorkplaceProviderProps> = ({ children }) => {
  const [lastWorkplaceId, setLastWorkplaceId] = useState<string>();

  return (
    <LastWorkplaceContext.Provider value={{ lastWorkplaceId, setLastWorkplaceId }}>
      {children}
    </LastWorkplaceContext.Provider>
  );
};

export const useLastWorkplace = (): LastWorkplace => {
  return useContext<LastWorkplace>(LastWorkplaceContext);
};
