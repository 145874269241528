import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReasonsParamTypes } from '@/types';

import { getLastOrderExecution } from '../helpers/orders-executions';
import { OrderExecutionModelType } from '../models';

import useStore from './useStore';

const useLastOrderExecution = (): OrderExecutionModelType | null => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [lastOrderExecution, setLastOrderExecution] = useState<OrderExecutionModelType | null>(null);
  const rootStore = useStore();

  useEffect(() => {
    setLastOrderExecution(getLastOrderExecution(rootStore, workplaceID));
  }, [rootStore, rootStore.orderExecutions.size, workplaceID]);

  return lastOrderExecution;
};

export default useLastOrderExecution;
