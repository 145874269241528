import { Dialog as StyledDialog, DialogContent, DialogTitle } from './styled';

interface DialogProps {
  children: React.ReactNode;
  title: string;
  open?: boolean;
  contentPadding?: string;
  'data-testid'?: string;
}

export const Dialog: React.FC<DialogProps> = ({ children, title, open, contentPadding, 'data-testid': dataTestID }) => {
  return (
    <StyledDialog data-testid={dataTestID} isOpen={!!open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent padding={contentPadding}>{children}</DialogContent>
    </StyledDialog>
  );
};

export { DialogMessage, DialogSubtitle } from './styled';
