import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';
import { v4 as uuidv4 } from 'uuid';

import { Button, Input } from '@/components/core';
import useForm from '@/forms';
import { prepareErrorMessages } from '@/forms/helpers';
import type { FormProps } from '@/forms/types';
import { notifyServerError } from '@/helpers/errors';
import { formatECMAScriptRegexp } from '@/helpers/formatECMAScriptRegexp';
import { useStore } from '@/hooks';
import {
  CreateProductPayloadModelType,
  FieldConfigModelType,
  ProductConfigModelType,
  ProductModel,
  ProductModelType,
  useQuery,
} from '@/models';
import productsMutations from '@/mutations/products';
import type { OrderExecutionModalState } from '@/types';

import { FieldError, Form, FormControl } from './styled';

interface ProductFormProps extends FormProps<typeof ProductModel> {
  orderExecutionModalState: OrderExecutionModalState;
  productConfig: ProductConfigModelType;
}

const ProductForm: React.FC<ProductFormProps> = observer(({ options, orderExecutionModalState, productConfig }) => {
  const rootStore = useStore();
  const { setQuery } = useQuery();
  const intl = useIntl();
  const { productInputValue, setSelectedProduct, setProductModalOpened } = orderExecutionModalState;

  const modelInitial = { id: uuidv4(), name: '', number: productInputValue };
  const fieldsConfigs = productConfig.formFieldsConfigs;
  const fieldsNames = fieldsConfigs.map((field) => field.name as string);

  const getFieldConfig = (name: string) => fieldsConfigs.find((field) => field.name === name) as FieldConfigModelType;

  const { fields, submit, saving } = useForm<typeof ProductModel>(ProductModel, undefined, modelInitial, {
    addMode: false,
    fields: fieldsNames,
    backend: {
      save: async (product: ProductModelType) => {
        const query = productsMutations.create({
          rootStore,
          product,
          onSuccess: () => {
            notify.success(
              !!productConfig?.alternativeName
                ? intl.formatMessage({
                    defaultMessage: 'Product successfully created',
                    description: 'Product form notify alternative message success',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Product successfully created',
                    description: 'Product form notify message success',
                  }),
            );
          },
          onError: (error, isValidationError) => {
            if (!isValidationError) {
              notifyServerError(error, intl);
            }
          },
        });
        setQuery(query);

        const { createProduct } = (await query.currentPromise()) as {
          createProduct: CreateProductPayloadModelType;
        };

        if (createProduct.errors) {
          return {
            errorValidations: [
              {
                id: 'save',
                messages: prepareErrorMessages(createProduct.errors),
              },
            ],
          };
        }
        setSelectedProduct(product);
        setProductModalOpened(false);

        return null;
      },
    },
    extraValidation: (accessor, value: string) => {
      const fieldName = accessor.path.replace('/', '');
      const fieldRegexp = formatECMAScriptRegexp(getFieldConfig(fieldName).regexp || '');

      if (value && fieldsNames.includes(fieldName) && fieldRegexp) {
        if (RegExp(fieldRegexp).exec(value)) return false;

        return intl.formatMessage({
          defaultMessage: 'The value provided has an invalid format',
          description: 'Product form field extra validation error',
        });
      }
      return false;
    },
    requiredError: intl.formatMessage({
      defaultMessage: 'This field is required',
      description: 'Product form field required text',
    }),
    ...options,
  });

  useEffect(() => {
    // run validation on mount
    fields.number.validate();
  }, [fields]);

  const shouldRenderField = (name: string) => fieldsNames.includes(name);

  return (
    <Form data-testid="product-form" onSubmit={submit} autoComplete="off" noValidate>
      {shouldRenderField('name') && (
        <FormControl>
          <Input
            data-testid="product-name-field"
            placeholder={
              !!productConfig?.alternativeName
                ? intl.formatMessage({
                    defaultMessage: 'Product name',
                    description: 'Product form name field alternative placeholder',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Product name',
                    description: 'Product form name field placeholder',
                  })
            }
            endAdornment={getFieldConfig('name').unit}
            {...fields.name.inputProps}
            error={!!fields.name.error}
          />
          <FieldError data-testid="product-name-field-error">{fields.name.error}</FieldError>
        </FormControl>
      )}
      {shouldRenderField('number') && (
        <FormControl>
          <Input
            data-testid="product-number-field"
            placeholder={
              !!productConfig?.alternativeName
                ? intl.formatMessage({
                    defaultMessage: 'Product number',
                    description: 'Product form number field alternative placeholder',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Product number',
                    description: 'Product form number field placeholder',
                  })
            }
            endAdornment={getFieldConfig('number').unit}
            {...fields.number.inputProps}
            error={!!fields.number.error}
          />
          <FieldError data-testid="product-number-field-error">{fields.number.error}</FieldError>
        </FormControl>
      )}
      <Row>
        <Col col={6}>
          <Button type="button" data-testid="product-form-cancel-button" onClick={() => setProductModalOpened(false)}>
            <FormattedMessage defaultMessage="Cancel" description="Product form cancel button" />
          </Button>
        </Col>
        <Col col={6}>
          <Button type="submit" data-testid="product-form-submit-button" disabled={saving} primary>
            {!!productConfig?.alternativeName ? (
              <FormattedMessage
                defaultMessage="Add product"
                description="Product form submit button alternative text"
              />
            ) : (
              <FormattedMessage defaultMessage="Add product" description="Product form submit button" />
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default ProductForm;
