import { useState } from 'react';

import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { useLocalObservable } from 'mobx-react-lite';
import { IAnyModelType, Instance } from 'mobx-state-tree';
import type { FieldAccessor, FormDefinition, GroupDefinition, RawType, ValueType } from 'mstform';
import { Form, FormState } from 'mstform';
import { ValidateOptions } from 'mstform/dist/src/validate-options';

import definitions from './definitions';
import type { FormStateOptions } from './types';

type FieldAccess<D extends FormDefinition<IAnyModelType>, K extends keyof D> = FieldAccessor<
  RawType<D[K]>,
  ValueType<D[K]>
>;

interface FormUtils<Model extends IAnyModelType> {
  state: FormState<FormDefinition<Model>, GroupDefinition<FormDefinition<Model>>, Model>;
  fields: Record<keyof FormDefinition<Model>, FieldAccess<FormDefinition<Model>, keyof FormDefinition<Model>>>;
  instance: Instance<Model>;
  submit: (e: React.FormEvent<HTMLFormElement>, validateOptions?: ValidateOptions) => Promise<boolean>;
  saving: boolean;
}

const useForm = <Model extends IAnyModelType>(
  model: Model,
  instance?: Instance<Model>,
  initial?: Record<string, unknown>,
  options?: FormStateOptions<Model>,
): FormUtils<Model> => {
  const [saving, setSaving] = useState<boolean>(false);
  const { fields, excludeFields } = options || {};

  let formDefinition: FormDefinition<Model>;

  if (fields && excludeFields) {
    throw new Error('You have to specify only one of "fields" and "excludeFields"');
  }
  if (fields?.length) {
    formDefinition = pick(definitions[model.name], fields);
  } else {
    formDefinition = omit(definitions[model.name], excludeFields || []);
  }
  const form = new Form(model, formDefinition);
  const formInstance = instance || model.create(initial);

  const state = useLocalObservable(() => form.state(formInstance, options));

  const getFields = () => {
    const result = {};

    Object.keys(formDefinition).forEach((name) => {
      Object.assign(result, { [name]: state.field(name) });
    });

    return result as Record<
      keyof typeof formDefinition,
      FieldAccess<FormDefinition<Model>, keyof typeof formDefinition>
    >;
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>, validateOptions?: ValidateOptions) => {
    e.preventDefault();

    setSaving(true);

    const success = await state.save(validateOptions);

    setSaving(false);

    return success;
  };

  return {
    state,
    fields: getFields(),
    instance: formInstance,
    submit,
    saving,
  };
};

export default useForm;
