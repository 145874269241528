/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum Period {
  CURRENT_SHIFT="CURRENT_SHIFT",
LAST_SHIFT="LAST_SHIFT"
}

/**
* Period
*/
export const PeriodEnumType = types.enumeration("Period", [
        "CURRENT_SHIFT",
  "LAST_SHIFT",
      ])
