import styled from 'styled-components';

export const Form = styled.form``;

export const FormControl = styled.div``;

export const FormGroup = styled.div``;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.dodgerBlue};
  > span {
    text-indent: 1em;
  }
`;

export const FieldError = styled.span`
  display: block;
  margin-top: 0.25em;
  font-size: 95%;
  font-style: italic;
  color: ${({ theme }) => theme.colors.cardinal};
`;

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
`;
