import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { Dialog } from '@/components/core';
import ProductForm from '@/components/forms/ProductForm';
import { ProductConfigModelType } from '@/models';
import type { OrderExecutionModalState } from '@/types';

interface ProductModalProps {
  orderExecutionModalState: OrderExecutionModalState;
  productConfig: ProductConfigModelType;
}

const ProductModal: React.FC<ProductModalProps> = observer(({ orderExecutionModalState, productConfig }) => {
  const intl = useIntl();
  const { productModalOpened } = orderExecutionModalState;

  return (
    <Dialog
      title={
        !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'New product',
              description: 'Product modal alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'New product',
              description: 'Product modal title',
            })
      }
      open={productModalOpened}
      contentPadding="0 40px 20px 40px"
      data-testid="product-modal"
    >
      <ProductForm orderExecutionModalState={orderExecutionModalState} productConfig={productConfig} />
    </Dialog>
  );
});

export default ProductModal;
