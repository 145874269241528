import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useWorkplaceData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

import { getLastStatusChange } from '../helpers/status-changes';
import { StatusChangeModelType } from '../models';

import useStore from './useStore';

const useLastStatusChange = (): StatusChangeModelType | null => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { loading: workplaceDataLoading } = useWorkplaceData();
  const [lastStatusChange, setLastStatusChange] = useState<StatusChangeModelType | null>(null);
  const rootStore = useStore();

  useEffect(() => {
    setLastStatusChange(getLastStatusChange(rootStore, workplaceID));
  }, [rootStore, rootStore.statusChanges.size, workplaceID, workplaceDataLoading]);

  return lastStatusChange;
};

export default useLastStatusChange;
