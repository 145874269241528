import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { ClientError } from 'graphql-request';
import Cookies from 'js-cookie';
import mergeWith from 'lodash.mergewith';
import pick from 'lodash.pick';

import { getClientErrorText } from '@/helpers/errors';
import { companyConfigQS, departmentsQS, metricsQS, shiftsQS, usersQS, workplacesQS } from '@/queries';
import { CompanyQueriesResults } from '@/types';

import { useQuery } from '../models';

const useQueryCompanyData = (): CompanyQueriesResults => {
  const intl = useIntl();

  const results = [
    useQuery((store) => store.queryCompanyConfig({}, companyConfigQS.ALL)),
    useQuery((store) => store.queryDepartments({}, departmentsQS.ALL)),
    useQuery((store) => store.queryMetrics({}, metricsQS.ALL)),
    useQuery((store) => store.queryShifts({}, shiftsQS.ALL)),
    useQuery((store) => store.queryUsers({}, usersQS.ALL)),
    useQuery((store) => store.queryWorkplaces({ filter: { deactivated_equals: 'false' } }, workplacesQS.ALL)),
  ];

  const mergedResults = mergeWith(
    {},
    ...results.map((result) => pick(result, ['data', 'loading', 'error'])),
    (obj: unknown, src: unknown) => {
      if (typeof obj === 'object') {
        return { ...obj, ...(src as Record<string, unknown>) };
      }
      if (typeof obj === 'boolean') {
        return obj || src;
      }
      return src;
    },
  ) as CompanyQueriesResults;

  if (
    !mergedResults.loading &&
    !mergedResults.data?.companyConfig?.devicesConfigs.find(
      (config) => config.deviceId === Cookies.get('device_config_id'),
    )
  ) {
    Cookies.remove('device_config_id');
    Cookies.remove('default_workplace_id');
  }
  if (
    !mergedResults.loading &&
    Cookies.get('device_config_id') &&
    Cookies.get('default_workplace_id') !==
      mergedResults.data?.companyConfig?.devicesConfigs.find(
        (config) => config.deviceId === Cookies.get('device_config_id'),
      )?.defaultWorkplaceId
  )
    Cookies.set(
      'default_workplace_id',
      mergedResults.data?.companyConfig?.devicesConfigs.find(
        (config) => config.deviceId === Cookies.get('device_config_id'),
      )?.defaultWorkplaceId as string,
    );

  useEffect(() => {
    if (!mergedResults.loading && mergedResults.error) {
      notify.error(
        intl.formatMessage(
          {
            defaultMessage: 'An error occurred while fetching company data: {error}',
            description: 'Company data fetching error message',
          },
          { error: getClientErrorText(mergedResults.error as ClientError) },
        ),
      );
    }
  }, [mergedResults, intl]);

  return mergedResults;
};

export default useQueryCompanyData;
