/* eslint-disable promise/catch-or-return, promise/always-return */
import type { UserData } from 'andoncloud-sdk';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import moment, { Moment } from 'moment';
import { Query } from 'mst-gql';
import { v4 as uuidv4 } from 'uuid';

import { getMutationErrorText, handleQueryError, isValidationError } from '@/helpers/errors';
import { ReasonModelType, RootStoreType, StatusChangeModel, StatusChangeModelType, UserModel } from '@/models';
import HistoryItemModel, { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import { statusChangesQS } from '@/queries';

interface ICreateStatusChange {
  rootStore: RootStoreType;
  workplaceId: string;
  userData: UserData;
  lastStatusChange: StatusChangeModelType | null;
  reason: ReasonModelType;
  reasonHasBeenRecommended: boolean;
  note: string | undefined;
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string, isValidationError?: boolean) => void;
}

const create = ({
  rootStore,
  workplaceId,
  userData,
  lastStatusChange,
  reason,
  reasonHasBeenRecommended,
  note,
  optimisticUpdate,
  revertUpdate,
  onSuccess,
  onError,
}: ICreateStatusChange): Query => {
  const prevStatusChangeFinishedAt = lastStatusChange?.finishedAt?.clone();

  const author = UserModel.create({
    id: userData.id.toString(),
    login: userData.email,
    name: userData.name,
  });

  const statusChange = StatusChangeModel.create({
    id: uuidv4(),
    workplaceId,
    reasonHasBeenRecommended,
    startedAt: moment().toISOString(),
    note,
  });

  const historyItem = HistoryItemModel.create({
    date: statusChange.startedAt as Moment,
    instance: castToReferenceSnapshot(statusChange),
    type: HistoryItemType.STATUS_CHANGE,
  });
  statusChange.update({ author, reason, order: lastStatusChange?.order });

  let createdDateSeparatorHistoryItem: HistoryItemModelType;

  const query = rootStore.mutateCreateStatusChange(
    {
      id: statusChange.id,
      workplaceId,
      userId: author.id,
      reasonId: reason.id,
      reasonHasBeenRecommended,
      startedAt: (statusChange.startedAt as Moment).toISOString(),
      note,
    },
    statusChangesQS.CREATE_STATUS_CHANGE_PAYLOAD_ALL,
    () => {
      lastStatusChange?.update({ finishedAt: statusChange.startedAt });

      rootStore.addStatusChange(statusChange);

      if (!rootStore.historyItems[0]?.date?.isSame(historyItem.date, 'day')) {
        createdDateSeparatorHistoryItem = HistoryItemModel.create({
          date: statusChange.startedAt as Moment,
          type: HistoryItemType.DATE_SEPARATOR,
        });
        rootStore.addHistoryItem(createdDateSeparatorHistoryItem);
      }
      rootStore.addHistoryItem(historyItem);

      rootStore.workplaces.get(workplaceId)?.updateCurrentStatusChange(statusChange);

      if (optimisticUpdate) optimisticUpdate();
    },
  );
  const revert = () => {
    if (revertUpdate) revertUpdate();

    if (createdDateSeparatorHistoryItem) {
      rootStore.removeHistoryItem(createdDateSeparatorHistoryItem);
    }
    rootStore.removeHistoryItem(historyItem);
    rootStore.removeStatusChange(statusChange);

    if (lastStatusChange) {
      lastStatusChange.update({ finishedAt: prevStatusChangeFinishedAt });

      rootStore.workplaces.get(workplaceId)?.updateCurrentStatusChange(lastStatusChange);
    }
  };

  query.then(
    ({ createStatusChange }) => {
      const { errors } = createStatusChange;

      if (errors?.length) {
        if (onError) onError(getMutationErrorText(errors[0]), isValidationError(errors[0]));
        revert();
        return;
      }
      if (onSuccess) onSuccess();
    },
    (error) => {
      handleQueryError(error, onError);
      revert();
    },
  );
  return query;
};

export default { create };
