import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { Dialog } from '@/components/core';
import OrderForm from '@/components/forms/OrderForm';
import { OrderConfigModelType } from '@/models';
import type { OrderExecutionModalState } from '@/types';

interface OrderModalProps {
  orderExecutionModalState: OrderExecutionModalState;
  orderConfig: OrderConfigModelType;
}

const OrderModal: React.FC<OrderModalProps> = observer(({ orderExecutionModalState, orderConfig }) => {
  const intl = useIntl();
  const { orderModalOpened } = orderExecutionModalState;

  return (
    <Dialog
      title={
        !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'New order',
              description: 'Order modal alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'New order',
              description: 'Order modal title',
            })
      }
      open={orderModalOpened}
      contentPadding="0 20px 20px 20px"
      data-testid="order-modal"
    >
      <OrderForm orderExecutionModalState={orderExecutionModalState} orderConfig={orderConfig} />
    </Dialog>
  );
});

export default OrderModal;
