/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Instance } from 'mobx-state-tree';

import { WorkplaceEventSubscriptionPayloadModelBase } from './WorkplaceEventSubscriptionPayloadModel.base';

/* The TypeScript type of an instance of WorkplaceEventSubscriptionPayloadModel */
export interface WorkplaceEventSubscriptionPayloadModelType
  extends Instance<typeof WorkplaceEventSubscriptionPayloadModel.Type> {}

/* A graphql query fragment builders for WorkplaceEventSubscriptionPayloadModel */
export {
  selectFromWorkplaceEventSubscriptionPayload,
  workplaceEventSubscriptionPayloadModelPrimitives,
  WorkplaceEventSubscriptionPayloadModelSelector,
} from './WorkplaceEventSubscriptionPayloadModel.base';

/**
 * WorkplaceEventSubscriptionPayloadModel
 */
export const WorkplaceEventSubscriptionPayloadModel = WorkplaceEventSubscriptionPayloadModelBase;
