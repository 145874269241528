import type { IntlShape } from 'react-intl';

import { converters, Field } from 'mstform';

interface Context {
  intl: IntlShape;
}

export default {
  number: new Field(converters.string, {
    required: true,
    conversionError: ({ intl }: Context) => {
      return intl.formatMessage({
        defaultMessage: 'The value provided is not valid',
        description: 'Order form number field conversion error',
      });
    },
  }),
};
