import { RootStoreType, StatusChangeModelType } from '@/models';

export const getWorkplaceStatusChanges = (
  workplaceId: string,
  statusChanges: StatusChangeModelType[],
): StatusChangeModelType[] => {
  return statusChanges.filter((statusChange) => statusChange.workplaceId === workplaceId);
};

export const getLastStatusChange = (rootStore: RootStoreType, workplaceId: string): StatusChangeModelType | null => {
  return [...rootStore.statusChanges.values()]
    .filter((status) => status.workplaceId === workplaceId)
    .sort((v1, v2) => {
      if (v2.startedAt) return v2.startedAt.diff(v1.startedAt);
      return 0;
    })[0];
};

export const getPrevStatusChange = (rootStore: RootStoreType, workplaceId: string): StatusChangeModelType | null => {
  if (rootStore.statusChanges.size >= 2) {
    return [...rootStore.statusChanges.values()]
      .filter((status) => status.workplaceId === workplaceId)
      .sort((v1, v2) => {
        if (v2.startedAt) return v2.startedAt.diff(v1.startedAt);
        return 0;
      })[1];
  }
  return null;
};

export const getDurationText = (status: StatusChangeModelType): string =>
  `${status.durationMinutes(new Date())} min ${status.durationSeconds(new Date())} sek`;
