/* eslint-disable */
/* tslint:disable */

import { IHasNestedPermittedChildrenTransitions, IIsTransitionPermittedProps } from '@/types';
import { last } from 'lodash';
import { Instance, types } from 'mobx-state-tree';
import { StatusChangeModelType, StatusChangeTransitionPermissionModelType } from '.';
import { ReasonModelBase } from './ReasonModel.base';

/* The TypeScript type of an instance of ReasonModel */
export interface ReasonModelType extends Instance<typeof ReasonModel.Type> {}

/* A graphql query fragment builders for ReasonModel */
export { selectFromReason, reasonModelPrimitives, ReasonModelSelector } from './ReasonModel.base';

/**
 * ReasonModel
 */

export const ReasonModel = ReasonModelBase.props({
  parentId: types.union(types.null, types.string),
});
