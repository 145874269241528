/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CounterDirectoryBase
 * auto generated base class for the model CounterDirectoryModel.
 */
export const CounterDirectoryModelBase = ModelBase
  .named('CounterDirectory')
  .props({
    __typename: types.optional(types.literal("CounterDirectory"), "CounterDirectory"),
    directoryId: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    position: types.union(types.undefined, types.integer),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CounterDirectoryModelSelector extends QueryBuilder {
  get directoryId() { return this.__attr(`directoryId`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get position() { return this.__attr(`position`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
}
export function selectFromCounterDirectory() {
  return new CounterDirectoryModelSelector()
}

export const counterDirectoryModelPrimitives = selectFromCounterDirectory().directoryId.name.position.workplaceId
