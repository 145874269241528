import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CounterModelType } from '@/models';
import { useWorkplaceData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

// TODO deprecated
export const useCurrentWorkplaceCounters = (): { loading: boolean; counters?: CounterModelType[] } => {
  const { data, loading } = useWorkplaceData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;

  const [counters, setCounters] = useState<CounterModelType[]>();

  useEffect(() => {
    if (!loading) setCounters(data?.counters?.filter((counter) => counter.workplaceId === workplaceID));
  }, [data?.counters, loading, workplaceID]);

  return { loading, counters };
};
