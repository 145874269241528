import { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Cancel } from '@mui/icons-material';
import { Box, Chip, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import without from 'lodash.without';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import useStore from '@/hooks/useStore';
import loader from '@/images/loader.svg';
import searchIcon from '@/images/searchIcon.svg';
import { AndonLightColor, WorkplaceModelType } from '@/models';
import { useCompanyData } from '@/providers';

import { CenteringContainer, Container, Input } from '../core';

import ListContainer from './ListContainer';
import StatusColorFilter from './StatusColorFilter';
import { HeaderContainer } from './styled';

const WorkplacesList: React.FC = observer(() => {
  const rootStore = useStore();
  const intl = useIntl();
  const { data, loading } = useCompanyData();
  const [inputValue, setInputValue] = useState<string>('');
  const [statusColorSelected, setStatusColorSelected] = useState<AndonLightColor | null>(null);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [filteredWorkplaces, setFilteredWorkplaces] = useState<WorkplaceModelType[]>([]);

  useEffect(() => {
    if (!loading) {
      setFilteredWorkplaces(
        [...rootStore.workplaces.values()].filter(
          // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
          (workplace) => {
            const departmentMatch =
              !selectedDepartments.length ||
              selectedDepartments.findIndex(
                (departmentId) =>
                  (!workplace.departmentId && departmentId === '') || workplace.departmentId === departmentId,
              ) !== -1;
            const searchMatch = workplace.name?.toUpperCase().match(inputValue.toUpperCase());

            if (statusColorSelected) {
              return (
                departmentMatch &&
                searchMatch &&
                workplace.currentStatusChange?.reason.statusColor === statusColorSelected
              );
            }
            return departmentMatch && searchMatch;
          },
        ) || [],
      );
    }
  }, [rootStore, data, loading, statusColorSelected, inputValue, selectedDepartments]);

  const handleDepartmentsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      setSelectedDepartments((current) => [...current, value]);
    } else {
      setSelectedDepartments(value);
    }
  };

  const handleDepartmentDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();

    setSelectedDepartments((current) => without(current, value));
  };

  const renderHeaderContent = (): ReactElement => {
    return (
      <HeaderContainer>
        <StatusColorFilter selected={statusColorSelected} onSelect={setStatusColorSelected} />
        <Input
          data-testid="workplace-select-search-input"
          gridArea="search"
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.currentTarget.value);
          }}
          placeholder={intl.formatMessage({
            defaultMessage: 'Search for a workplace',
            description: 'Workplace select search input placeholder',
          })}
          startAdornment={<img src={searchIcon} alt="" />}
          containerStyles={{ marginTop: 0 }}
        />
        {!!data?.departments?.length && (
          <Select
            value={selectedDepartments}
            onChange={handleDepartmentsChange}
            renderValue={(selected: string[]) => {
              const getLabel = (value: string) => {
                if (value) return data.departments.find((department) => department.id === value)?.name;

                return intl.formatMessage({
                  defaultMessage: 'Other',
                  description: 'Department selected other label',
                });
              };
              if (selected.length === 0) {
                return (
                  <em style={{ fontWeight: 200 }}>
                    {intl.formatMessage({
                      defaultMessage: 'Select department',
                      description: 'Department select placeholder',
                    })}
                  </em>
                );
              }
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getLabel(value)}
                      size="small"
                      color="primary"
                      deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                      onDelete={(e) => handleDepartmentDelete(e, value)}
                    />
                  ))}
                </Box>
              );
            }}
            sx={{ marginRight: '20px' }}
            multiple
            displayEmpty
          >
            {data.departments.map((department) => (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            ))}
            <MenuItem key="other" value="">
              {intl.formatMessage({
                defaultMessage: 'Other (no specific department)',
                description: 'Department select menu item other text',
              })}
            </MenuItem>
          </Select>
        )}
      </HeaderContainer>
    );
  };

  if (loading) {
    return (
      <Row>
        <Col col={12}>
          <Container
            style={{ height: 'calc(100vh - 85px)', marginTop: 0 }}
            extraHeaderContent={renderHeaderContent()}
            data-testid="workplace-select-container-loading"
          >
            <CenteringContainer>
              <img src={loader} width="500px" alt="" />
            </CenteringContainer>
          </Container>
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col col={12}>
        <Container
          style={{ height: 'calc(100vh - 92px)', marginTop: 0 }}
          extraHeaderContent={renderHeaderContent()}
          data-testid="workplace-select-container"
        >
          {filteredWorkplaces.length ? (
            <ListContainer data-testid="workplaces-list-container" items={filteredWorkplaces} />
          ) : (
            <CenteringContainer>
              {intl.formatMessage({
                defaultMessage: 'No workplaces found',
                description: 'Workplace select empty list message',
              })}
            </CenteringContainer>
          )}
        </Container>
      </Col>
    </Row>
  );
});

export default WorkplacesList;
