import { useLocalObservable } from 'mobx-react-lite';

import { getOrderFormFieldConfig } from '@/helpers/order-config';
import { getProductFormFieldConfig } from '@/helpers/product-config';
import { OrderConfigModelType, ProductConfigModelType, useQuery } from '@/models';
import { OrderExecutionModalState } from '@/types';

interface OrderExecutionModalStateOptions {
  initial?: Partial<OrderExecutionModalState>;
  orderConfig?: OrderConfigModelType;
  productConfig?: ProductConfigModelType;
}

const useOrderExecutionModalState = (options?: OrderExecutionModalStateOptions): OrderExecutionModalState => {
  const { initial, orderConfig, productConfig } = options || {};
  const orderInputPrefix = orderConfig ? getOrderFormFieldConfig(orderConfig, 'number')?.prefix || '' : '';
  const productInputPrefix = productConfig ? getProductFormFieldConfig(productConfig, 'number')?.prefix || '' : '';
  const checkIfExistsQuery = useQuery();
  const mutationQuery = useQuery();

  return useLocalObservable<OrderExecutionModalState>(() => ({
    /*
     * Orders management
     */
    ordersList: [],
    setOrdersList(orders) {
      this.ordersList = orders;
    },
    addOrderToList(order) {
      this.ordersList = [order, ...this.ordersList];
    },
    orderInputPrefix,
    orderInputValue: orderInputPrefix,
    setOrderInputValue(value) {
      this.orderInputValue = value;
    },
    clearOrderInputValue() {
      this.orderInputValue = orderInputPrefix;
    },
    selectedOrder: null,
    setSelectedOrder(order) {
      this.selectedOrder = order;

      if (order) this.moveToNextField();
    },
    selectedItem: null,
    setSelectedItem(item) {
      this.selectedItem = item;
    },
    orderModalOpened: false,
    setOrderModalOpened(value) {
      this.orderModalOpened = value;
    },
    /*
     * Products management
     */
    productsList: [],
    setProductsList(products) {
      this.productsList = products;
    },
    addProductToList(product) {
      this.productsList = [product, ...this.productsList];
    },
    productInputPrefix,
    productInputValue: productInputPrefix,
    setProductInputValue(value) {
      this.productInputValue = value;
    },
    clearProductInputValue() {
      this.productInputValue = productInputPrefix;
    },
    selectedProduct: null,
    setSelectedProduct(product) {
      this.selectedProduct = product;

      if (product) this.moveToNextField();
    },
    productModalOpened: false,
    setProductModalOpened(value) {
      this.productModalOpened = value;
    },
    /*
     * Order execution fields management
     */
    fieldsNames: [],
    setFieldsNames(fields: string[]) {
      this.fieldsNames = fields;
    },
    shouldRenderField(field) {
      return this.fieldsNames.includes(field);
    },
    currentField: null,
    setCurrentField(field: string | null) {
      this.currentField = field;
    },
    isCurrentField(field) {
      return this.currentField === field;
    },
    moveToNextField() {
      if (this.currentField) {
        const currentFieldIndex = this.fieldsNames.indexOf(this.currentField);

        if (currentFieldIndex !== this.fieldsNames.length - 1) {
          this.setCurrentField(this.fieldsNames[currentFieldIndex + 1]);
        }
      }
    },
    showNoteField: false,
    toggleShowNoteField() {
      this.showNoteField = !this.showNoteField;
    },
    /*
     * Order execution state management
     */
    afterStartReason: null,
    setAfterStartReason(reason) {
      this.afterStartReason = reason;
    },
    checkIfExistsQuery,
    mutationQuery,
    mutationInProgress: false,
    setMutationInProgress(value) {
      this.mutationInProgress = value;
    },
    clearSelected() {
      this.selectedOrder = null;
      this.selectedProduct = null;
      this.selectedItem = null;
    },
    orderExecutionModalOpened: false,
    setOrderExecutionModalOpened(value) {
      this.orderExecutionModalOpened = value;
    },
    ...initial,
  }));
};

export default useOrderExecutionModalState;
