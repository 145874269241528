import ContentLoader from 'react-content-loader';

import times from 'lodash.times';

const Item = () => (
  <ContentLoader style={{}} width="100%" height={80} speed={1.5} backgroundColor="#282828" foregroundColor="#999">
    <rect x="2.5%" y="40" rx="6" ry="6" width="95%" height="12" />
  </ContentLoader>
);

interface ListLoaderProps {
  n: number;
  'data-testid'?: string;
}

const ListLoader: React.FC<ListLoaderProps> = ({ n, 'data-testid': dataTestID }) => (
  <div data-testid={dataTestID}>
    {times(n, (i) => (
      <Item key={i} />
    ))}
  </div>
);

export default ListLoader;
