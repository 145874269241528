import { useLocalObservable } from 'mobx-react-lite';

import type { ColumnProps, ColumnsStore } from '@/types';

const useMillerColumns = (): ColumnsStore => {
  return useLocalObservable<ColumnsStore>(() => ({
    state: [],
    setState(props) {
      this.state = props;
    },
    insertAfter(prevParentID, parentID) {
      this.removeAfter(prevParentID);
      this.state = [...this.state, { parentID, selectedID: null }];
    },
    removeAfter(parentID) {
      const columnIndex = this.state.findIndex((column) => column.parentID === parentID);

      this.state = this.state.slice(0, columnIndex + 1);
    },
    removeAndDeselect(parentID) {
      const columnIndex = this.state.findIndex((column) => column.parentID === parentID);
      if (this.state[columnIndex - 1]) this.state[columnIndex - 1].selectedID = null;
      this.state = this.state.slice(0, columnIndex);
    },
    update(parentID, props) {
      const columnIndex = this.state.findIndex((column: ColumnProps) => column.parentID === parentID);

      if (columnIndex !== -1) this.state[columnIndex] = { ...this.state[columnIndex], ...props };
    },
    select(parentID, selectedID) {
      const columnItem = this.state.find((column: ColumnProps) => column.parentID === parentID);

      if (columnItem) columnItem.selectedID = selectedID;
    },
    isSelected(parentID, reasonID) {
      return !!this.state.find((column: ColumnProps) => column.parentID === parentID && column.selectedID === reasonID);
    },
    clear() {
      this.state = [];
    },
    get length() {
      return this.state.length;
    },
    get lastColumnParentId() {
      const { state } = this;
      const { parentID } = state[state.length - 1];
      return parentID;
    },
  }));
};

export default useMillerColumns;
