import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { ListItem } from '@/types';

import { CenteringContainer, Container } from '../core';
import List from '../core/List';
import ListLoader from '../loaders/ListLoader';

interface IPresencesDetails {
  loading: boolean;
  presencesList: ListItem[];
}

export const PresencesDetails: React.FC<IPresencesDetails> = ({ loading, presencesList }): ReactElement => {
  const intl = useIntl();
  return (
    <Container
      title={intl.formatMessage({
        defaultMessage: 'Presences',
        description: 'Screen saver page presences container header',
      })}
      style={{ height: 'calc(25vh - 10px)', marginTop: '10px' }}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <ListLoader data-testid="presences-details-loading" n={5} />
      ) : presencesList?.length ? (
        <List
          data-testid="presences-details-list"
          rowStyles={{ gridTemplateColumns: '1fr', minHeight: '40px' }}
          labelStyles={{ fontSize: '22px' }}
          rowHeight="25%"
          items={presencesList}
        />
      ) : (
        <CenteringContainer>
          {intl.formatMessage({
            defaultMessage: 'No current presences on this workplace',
            description: 'Presences empty list message',
          })}
        </CenteringContainer>
      )}
    </Container>
  );
};
