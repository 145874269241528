import styled from 'styled-components';

import { ThemeInterface } from '@/theme';

interface SeparatorProps {
  theme: ThemeInterface;
  active?: boolean;
  important?: boolean;
}

export const Separator = styled.span<SeparatorProps>`
  display: flex;
  margin-top: 20px;
  font-size: 90%;
  font-style: italic;
  color: ${({ theme, active, important }: SeparatorProps) => {
    if (important) return theme.colors.persimmon;

    return active ? 'white' : 'rgba(255, 255, 255, 0.65)';
  }};
  align-items: center;
  justify-content: center;
  &::before,
  &::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  &::before {
    margin-right: 1em;
  }
  &::after {
    margin-left: 1em;
  }
`;
