import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useLoginStatus } from 'andoncloud-sdk';

import { StatusChangeTransitionPermissionModelType } from '@/models';
import { ReasonsParamTypes } from '@/types';

import { useWorkplaceData } from './data';

interface TransitionPermissions {
  statusChangeTransitionPermissions: StatusChangeTransitionPermissionModelType[] | null;
}

const StatusChangeTransitionPermissionsContext = createContext<TransitionPermissions>({
  statusChangeTransitionPermissions: [],
});

interface StatusChangeTransitionPermissionsProviderProps {
  children: React.ReactNode;
  fromReasonId?: string;
  provideAll?: boolean;
}

const StatusChangeTransitionPermissionsProvider: React.FC<StatusChangeTransitionPermissionsProviderProps> = ({
  children,
  fromReasonId,
  provideAll,
}) => {
  const { data, statusChangeTransitionPermissionsLoaded } = useWorkplaceData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { authResponse } = useLoginStatus();
  const [statusChangeTransitionPermissions, setStatusChangeTransitionPermissions] = useState<
    StatusChangeTransitionPermissionModelType[] | null
  >([]);

  useEffect(() => {
    if (statusChangeTransitionPermissionsLoaded)
      if (provideAll) {
        setStatusChangeTransitionPermissions(data?.statusChangeTransitionPermissions);
      } else {
        setStatusChangeTransitionPermissions(
          data?.statusChangeTransitionPermissions?.filter((permission) => permission.fromReasonId === fromReasonId) ||
            null,
        );
      }
  }, [
    authResponse?.user,
    fromReasonId,
    provideAll,
    data?.statusChangeTransitionPermissions,
    workplaceID,
    statusChangeTransitionPermissionsLoaded,
  ]);

  const value = {
    statusChangeTransitionPermissions,
  };
  return (
    <StatusChangeTransitionPermissionsContext.Provider value={value}>
      {children}
    </StatusChangeTransitionPermissionsContext.Provider>
  );
};

const useStatusChangeTransitionPermissions = (): {
  statusChangeTransitionPermissions: StatusChangeTransitionPermissionModelType[] | null;
} => {
  const permissions = useContext(StatusChangeTransitionPermissionsContext);
  if (!permissions)
    throw new Error(
      'useStatusChangeTransitionPermissions must be used within StatusChangeTransitionPermissionsProvider',
    );
  return permissions;
};

export {
  StatusChangeTransitionPermissionsProvider,
  useStatusChangeTransitionPermissions,
  StatusChangeTransitionPermissionsContext,
};
