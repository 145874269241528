import { Separator as StyledSeparator } from './styled';

interface SeparatorProps {
  text: string;
  active?: boolean;
  important?: boolean;
  'data-testid'?: string;
}

export const Separator: React.FC<SeparatorProps> = ({ text, active, important, 'data-testid': dataTestID }) => (
  <StyledSeparator active={active} important={important} data-testid={dataTestID}>
    {text}
  </StyledSeparator>
);
