/* eslint-disable indent */
import React from 'react';

import { mapProgressEnumToBlackImageIndicator } from '@/helpers/mapProgressEnumToBlackImageIndicator';
import { mapProgressToColorString } from '@/helpers/mapProgressToColorString';
import { mapStatusColorToBlackIndicator } from '@/helpers/mapStatusColorToBlackIndicator';
import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { AndonLightColor } from '@/models';
import { theme } from '@/theme';
import { ExtendedListItem } from '@/types';

import { Container, TextTruncate } from '../core';
import { Loader } from '../core/Loader';
import { Overlay } from '../core/Overlay';
import CardLoader from '../loaders/CardLoader';

import { ItemContainer, ItemText, ItemTextContainer, Label } from './styled';

interface ExtendedListItemConsumerProps {
  small?: boolean;
  large?: boolean;
  item: ExtendedListItem;
  onClick?: () => void;
  loading?: boolean;
}

const ExtendedListItemConsumer: React.FC<ExtendedListItemConsumerProps> = ({
  item: kpi,
  small,
  large,
  onClick,
  loading,
}) => {
  return !loading ? (
    <Container
      onClick={onClick}
      headerStyles={{
        filter: 'brightness(1.2)',
        // eslint-disable-next-line no-nested-ternary
        background: kpi.progress
          ? mapProgressToColorString({ progress: kpi.progress })
          : kpi.statusColor
            ? mapStatusColorToColorString(kpi.statusColor)
            : theme.gradients.reasonsHeader,
      }}
      style={{
        margin: 0,
        paddingTop: large ? '15px' : 0,
        height: '100%',
      }}
      bodyStyle={{
        filter: 'brightness(0.9)',
        // eslint-disable-next-line no-nested-ternary
        background: kpi.progress
          ? mapProgressToColorString({ progress: kpi.progress })
          : kpi.statusColor
            ? mapStatusColorToColorString(kpi.statusColor)
            : theme.gradients.reasonsHeader,
        color: 'white',
      }}
      title=""
      key={kpi.key || kpi.label}
      data-testid={`extended-list-item-${kpi.label}`}
    >
      <Overlay small={small}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {kpi.statusColor ? (
          <ItemContainer>
            <img width={large ? '180px' : '70px'} src={mapStatusColorToBlackIndicator(kpi.statusColor)} alt="" />
            <ItemTextContainer large={large}>
              <ItemText large={large} small={small} whiteText={kpi.statusColor === AndonLightColor.gray}>
                <TextTruncate lines={2}>{kpi.value}</TextTruncate>
              </ItemText>
              <br />
              <br />
              {kpi.extraContent ? (
                <Label large={large} whiteText={kpi.statusColor === AndonLightColor.gray}>
                  {kpi.extraContent}
                </Label>
              ) : (
                <Loader width="35px" />
              )}
            </ItemTextContainer>
          </ItemContainer>
        ) : kpi.progress ? (
          <ItemContainer>
            <img width={large ? '180px' : '70px'} src={mapProgressEnumToBlackImageIndicator(kpi.progress)} alt="" />
            <ItemTextContainer large={large}>
              {kpi.value !== undefined && kpi.value !== null && (
                <ItemText large={large} small={small}>
                  {kpi.value}
                </ItemText>
              )}
              <Label large={large}>{kpi.label}</Label>
            </ItemTextContainer>
          </ItemContainer>
        ) : (
          <ItemContainer>
            {!large && <div />}
            <ItemTextContainer large={large}>
              {kpi.value !== undefined && kpi.value !== null && (
                <ItemText large={large} small={small} whiteText invalid={kpi.invalid}>
                  {kpi.value}
                </ItemText>
              )}
              <Label large={large} whiteText invalid={kpi.invalid}>
                {kpi.label}
              </Label>
            </ItemTextContainer>
          </ItemContainer>
        )}
      </Overlay>
    </Container>
  ) : (
    <CardLoader />
  );
};

export default ExtendedListItemConsumer;
