import { createGlobalStyle } from 'styled-components';

export interface ThemeInterface {
  colors: Record<string, string>;
  gradients: Record<string, string>;
  rgbas: Record<string, string>;
}

export const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xxl: 1920,
    xl: 1440,
    lg: 1200,
    md: 768,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 10,
  },
  container: {
    padding: 10,
    maxWidth: {
      xxl: 1800,
      xl: 1400,
    },
  },
};

export const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    scorpion: '#595959',
    emperor: '#4F4F4F',
    denim: '#155ED1',
    dodgerBlue: '#3897FE',
    cyan: '#38D9FE',
    cannonSmoke: '#383838',
    mineSchaft: '#313131',
    mineSchaftDark: '#2D2D2D',
    youtubeBlack: '#282828',
    codGray: '#1E1E1E',
    cardinal: '#C82026',
    emerald: '#2ACB42',
    persimmon: '#FF453A',
    sunglow: '#FFC12F',
    forestGreen: '#1BAC2C',
    skyan: '#64D2FF',
  },
  rgbas: {
    historySeparator: 'rgba(255, 255, 255, 0.5)',
  },
  gradients: {
    reasonsHeader: 'linear-gradient(180deg, #4A4A4A 0%, #3E3E3E 100%)',
  },
} as const;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    scroll-behavior: smooth;
	  color: white !important;
    overflow-x: hidden;  
  }
  ::-webkit-scrollbar {
    width: 0.6em;
    height: 0.6em;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.3em;
    background-color: rgba(255, 255, 255, 0.25);
  }
`;
