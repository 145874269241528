import { applyPatch, Instance, types } from 'mobx-state-tree';

import { CounterModelType } from './CounterModel';
import HistoryItemModel, { HistoryItemModelType } from './HistoryItemModel';
import { RootStoreBase } from './RootStore.base';
import { WorkplaceModelType } from './WorkplaceModel';
import {
  CounterDirectoryModelType,
  MetricModelType,
  MetricValueModelType,
  OrderExecutionModelType,
  OrderModelType,
  ProductModelType,
  ReasonModelType,
  ShiftModelType,
  StatusChangeModelType,
  UserModelType,
  UserPresenceModelType,
} from '.';

export interface RootStoreType extends Instance<typeof RootStore.Type> {}

export const RootStore = RootStoreBase.props({
  historyItems: types.optional(types.array(HistoryItemModel), []),
}).actions((self) => ({
  addUser(item: UserModelType) {
    self.users.merge({ [item.id]: item });
  },
  addUserPresence(item: UserPresenceModelType) {
    self.userPresences.merge({ [item.id]: item });
  },
  removeUserPresence(item: UserPresenceModelType) {
    self.userPresences.delete(item.id);
  },
  addShift(item: ShiftModelType) {
    self.shifts.merge({ [item.id]: item });
  },
  addReason(item: ReasonModelType) {
    self.reasons.merge({ [item.id]: item });
  },
  addStatusChange(item: StatusChangeModelType) {
    self.statusChanges.merge({ [item.id]: item });
  },
  removeStatusChange(item: StatusChangeModelType) {
    self.statusChanges.delete(item.id);
  },
  addHistoryItem(item: HistoryItemModelType) {
    self.historyItems.unshift(item);
  },
  removeHistoryItem(item: HistoryItemModelType) {
    self.historyItems.remove(item);
  },
  addOrder(item: OrderModelType) {
    self.orders.merge({ [item.id]: item });
  },
  removeOrder(item: OrderModelType) {
    self.orders.delete(item.id);
  },
  addOrderExecution(item: OrderExecutionModelType) {
    self.orderExecutions.merge({ [item.id]: item });
  },
  removeOrderExecution(item: OrderExecutionModelType) {
    self.orderExecutions.delete(item.id);
  },
  addProduct(item: ProductModelType) {
    self.products.merge({ [item.id]: item });
  },
  removeProduct(item: ProductModelType) {
    self.products.delete(item.id);
  },
  addWorkplace(item: WorkplaceModelType) {
    self.workplaces.merge({ [item.id]: item });
  },
  addCounter(item: CounterModelType) {
    self.counters.merge({ [item.id]: item });
  },
  addCounterDirectory(item: CounterDirectoryModelType) {
    self.counterDirectories.merge({ [item.id]: item });
  },
  addMetric(item: MetricModelType) {
    self.metrics.merge({ [item.id]: item });
  },
  addMetricValue(item: MetricValueModelType) {
    self.metricValues.merge({ [item.id]: item });
  },
  reset() {
    applyPatch(self, [
      { op: 'replace', path: '/counters', value: {} },
      { op: 'replace', path: '/counterDirectories', value: {} },
      { op: 'replace', path: '/departments', value: {} },
      { op: 'replace', path: '/floorPlans', value: {} },
      { op: 'replace', path: '/floorPlans', value: {} },
      { op: 'replace', path: '/metrics', value: {} },
      { op: 'replace', path: '/metricValues', value: {} },
      { op: 'replace', path: '/notifications', value: {} },
      { op: 'replace', path: '/orders', value: {} },
      { op: 'replace', path: '/orderExecutions', value: {} },
      { op: 'replace', path: '/products', value: {} },
      { op: 'replace', path: '/reasons', value: {} },
      { op: 'replace', path: '/shifts', value: {} },
      { op: 'replace', path: '/standardRates', value: {} },
      { op: 'replace', path: '/statusChanges', value: {} },
      { op: 'replace', path: '/users', value: {} },
      { op: 'replace', path: '/userPresences', value: {} },
      { op: 'replace', path: '/workplaces', value: {} },
    ]);
    self.historyItems.clear();
  },
}));
