import styled, { css } from 'styled-components';

interface ItemTextProps {
  small?: boolean;
  whiteText?: boolean;
  large?: boolean;
  invalid?: boolean;
}

interface LabelProps {
  whiteText?: boolean;
  large?: boolean;
  invalid?: boolean;
}

interface ItemTextContainerProps {
  large?: boolean;
}

export const ItemContainer = styled.div`
  padding: 2px 4px;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
`;
export const ItemText = styled.span<ItemTextProps>`
  display: block;
  margin: 0;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 2.4vw;
  line-height: 48px;
  max-height: 98px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.mineSchaftDark};
  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.persimmon};
    `}
  ${({ small }) =>
    small &&
    css`
      font-size: 1.8vw;
      line-height: 38px;
      max-height: 78px;
    `}
    ${({ large }) =>
    large &&
    css`
      font-size: 6.2vw;
      line-height: 135px;
      max-height: 274px;
    `}
`;
export const Label = styled.span<LabelProps>`
  display: block;
  margin: 10px 0 0 0;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.mineSchaftDark};
  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `};
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.persimmon};
      text-decoration: line-through;
    `}
  ${({ large }) =>
    large &&
    css`
      margin-top: 20px;
      font-size: 36px;
      line-height: 44px;
    `}
`;

export const ItemTextContainer = styled.div<ItemTextContainerProps>`
  margin-bottom: 0;
  width: 100%;
  ${({ large }) =>
    large &&
    css`
      margin-bottom: 50px;
    `};
`;
