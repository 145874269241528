import { useIntl } from 'react-intl';
import { JSONTree } from 'react-json-tree';

import { toJS } from 'mobx';

import { CompanyConfigModelType } from '@/models';

import { Container } from '../core';

interface ICompanyConfigContainer {
  companyConfig: CompanyConfigModelType;
}

const CompanyConfig: React.FC<ICompanyConfigContainer> = ({ companyConfig }) => {
  const intl = useIntl();
  return (
    <Container
      style={{ height: 'calc(100vh - 80px)', marginTop: 0 }}
      data-testid="company-config"
      title={intl.formatMessage({
        defaultMessage: 'Company config',
        description: 'Service page company config container title',
      })}
    >
      {/* usuniecie pola __typename wymaga napisanie funkcji ktora przeiteruje po obiekcie w kazdym zaglebieniu */}
      <div data-testid="json-tree-container" style={{ padding: '0 10px' }}>
        <JSONTree
          theme={{
            base00: 'transparent',
            extend: {
              background: 'transparent',
            },
          }}
          data={toJS(companyConfig)}
        />
      </div>
    </Container>
  );
};
export default CompanyConfig;
