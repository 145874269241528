import { AndonLightColor } from '@/models';
import { theme } from '@/theme';

export const mapStatusColorToColorString = (color: AndonLightColor): string => {
  switch (color) {
    case 'green':
      return theme.colors.emerald;
    case 'red':
      return theme.colors.persimmon;
    case 'yellow':
      return theme.colors.sunglow;
    default:
      return theme.colors.scorpion;
  }
};
