import { ReactElement, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { getWorkplaceConfig } from '@/helpers';
import { useCurrentWorkplace } from '@/hooks/useCurrentWorkplace';
import andonCloudStatusLogo from '@/images/andonCloudStatusLogo.svg';
import { StatusScreenDisplayBlocksEnum } from '@/models';
import { useCompanyData } from '@/providers';

import OrderInfo from './Order/OrderInfo';
import LiveTime from './Livetime';
import Presences from './Presences';
import ShiftKpiContainer from './ShiftKPI';
import { Logo, StatusScreenElement, StatusScreenWrapper } from './styled';

const StatusScreen = observer((): ReactElement => {
  const { workplaceID } = useCurrentWorkplace();
  const { data, loading } = useCompanyData();
  const [orderFeatureEnabled, setOrderFeatureEnabled] = useState<boolean>(false);
  const currentWorkplaceConfig = getWorkplaceConfig(data?.companyConfig, workplaceID);

  const arrangement = currentWorkplaceConfig?.statusScreenDisplayBlocks || ['PRESENCES', 'ORDERS', 'LIVETIME', 'KPI'];

  const constructTemplateAreasFromArray = (dashArrangement?: StatusScreenDisplayBlocksEnum[]) => {
    if (dashArrangement)
      // eslint-disable-next-line default-case
      switch (dashArrangement?.length) {
        case 0:
          return `"logo"`;
        case 1:
          return `"${dashArrangement[0]}"`;
        case 2:
          return `"${dashArrangement.slice(0, 2).join(' ')}" "logo logo"`;
        case 3:
          return `"${dashArrangement.slice(0, 2).join(' ')}" "${dashArrangement[2]} logo"`;
        case 4:
          return `"${dashArrangement.slice(0, 2).join(' ')}" "${dashArrangement.slice(2, 4).join(' ')}"`;
      }
    return undefined;
  };

  useEffect(() => {
    if (!loading && currentWorkplaceConfig) {
      setOrderFeatureEnabled(!!currentWorkplaceConfig?.orderConfig?.featureEnabled);
    }
  }, [data, loading, currentWorkplaceConfig?.orderConfig?.featureEnabled, currentWorkplaceConfig]);

  return (
    <StatusScreenWrapper
      gridTemplateAreas={constructTemplateAreasFromArray(arrangement as StatusScreenDisplayBlocksEnum[])}
      singleItem={arrangement?.length === undefined || arrangement.length <= 1}
      orderFeatureEnabled={!!orderFeatureEnabled}
      data-testid="workplace-status"
    >
      {arrangement && arrangement.length < 4 && arrangement.length !== 1 && (
        <StatusScreenElement data-testid="LOGO-container" gridArea="logo" gridTemplateRows="1fr">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Logo src={andonCloudStatusLogo} />
          </div>
        </StatusScreenElement>
      )}

      {arrangement?.includes('KPI') && <ShiftKpiContainer />}

      {arrangement?.includes('LIVETIME') && <LiveTime />}
      {arrangement?.includes('PRESENCES') && <Presences />}
      {!loading && !!orderFeatureEnabled && arrangement?.includes('ORDERS') && <OrderInfo />}
    </StatusScreenWrapper>
  );
});

export default StatusScreen;
