import './index.css';

import BaseProgressBar, { ProgressBarProps } from '@ramonak/react-progress-bar';

import { Box } from '../Box';

export const ProgressBar = ({ maxLabel, ...props }: ProgressBarProps & { maxLabel: number }) => (
  <Box>
    <BaseProgressBar
      {...props}
      height="28px"
      baseBgColor="#3c3c3c"
      bgColor="#155ED1"
      labelAlignment="center"
      className="progress-bar-container"
    />
    <p className="progress-bar-max-label">{maxLabel}</p>
  </Box>
);
