import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const DetailsContainer = styled(Container)`
  min-width: 500px;
  margin: -20px -50px;
  padding-bottom: 20px;
`;

export const DetailRow = styled(Row)`
  padding: 0.5em 0;

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.youtubeBlack};
  }
`;

export const DetailLabel = styled(Col)`
  text-indent: 0.5em;
`;

export const DetailValue = styled(Col)``;
