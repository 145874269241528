import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { notify, useLoginStatus } from 'andoncloud-sdk';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import { Moment } from 'moment';

import { getWorkplaceConfig, mapScreenToPath } from '@/helpers';
import { notifyServerError } from '@/helpers/errors';
import { buildScreenSaverURL } from '@/helpers/urls/buildScreenSaverURL';
import { OrderExecutionModelType, ScreenEnum } from '@/models';
import HistoryItemModel, { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import { useCompanyData, useWorkplaceData } from '@/providers';
import { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

import useLastStatusChange from './useLastStatusChange';
import useStore from './useStore';

const useStartOrderExecution = (orderExecutionModalState: OrderExecutionModalState) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { authResponse } = useLoginStatus();
  const rootStore = useStore();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const { data: workplaceData, loading: workplaceDataLoading } = useWorkplaceData();
  const lastStatusChange = useLastStatusChange();
  const navigate = useNavigate();
  const intl = useIntl();

  const workplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const screenSaverConfig = workplaceConfig?.screenSaverConfig;
  const redirectToScreenSaver = screenSaverConfig?.enabled && screenSaverConfig?.displayAfterOrderCreated;
  const { afterStartReason, setAfterStartReason } = orderExecutionModalState;

  useEffect(() => {
    if (!companyDataLoading && !workplaceDataLoading) {
      const configReason =
        workplaceData.reasons.find(
          (reason) => reason.id === workplaceConfig?.orderConfig.afterStartOrderExecutionReasonId,
        ) || null;

      setAfterStartReason(configReason);
    }
  }, [
    companyDataLoading,
    workplaceData,
    workplaceDataLoading,
    workplaceConfig?.orderConfig.afterStartOrderExecutionReasonId,
    setAfterStartReason,
  ]);

  if (authResponse) {
    let createdStatusChangeHistoryItem: HistoryItemModelType;
    let createdOrderInfoHistoryItem: HistoryItemModelType;

    return (orderExecution: OrderExecutionModelType) =>
      orderExecution.start({
        workplaceId: workplaceID,
        userData: authResponse.user,
        lastStatusChange,
        reason: afterStartReason,
        optimisticUpdate: (statusChange) => {
          createdStatusChangeHistoryItem = HistoryItemModel.create({
            date: statusChange.startedAt as Moment,
            instance: castToReferenceSnapshot(statusChange),
            type: HistoryItemType.STATUS_CHANGE,
          });

          createdOrderInfoHistoryItem = HistoryItemModel.create({
            date: statusChange.startedAt as Moment,
            instance: castToReferenceSnapshot(orderExecution),
            type: HistoryItemType.ORDER_EXECUTION_START,
          });

          rootStore.addHistoryItem(createdOrderInfoHistoryItem);
          rootStore.addHistoryItem(createdStatusChangeHistoryItem);

          if (redirectToScreenSaver) {
            navigate(buildScreenSaverURL(workplaceID));
          } else {
            const defaultScreen = workplaceConfig?.defaultScreen || ScreenEnum.STATUS_SCREEN;

            navigate(mapScreenToPath(defaultScreen, workplaceID));
          }
        },
        revertUpdate: () => {
          rootStore.removeHistoryItem(createdOrderInfoHistoryItem);
          rootStore.removeHistoryItem(createdStatusChangeHistoryItem);
          rootStore.removeOrderExecution(orderExecution);

          navigate(mapScreenToPath(ScreenEnum.STATUS_SCREEN, workplaceID));
        },
        onSuccess: () => {
          notify.success(
            !!orderConfig?.alternativeName
              ? intl.formatMessage({
                  defaultMessage: 'Order execution successfully started',
                  description: 'Order execution form notify alternative message success',
                })
              : intl.formatMessage({
                  defaultMessage: 'Order execution successfully started',
                  description: 'Order execution form notify message success',
                }),
          );
        },
        onError: (error, isValidationError) => {
          if (isValidationError) {
            notify.error(
              !!orderConfig?.alternativeName
                ? intl.formatMessage(
                    {
                      defaultMessage: 'There was a problem starting the order execution: {error}',
                      description: 'Order execution starting validation alternative error message',
                    },
                    { error },
                  )
                : intl.formatMessage(
                    {
                      defaultMessage: 'There was a problem starting the order execution: {error}',
                      description: 'Order execution starting validation error message',
                    },
                    { error },
                  ),
            );
          } else {
            notifyServerError(error, intl);

            navigate(mapScreenToPath(ScreenEnum.STATUS_SCREEN, workplaceID));
          }
        },
      });
  }
  return null;
};

export default useStartOrderExecution;
