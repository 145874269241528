/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { StatusChangeModel, StatusChangeModelType } from "./StatusChangeModel"
import { StatusChangeModelSelector } from "./StatusChangeModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  currentStatusChange: StatusChangeModelType;
}

/**
 * WorkplaceBase
 * auto generated base class for the model WorkplaceModel.
 */
export const WorkplaceModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Workplace')
  .props({
    __typename: types.optional(types.literal("Workplace"), "Workplace"),
    currentStatusChange: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => StatusChangeModel))),
    deactivated: types.union(types.undefined, types.boolean),
    departmentId: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    minOperators: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.string),
    reasonGroupId: types.union(types.undefined, types.string),
    reasonsRecommendationsEnabled: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class WorkplaceModelSelector extends QueryBuilder {
  get deactivated() { return this.__attr(`deactivated`) }
  get departmentId() { return this.__attr(`departmentId`) }
  get id() { return this.__attr(`id`) }
  get minOperators() { return this.__attr(`minOperators`) }
  get name() { return this.__attr(`name`) }
  get reasonGroupId() { return this.__attr(`reasonGroupId`) }
  get reasonsRecommendationsEnabled() { return this.__attr(`reasonsRecommendationsEnabled`) }
  currentStatusChange(builder: string | StatusChangeModelSelector | ((selector: StatusChangeModelSelector) => StatusChangeModelSelector) | undefined) { return this.__child(`currentStatusChange`, StatusChangeModelSelector, builder) }
}
export function selectFromWorkplace() {
  return new WorkplaceModelSelector()
}

export const workplaceModelPrimitives = selectFromWorkplace().deactivated.departmentId.minOperators.name.reasonGroupId.reasonsRecommendationsEnabled
