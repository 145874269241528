/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DeviceConfigModel, DeviceConfigModelType } from "./DeviceConfigModel"
import { DeviceConfigModelSelector } from "./DeviceConfigModel.base"
import { ProductConfigModel, ProductConfigModelType } from "./ProductConfigModel"
import { ProductConfigModelSelector } from "./ProductConfigModel.base"
import { RoleConfigModel, RoleConfigModelType } from "./RoleConfigModel"
import { RoleConfigModelSelector } from "./RoleConfigModel.base"
import { WorkplaceConfigModel, WorkplaceConfigModelType } from "./WorkplaceConfigModel"
import { WorkplaceConfigModelSelector } from "./WorkplaceConfigModel.base"
import { RootStoreType } from "./index"


/**
 * CompanyConfigBase
 * auto generated base class for the model CompanyConfigModel.
 */
export const CompanyConfigModelBase = ModelBase
  .named('CompanyConfig')
  .props({
    __typename: types.optional(types.literal("CompanyConfig"), "CompanyConfig"),
    devicesConfigs: types.union(types.undefined, types.array(types.late((): any => DeviceConfigModel))),
    displayedKpiItems: types.union(types.undefined, types.array(types.string)),
    productConfig: types.union(types.undefined, types.late((): any => ProductConfigModel)),
    reasonsRecommendationsEnabled: types.union(types.undefined, types.boolean),
    roleConfig: types.union(types.undefined, types.late((): any => RoleConfigModel)),
    statusChangeTransitionPermissionsUpdatedAt: types.union(types.undefined, types.null, types.string),
    workplacesConfigs: types.union(types.undefined, types.array(types.late((): any => WorkplaceConfigModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CompanyConfigModelSelector extends QueryBuilder {
  get displayedKpiItems() { return this.__attr(`displayedKpiItems`) }
  get reasonsRecommendationsEnabled() { return this.__attr(`reasonsRecommendationsEnabled`) }
  get statusChangeTransitionPermissionsUpdatedAt() { return this.__attr(`statusChangeTransitionPermissionsUpdatedAt`) }
  devicesConfigs(builder: string | DeviceConfigModelSelector | ((selector: DeviceConfigModelSelector) => DeviceConfigModelSelector) | undefined) { return this.__child(`devicesConfigs`, DeviceConfigModelSelector, builder) }
  productConfig(builder: string | ProductConfigModelSelector | ((selector: ProductConfigModelSelector) => ProductConfigModelSelector) | undefined) { return this.__child(`productConfig`, ProductConfigModelSelector, builder) }
  roleConfig(builder: string | RoleConfigModelSelector | ((selector: RoleConfigModelSelector) => RoleConfigModelSelector) | undefined) { return this.__child(`roleConfig`, RoleConfigModelSelector, builder) }
  workplacesConfigs(builder: string | WorkplaceConfigModelSelector | ((selector: WorkplaceConfigModelSelector) => WorkplaceConfigModelSelector) | undefined) { return this.__child(`workplacesConfigs`, WorkplaceConfigModelSelector, builder) }
}
export function selectFromCompanyConfig() {
  return new CompanyConfigModelSelector()
}

export const companyConfigModelPrimitives = selectFromCompanyConfig().displayedKpiItems.reasonsRecommendationsEnabled.statusChangeTransitionPermissionsUpdatedAt
