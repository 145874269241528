import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';

export const TimelineLoader = (): ReactElement => (
  <ContentLoader
    data-testid="timeline-loading"
    height="28"
    width="100%"
    speed={1.5}
    backgroundColor="#3d3d3d"
    foregroundColor="#ffffff"
  >
    <rect x="4%" y="10" ry="6" width="92%" height="14" />
  </ContentLoader>
);

export const Row = (): ReactElement => (
  <ContentLoader style={{}} width="100%" height={60} speed={1.5} backgroundColor="#282828" foregroundColor="#999">
    {/* Only SVG shapes */}
    <rect x="4%" y="35" rx="4" ry="4" width="92%" height="12" />
  </ContentLoader>
);

export const LoadingList = (): ReactElement => (
  <div
    data-testid="history-list-loading"
    style={{
      width: '100%',
      backgroundColor: `#2d2d2d`,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }}
  >
    <Row />
    <br />
    <Row />
    <br />
    <Row />
    <br />
    <Row />
    <br />
    <Row />
    <br />
    <Row />
  </div>
);
