import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import OrderExecutionForm from '@/components/forms/OrderExecutionForm';
import { getWorkplaceConfig } from '@/helpers';
import { ItemModelType, OrderModelType, ProductModelType, useQuery } from '@/models';
import { useCompanyData } from '@/providers';
import { itemsQS, ordersQS, productsQS } from '@/queries';
import { ReasonsParamTypes } from '@/types';

import OrderModal from '../../OrderModal';
import ProductModal from '../../ProductModal';
import { OrderExecutionModalProps } from '..';

const ManualModeView: React.FC<OrderExecutionModalProps> = observer(({ state }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { data: companyData, loading: loadingCompanyData } = useCompanyData();
  const {
    setQuery: setOrdersSearchQuery,
    data: ordersSearchData,
    loading: ordersSearchLoading,
  } = useQuery<{ orders: OrderModelType[] }>();
  const {
    setQuery: setProductsSearchQuery,
    data: productsSearchData,
    loading: productsSearchLoading,
  } = useQuery<{ products: ProductModelType[] }>();
  const {
    setQuery: setItemsSearchQuery,
    data: itemsSearchData,
    loading: itemsSearchLoading,
  } = useQuery<{ items: ItemModelType[] }>();
  const workplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const productConfig = companyData?.companyConfig?.productConfig;
  const {
    orderInputValue,
    ordersList,
    setOrdersList,
    addOrderToList,
    selectedOrder,
    productInputValue,
    productsList,
    setProductsList,
    addProductToList,
    selectedProduct,
    setSelectedProduct,
    setSelectedItem,
  } = state;

  useEffect(() => {
    setOrdersSearchQuery((store) =>
      store.queryOrders({ filter: { order_number_contains: orderInputValue } }, ordersQS.ALL),
    );
  }, [orderInputValue, setOrdersSearchQuery]);

  useEffect(() => {
    if (!ordersSearchLoading && ordersSearchData) {
      setOrdersList(ordersSearchData.orders?.slice(0, 10) || []);
    }
  }, [ordersSearchData, ordersSearchLoading, setOrdersList]);

  useEffect(() => {
    const filter = {
      name_or_number_contains: productInputValue,
      ...(selectedOrder && { orders_order_number_equals: selectedOrder?.number }),
    };
    setProductsSearchQuery((store) => store.queryProducts({ filter }, productsQS.ALL));
  }, [selectedOrder, productInputValue, setProductsSearchQuery]);

  useEffect(() => {
    if (!productsSearchLoading && productsSearchData) {
      const searchProducts = productsSearchData.products;

      if (searchProducts?.length > 0) {
        if (selectedOrder && searchProducts.length === 1) {
          setSelectedProduct(searchProducts[0]);
        }
        setProductsList(searchProducts.slice(0, 10) || []);
      } else if (selectedOrder) {
        setProductsSearchQuery((store) =>
          store.queryProducts({ filter: { name_or_number_contains: productInputValue } }, productsQS.ALL),
        );
        setSelectedProduct(null);
      }
    }
  }, [
    productsSearchData,
    productsSearchLoading,
    productInputValue,
    setProductsSearchQuery,
    setProductsList,
    selectedOrder,
    setSelectedProduct,
  ]);

  useEffect(() => {
    if (selectedOrder && selectedProduct) {
      setItemsSearchQuery((store) =>
        store.queryItems(
          { filter: { order_id_equals: selectedOrder.id, product_id_equals: selectedProduct.id } },
          itemsQS.ALL,
        ),
      );
    } else {
      setSelectedItem(null);
    }
  }, [selectedOrder, selectedProduct, setItemsSearchQuery, setSelectedItem]);

  useEffect(() => {
    if (!itemsSearchLoading && itemsSearchData?.items.length === 1) {
      setSelectedItem(itemsSearchData.items[0]);
    } else {
      setSelectedItem(null);
    }
  }, [itemsSearchData, itemsSearchLoading, setSelectedItem]);

  useEffect(() => {
    if (selectedOrder) {
      if (ordersList.findIndex((order) => order.id === selectedOrder.id) === -1) {
        // update orders list with created one
        addOrderToList(selectedOrder);
      }
    }
  }, [ordersList, addOrderToList, selectedOrder]);

  useEffect(() => {
    if (selectedProduct) {
      if (productsList.findIndex((product) => product.id === selectedProduct.id) === -1) {
        // update products list with created one
        addProductToList(selectedProduct);
      }
    }
  }, [productsList, addProductToList, selectedProduct]);

  return (
    (!loadingCompanyData && orderConfig && productConfig && (
      <>
        <OrderExecutionForm orderExecutionModalState={state} orderConfig={orderConfig} productConfig={productConfig} />

        <OrderModal orderExecutionModalState={state} orderConfig={orderConfig} />
        <ProductModal orderExecutionModalState={state} productConfig={productConfig} />
      </>
    )) ||
    null
  );
});

export default ManualModeView;
