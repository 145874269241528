import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Switch from 'react-switch';

import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { Autocomplete, Button, ButtonIcon, Input, Textarea } from '@/components/core';
import { SwitchLabel } from '@/components/forms';
import type { FormProps } from '@/forms/types';
import { useStore } from '@/hooks';
import plusImage from '@/images/plusImage.svg';
import { FieldConfigModelType, OrderConfigModelType, OrderExecutionModel, ProductConfigModelType } from '@/models';
import { useWorkplaceData } from '@/providers';
import { theme } from '@/theme';
import type { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

import { EmptyMessage, FieldError, Form, FormControl, FormGroup } from '../styled';

import useHandlers from './hooks/useHandlers';
import useOrderExecutionForm from './hooks/useOrderExecutionForm';

interface OrderExecutionFormProps extends FormProps<typeof OrderExecutionModel> {
  children?: React.ReactNode;
  orderExecutionModalState: OrderExecutionModalState;
  orderConfig: OrderConfigModelType;
  productConfig: ProductConfigModelType;
}

const OrderExecutionForm: React.FC<OrderExecutionFormProps> = observer(
  ({ children, options, orderExecutionModalState, orderConfig, productConfig }) => {
    const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
    const rootStore = useStore();
    const { data: workplaceData } = useWorkplaceData();
    const [quantityValue, setQuantityValue] = useState<string>('');
    const intl = useIntl();
    const {
      ordersList,
      productsList,
      selectedOrder,
      orderInputPrefix,
      setOrderInputValue,
      setSelectedOrder,
      productInputPrefix,
      setProductInputValue,
      selectedProduct,
      setSelectedProduct,
      setOrderModalOpened,
      setProductModalOpened,
      selectedItem,
      fieldsNames,
      shouldRenderField,
      setCurrentField,
      isCurrentField,
      showNoteField,
      toggleShowNoteField,
      mutationInProgress,
    } = orderExecutionModalState;

    const fieldsConfigs = orderConfig.orderExecutionFormFieldsConfigs;

    const getFieldConfig = (name: string) => fieldsConfigs.find((field) => field.name === name) as FieldConfigModelType;

    const { fields, submit, saving } = useOrderExecutionForm(
      workplaceID,
      orderExecutionModalState,
      orderConfig,
      options,
    );
    const {
      handleOrderFieldEnterPress,
      handleOrderFieldClear,
      handleProductFieldEnterPress,
      handleProductFieldClear,
      handleCancel,
      handleSubmit,
    } = useHandlers({
      rootStore,
      orderExecutionModalState,
      orderConfig,
      productConfig,
      submit,
    });

    useEffect(() => {
      setCurrentField(fieldsNames[0]);
    }, [fieldsNames, setCurrentField]);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      fields.order?.inputProps.onChange(selectedOrder) as void;
    }, [selectedOrder, fields.order?.inputProps]);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      fields.product?.inputProps.onChange(selectedProduct) as void;
    }, [selectedProduct, fields.product?.inputProps]);

    useEffect(() => {
      setQuantityValue(selectedItem?.count?.toString() || '');
    }, [selectedItem]);

    const renderNoOrdersFoundMessage = () => {
      return !!orderConfig?.alternativeName ? (
        <FormattedMessage
          defaultMessage="No order with the given number was found"
          description="Order execution form order not found alternative message"
        />
      ) : (
        <FormattedMessage
          defaultMessage="No order with the given number was found"
          description="Order execution form order not found message"
        />
      );
    };

    const renderNoProductsFoundMessage = () => {
      return !!productConfig?.alternativeName ? (
        <FormattedMessage
          defaultMessage="No product with the given name was found"
          description="Order execution form product not found alternative message"
        />
      ) : (
        <FormattedMessage
          defaultMessage="No product with the given name was found"
          description="Order execution form product not found message"
        />
      );
    };

    const renderButtons = () => (
      <Row>
        <Col col={6}>
          <Button type="button" onClick={handleCancel} data-testid="order-execution-form-cancel-button">
            <FormattedMessage defaultMessage="Cancel" description="Order execution form cancel button" />
          </Button>
        </Col>
        <Col col={6}>
          <Button type="submit" data-testid="order-execution-form-submit-button" disabled={saving} primary>
            <FormattedMessage defaultMessage="Start" description="Order execution form submit button" />
          </Button>
        </Col>
      </Row>
    );

    return (
      <Form data-testid="order-execution-form" onSubmit={handleSubmit} autoComplete="off" noValidate>
        <Row>
          <Col col={6}>
            {shouldRenderField('order') && (
              <>
                <FormControl>
                  <Autocomplete
                    data-testid="order-execution-order-field"
                    items={ordersList}
                    itemLabelKey="number"
                    ignoreCase={false}
                    placeholder={
                      !!orderConfig?.alternativeName
                        ? intl.formatMessage({
                            defaultMessage: 'Enter order number',
                            description: 'Order execution form order autocomplete field alternative placeholder',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Enter order number',
                            description: 'Order execution form order autocomplete field placeholder',
                          })
                    }
                    createButtonText={intl.formatMessage({
                      defaultMessage: 'Add',
                      description: 'Order execution form create order button text',
                    })}
                    prefix={orderInputPrefix}
                    initialValue={selectedOrder?.number || orderInputPrefix}
                    onChange={(e, value) => setOrderInputValue(value)}
                    onKeyDown={handleOrderFieldEnterPress}
                    onSelect={(value, item) => setSelectedOrder(item)}
                    onCreate={() => setOrderModalOpened(true)}
                    onClear={handleOrderFieldClear}
                    onBlur={() => setCurrentField(null)}
                    onFocus={() => setCurrentField('order')}
                    focus={isCurrentField('order')}
                    error={!!fields.order.error}
                    emptyMessage={
                      <EmptyMessage>
                        {!!workplaceData.orders?.length && renderNoOrdersFoundMessage()}
                        <Button
                          type="button"
                          size="small"
                          onClick={() => setOrderModalOpened(true)}
                          data-testid="new-order-button"
                        >
                          <ButtonIcon src={plusImage} />
                          {!!orderConfig?.alternativeName ? (
                            <FormattedMessage
                              defaultMessage="Add new order"
                              description="Order execution form new order button alternative text"
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage="Add new order"
                              description="Order execution form new order button"
                            />
                          )}
                        </Button>
                      </EmptyMessage>
                    }
                    pending={isCurrentField('order') && mutationInProgress}
                    hideSearchIcon
                  />
                  <FieldError data-testid="order-execution-order-field-error">{fields.order.error}</FieldError>
                </FormControl>
              </>
            )}
            {shouldRenderField('note') && (
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <SwitchLabel htmlFor="order-execution-note-field-switch" style={{ marginTop: 20 }}>
                  <Switch
                    id="order-execution-note-field-switch"
                    data-testid="order-execution-note-field-switch"
                    checked={showNoteField}
                    onChange={toggleShowNoteField}
                    onColor={theme.colors.dodgerBlue}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                  <span>
                    <FormattedMessage defaultMessage="Add note" description="Order execution form add note label" />
                  </span>
                </SwitchLabel>
                {showNoteField && (
                  <FormControl>
                    <Textarea
                      data-testid="order-execution-note-field"
                      rows={5}
                      {...fields.note.inputProps}
                      onBlur={() => setCurrentField(null)}
                      onFocus={() => setCurrentField('note')}
                      focus={isCurrentField('note')}
                      error={!!fields.note.error}
                    />
                    <FieldError data-testid="order-execution-note-field-error">{fields.note.error}</FieldError>
                  </FormControl>
                )}
              </FormGroup>
            )}
          </Col>
          <Col col={6}>
            {shouldRenderField('product') && (
              <FormGroup>
                <FormControl>
                  <Autocomplete
                    data-testid="order-execution-product-field"
                    items={productsList}
                    itemLabelKey="name|number"
                    itemSearchKeys={['name', 'number']}
                    ignoreCase={false}
                    placeholder={
                      !!productConfig?.alternativeName
                        ? intl.formatMessage({
                            defaultMessage: 'Enter product name or code',
                            description: 'Order execution form product autocomplete field alternative placeholder',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Enter product name or number',
                            description: 'Order execution form product autocomplete field placeholder',
                          })
                    }
                    createButtonText={intl.formatMessage({
                      defaultMessage: 'Add',
                      description: 'Order execution form create product button text',
                    })}
                    prefix={productInputPrefix}
                    initialValue={selectedProduct?.name || selectedProduct?.number || productInputPrefix}
                    onChange={(e, value) => setProductInputValue(value)}
                    onKeyDown={handleProductFieldEnterPress}
                    onSelect={(value, item) => setSelectedProduct(item)}
                    onCreate={() => setProductModalOpened(true)}
                    onClear={handleProductFieldClear}
                    onBlur={() => setCurrentField(null)}
                    onFocus={() => setCurrentField('product')}
                    focus={isCurrentField('product')}
                    error={!!fields.product.error}
                    emptyMessage={
                      <EmptyMessage>
                        {!!workplaceData.products?.length && renderNoProductsFoundMessage()}
                        <Button
                          type="button"
                          size="small"
                          onClick={() => setProductModalOpened(true)}
                          data-testid="new-product-button"
                        >
                          <ButtonIcon src={plusImage} />
                          {!!productConfig?.alternativeName ? (
                            <FormattedMessage
                              defaultMessage="Add new product"
                              description="Order execution form new product button alternative text"
                            />
                          ) : (
                            <FormattedMessage
                              defaultMessage="Add new product"
                              description="Order execution form new product button"
                            />
                          )}
                        </Button>
                      </EmptyMessage>
                    }
                    pending={isCurrentField('product') && mutationInProgress}
                    disabled={!!selectedOrder && !selectedProduct && productsList.length === 1}
                    hideSearchIcon
                  />
                  <FieldError data-testid="order-execution-product-field-error">{fields.product.error}</FieldError>
                </FormControl>
              </FormGroup>
            )}
            {shouldRenderField('quantity') && (
              <FormControl>
                <Input
                  data-testid="order-execution-quantity-field"
                  placeholder={
                    !!productConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Planned quantity of products',
                          description: 'Order execution form quantity field alternative placeholder',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Planned quantity of products',
                          description: 'Order execution form quantity field placeholder',
                        })
                  }
                  startAdornment={getFieldConfig('quantity').prefix}
                  endAdornment={getFieldConfig('quantity').unit}
                  {...fields.quantity.inputProps}
                  value={quantityValue}
                  onChange={(e) => {
                    setQuantityValue(e.target.value);

                    fields.quantity.inputProps.onChange(e);
                  }}
                  onBlur={() => setCurrentField(null)}
                  onFocus={() => setCurrentField('quantity')}
                  focus={isCurrentField('quantity')}
                  error={!!fields.quantity.error}
                />
                <FieldError data-testid="order-execution-quantity-field-error">{fields.quantity.error}</FieldError>
              </FormControl>
            )}
          </Col>
        </Row>
        {children}
        {renderButtons()}
      </Form>
    );
  },
);

export default OrderExecutionForm;
