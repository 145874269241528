import styled, { css } from 'styled-components';

interface KeyboardButtonProps {
  gridArea?: string;
  selected?: boolean;
}

export const KeyboardButton = styled.button<KeyboardButtonProps>`
  background: #4f4f4f;
  border-radius: 10px;
  border: none;
  font-size: 28px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
  &:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    filter: brightness(0.6);
  }
  ${({ gridArea }) =>
    gridArea &&
    css`
        grid-area: ${gridArea}};
      `}
  ${({ selected }) =>
    selected &&
    css`
      &:hover {
        cursor: unset;
      }
      background-color: #383838;
      color: ${({ theme }) => theme.colors.white};
    `}
`;
export const GridContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 11vh repeat(4, 10vh) 6vh;
  grid-template-areas:
    'input     input     input     input'
    'num-1     num-2     num-3     addOperation'
    'num-4     num-5     num-6     addOperation'
    'num-7     num-8     num-9     subtractOperation'
    'num-0     num-0     backspace subtractOperation'
    'reject    reject    confirm   confirm';
  @media (max-height: 1000px) {
    grid-gap: 12px;
  }
`;
