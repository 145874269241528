import { CenteringContainer } from '@/components/core';
import { Loader } from '@/components/core/Loader';

const DataLoadingPage: React.FC = () => {
  return (
    <CenteringContainer style={{ backgroundColor: '#1E1E1E' }} height="100vh">
      <Loader width="500" />
    </CenteringContainer>
  );
};

export default DataLoadingPage;
