import { MutationErrorModelType } from '@/models';

type Message = {
  path: string;
  message: string;
};

export const prepareErrorMessages = (errors: readonly MutationErrorModelType[]): Message[] => {
  return errors.map((error) => ({
    path: `/${error.field as string}`,
    message: (error.messages as string[])[0],
  })) as Message[];
};
