/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { ReasonModel, ReasonModelType } from "./ReasonModel"
import { ReasonModelSelector } from "./ReasonModel.base"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  author: UserModelType;
  order: OrderModelType;
  product: ProductModelType;
  reason: ReasonModelType;
}

/**
 * StatusChangeBase
 * auto generated base class for the model StatusChangeModel.
 */
export const StatusChangeModelBase = withTypedRefs<Refs>()(ModelBase
  .named('StatusChange')
  .props({
    __typename: types.optional(types.literal("StatusChange"), "StatusChange"),
    author: types.union(types.undefined, MSTGQLRef(types.late((): any => UserModel))),
    finishedAt: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    note: types.union(types.undefined, types.null, types.string),
    order: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => OrderModel))),
    product: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ProductModel))),
    reason: types.union(types.undefined, MSTGQLRef(types.late((): any => ReasonModel))),
    reasonHasBeenRecommended: types.union(types.undefined, types.boolean),
    startedAt: types.union(types.undefined, types.string),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class StatusChangeModelSelector extends QueryBuilder {
  get finishedAt() { return this.__attr(`finishedAt`) }
  get id() { return this.__attr(`id`) }
  get note() { return this.__attr(`note`) }
  get reasonHasBeenRecommended() { return this.__attr(`reasonHasBeenRecommended`) }
  get startedAt() { return this.__attr(`startedAt`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
  author(builder: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector) | undefined) { return this.__child(`author`, UserModelSelector, builder) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined) { return this.__child(`order`, OrderModelSelector, builder) }
  product(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined) { return this.__child(`product`, ProductModelSelector, builder) }
  reason(builder: string | ReasonModelSelector | ((selector: ReasonModelSelector) => ReasonModelSelector) | undefined) { return this.__child(`reason`, ReasonModelSelector, builder) }
}
export function selectFromStatusChange() {
  return new StatusChangeModelSelector()
}

export const statusChangeModelPrimitives = selectFromStatusChange().finishedAt.note.reasonHasBeenRecommended.startedAt.workplaceId
