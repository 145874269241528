import styled, { css } from 'styled-components';

interface StatusScreenElementProps {
  gridTemplateRows?: string;
  gridArea?: string;
}
interface StatusScreenWrapperProps {
  orderFeatureEnabled: boolean;
  gridTemplateAreas?: string | null;
  singleItem?: boolean;
}

export const StatusScreenWrapper = styled.div<StatusScreenWrapperProps>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: 1px;
  grid-auto-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'LIVETIME KPI'
    'PRESENCES ORDERS';
  ${({ gridTemplateAreas }) =>
    gridTemplateAreas &&
    css`
      grid-template-areas: ${gridTemplateAreas};
    `}
  ${({ singleItem }) =>
    singleItem &&
    css`
      grid-auto-columns: unset;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    `}
`;

export const StatusScreenElement = styled.div<StatusScreenElementProps>`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  position: relative;
  overflow-y: hidden;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}

  ${({ gridTemplateRows }) =>
    gridTemplateRows &&
    css`
      grid-template-rows: ${gridTemplateRows};
    `};
`;

export const StatusScreenElementHeader = styled.header`
  position: sticky;
  background: linear-gradient(180deg, #4a4a4a 0%, #3e3e3e 100%);
  padding: 8px 20px;
  display: grid;
  grid-template-columns: 1fr;
`;
export const Logo = styled.img`
  width: 200px;
`;
export const Title = styled.h3`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding: 0;
`;
