import greenIndicator from '@/images/greenIndicator.svg';
import redIndicator from '@/images/redIndicator.svg';
import yellowIndicator from '@/images/yellowIndicator.svg';

export const mapProgressEnumToImageIndicator = (progress: string): string => {
  if (progress === 'boost') {
    return yellowIndicator;
  }
  if (progress === 'failure') {
    return redIndicator;
  }
  return greenIndicator;
};
