import { counterModelPrimitives } from '@/models/CounterModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { setCounterPayloadModelPrimitives } from '@/models/SetCounterPayloadModel.base';

export default {
  ALL: counterModelPrimitives.toString(),
  SET_COUNTER_PAYLOAD_ALL: setCounterPayloadModelPrimitives
    .counter(counterModelPrimitives)
    .errors(mutationErrorModelPrimitives)
    .toString(),
};
