import { theme } from '@/theme';

interface ImapProgressToColorString {
  progress: string;
}

export const mapProgressToColorString = ({ progress }: ImapProgressToColorString): string => {
  switch (progress) {
    case 'failure':
      return theme.colors.persimmon;
    case 'boost':
      return theme.colors.sunglow;
    case 'success':
      return theme.colors.emerald;
    default:
      return theme.colors.codGray;
  }
};
