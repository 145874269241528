import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CounterDirectoryModelType } from '@/models';
import { useWorkplaceData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

// TODO deprecated
export const useCurrentWorkplaceCounterDirectories = (): {
  loading: boolean;
  counterDirectories?: CounterDirectoryModelType[];
} => {
  const { data, loading } = useWorkplaceData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;

  const [counterDirectories, setCounterDirectories] = useState<CounterDirectoryModelType[]>();

  useEffect(() => {
    if (!loading)
      setCounterDirectories(data?.counterDirectories?.filter((directory) => directory.workplaceId === workplaceID));
  }, [data?.counterDirectories, loading, workplaceID]);

  return { loading, counterDirectories };
};
