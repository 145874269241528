export default {
  hidden: {
    zIndex: 0,
    opacity: 0,
    y: -30,
  },
  visible: {
    zIndex: 20,
    opacity: 1,
    y: 0,
  },
};
