import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { TextTruncate } from '@/components/core';
import { modalContext } from '@/hooks/useHistoryItemModal';
import useUserPresenceModalDetails from '@/hooks/useUserPresenceModalDetails';
import { UserPresenceModelType } from '@/models';
import { HistoryItemType } from '@/models/HistoryItemModel';
import { getHistoryButtonTestId } from '@/testing';
import { ModalStore } from '@/types';

import HistoryButton, { HistoryButtonText } from '../HistoryButton';

export interface UserPresenceProps {
  userPresence: UserPresenceModelType;
  type: HistoryItemType.CHECK_IN | HistoryItemType.CHECK_OUT;
  groupedCount?: number;
  toggleCollapsed?: () => void;
  collapsed?: boolean;
}

const UserPresence: React.FC<UserPresenceProps> = observer(
  ({ userPresence, type, groupedCount, toggleCollapsed, collapsed }) => {
    const intl = useIntl();
    const modalStore: ModalStore | null = useContext(modalContext);
    const modalDetails = useUserPresenceModalDetails(userPresence);
    const handleClick = () => {
      if (toggleCollapsed) {
        toggleCollapsed();
        return;
      }
      if (modalStore) {
        modalStore.setDetails(modalDetails);
        modalStore.toggleOpen();
      }
    };

    const getButtonText = () => {
      if (type === HistoryItemType.CHECK_IN) {
        if (groupedCount && groupedCount > 1) {
          return intl.formatMessage(
            {
              defaultMessage: `Check in ({count})`,
              description: 'user presence grouped check in',
            },
            { count: groupedCount },
          );
        }
        return intl.formatMessage(
          {
            defaultMessage: `Check in {name}`,
            description: 'user presence check in',
          },
          { name: userPresence.user.name || '' },
        );
      }
      if (groupedCount && groupedCount > 1) {
        return intl.formatMessage(
          {
            defaultMessage: `Check out ({count})`,
            description: 'user presence grouped check out',
          },
          { count: groupedCount },
        );
      }
      return intl.formatMessage(
        {
          defaultMessage: `Check out {name}`,
          description: 'user presence check out',
        },
        { name: userPresence.user.name || '' },
      );
    };

    return (
      <HistoryButton
        onClick={handleClick}
        data-testid={getHistoryButtonTestId(userPresence?.id, type, !!groupedCount)}
        arrowDown={collapsed !== undefined && !collapsed}
        arrowUp={collapsed !== undefined && collapsed}
      >
        {/* empty div container to fit the historyButton grid that makes space for the icon */}
        <div />
        <HistoryButtonText>
          <TextTruncate>{getButtonText()}</TextTruncate>
        </HistoryButtonText>
      </HistoryButton>
    );
  },
);

export default UserPresence;
