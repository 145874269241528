import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';
import { v4 as uuidv4 } from 'uuid';

import { Button, Input } from '@/components/core';
import useForm from '@/forms';
import { prepareErrorMessages } from '@/forms/helpers';
import type { FormProps } from '@/forms/types';
import { notifyServerError } from '@/helpers/errors';
import { formatECMAScriptRegexp } from '@/helpers/formatECMAScriptRegexp';
import { useStore } from '@/hooks';
import {
  CreateOrderPayloadModelType,
  FieldConfigModelType,
  OrderConfigModelType,
  OrderModel,
  OrderModelType,
  useQuery,
} from '@/models';
import ordersMutations from '@/mutations/orders';
import type { OrderExecutionModalState } from '@/types';

import { FieldError, Form, FormControl } from './styled';

interface OrderFormProps extends FormProps<typeof OrderModel> {
  orderExecutionModalState: OrderExecutionModalState;
  orderConfig: OrderConfigModelType;
}

const OrderForm: React.FC<OrderFormProps> = observer(({ options, orderExecutionModalState, orderConfig }) => {
  const rootStore = useStore();
  const { setQuery } = useQuery();
  const intl = useIntl();
  const { orderInputValue, setSelectedOrder, setOrderModalOpened } = orderExecutionModalState;

  const modelInitial = { id: uuidv4(), number: orderInputValue };
  const fieldsConfigs = orderConfig.orderFormFieldsConfigs;
  const fieldsNames = fieldsConfigs.map((field) => field.name as string);

  const getFieldConfig = (name: string) => fieldsConfigs.find((field) => field.name === name) as FieldConfigModelType;

  const { fields, submit, saving } = useForm<typeof OrderModel>(OrderModel, undefined, modelInitial, {
    addMode: false,
    fields: fieldsNames,
    backend: {
      save: async (order: OrderModelType) => {
        const query = ordersMutations.create({
          rootStore,
          order,
          onSuccess: () => {
            notify.success(
              !!orderConfig.alternativeName
                ? intl.formatMessage({
                    defaultMessage: 'Order successfully created',
                    description: 'Order form notify alternative message success',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Order successfully created',
                    description: 'Order form notify message success',
                  }),
            );
          },
          onError: (error, isValidationError) => {
            if (!isValidationError) {
              notifyServerError(error, intl);
            }
          },
        });
        setQuery(query);

        const { createOrder } = (await query.currentPromise()) as {
          createOrder: CreateOrderPayloadModelType;
        };

        if (createOrder.errors) {
          return {
            errorValidations: [
              {
                id: 'save',
                messages: prepareErrorMessages(createOrder.errors),
              },
            ],
          };
        }
        setSelectedOrder(order);
        setOrderModalOpened(false);

        return null;
      },
    },
    extraValidation: (accessor, value: string) => {
      const fieldName = accessor.path.replace('/', '');
      const fieldRegexp = formatECMAScriptRegexp(getFieldConfig(fieldName).regexp || '');

      if (value && fieldsNames.includes(fieldName) && fieldRegexp) {
        if (RegExp(fieldRegexp).exec(value)) return false;

        return intl.formatMessage({
          defaultMessage: 'The value provided has an invalid format',
          description: 'Order form field extra validation error',
        });
      }
      return false;
    },
    requiredError: intl.formatMessage({
      defaultMessage: 'This field is required',
      description: 'Product form field required text',
    }),
    ...options,
  });

  useEffect(() => {
    // run validation on mount
    fields.number.validate();
  }, [fields]);

  const shouldRenderField = (name: string) => fieldsNames.includes(name);

  return (
    <Form data-testid="order-form" onSubmit={submit} autoComplete="off" noValidate>
      {shouldRenderField('number') && (
        <FormControl>
          <Input
            data-testid="order-number-field"
            placeholder={
              !!orderConfig?.alternativeName
                ? intl.formatMessage({
                    defaultMessage: 'Order number',
                    description: 'Order form number field alternative placeholder',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Order number',
                    description: 'Order form number field placeholder',
                  })
            }
            endAdornment={getFieldConfig('number').unit}
            {...fields.number.inputProps}
            error={!!fields.number.error}
          />
          <FieldError data-testid="order-number-field-error">{fields.number.error}</FieldError>
        </FormControl>
      )}
      <Row>
        <Col col={6}>
          <Button type="button" data-testid="order-form-cancel-button" onClick={() => setOrderModalOpened(false)}>
            <FormattedMessage defaultMessage="Cancel" description="Order form cancel button" />
          </Button>
        </Col>
        <Col col={6}>
          <Button type="submit" data-testid="order-form-submit-button" disabled={saving} primary>
            {!!orderConfig?.alternativeName ? (
              <FormattedMessage defaultMessage="Add order" description="Order form submit button alternative text" />
            ) : (
              <FormattedMessage defaultMessage="Add order" description="Order form submit button" />
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default OrderForm;
