import { OrderExecutionModel, OrderModel, ProductModel } from '@/models';
import NoteModel from '@/models/NoteModel';

import noteFormDefinition from './note';
import orderFormDefinition from './order';
import orderExecutionFormDefinition from './order-execution';
import productFormDefinition from './product';

export default {
  [NoteModel.name]: noteFormDefinition,
  [OrderModel.name]: orderFormDefinition,
  [OrderExecutionModel.name]: orderExecutionFormDefinition,
  [ProductModel.name]: productFormDefinition,
};
