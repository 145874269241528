/* eslint-disable promise/catch-or-return, promise/always-return */
import { KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { v4 as uuidv4 } from 'uuid';

import { handleQueryError, notifyServerError } from '@/helpers/errors';
import { formatECMAScriptRegexp } from '@/helpers/formatECMAScriptRegexp';
import { getOrderFormFieldConfig } from '@/helpers/order-config';
import { getProductFormFieldConfig } from '@/helpers/product-config';
import { OrderConfigModelType, OrderModel, ProductConfigModelType, ProductModel, RootStoreType } from '@/models';
import ordersMutations from '@/mutations/orders';
import productsMutations from '@/mutations/products';
import { ordersQS, productsQS } from '@/queries';
import { OrderExecutionModalState } from '@/types';

interface UseHandlersProps {
  rootStore: RootStoreType;
  orderExecutionModalState: OrderExecutionModalState;
  orderConfig: OrderConfigModelType;
  productConfig: ProductConfigModelType;
  submit: (e: React.FormEvent<HTMLFormElement>) => Promise<boolean>;
}

const useHandlers = ({ rootStore, orderExecutionModalState, orderConfig, productConfig, submit }: UseHandlersProps) => {
  const {
    orderInputValue,
    clearOrderInputValue,
    setSelectedOrder,
    productInputValue,
    clearProductInputValue,
    setSelectedProduct,
    setOrderExecutionModalOpened,
    setOrderModalOpened,
    setProductModalOpened,
    clearSelected,
    checkIfExistsQuery: { setQuery: setCheckIfExistsQuery },
    mutationQuery: { setQuery: setMutationQuery },
    mutationInProgress,
    setMutationInProgress,
  } = orderExecutionModalState;

  const intl = useIntl();

  return {
    handleOrderFieldEnterPress: (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        if (!mutationInProgress) {
          const fieldRegexp = formatECMAScriptRegexp(getOrderFormFieldConfig(orderConfig, 'number')?.regexp || '');
          const isValidNumber = !!orderInputValue && (!fieldRegexp || RegExp(fieldRegexp).exec(orderInputValue));

          if (isValidNumber && orderConfig.orderFormFieldsConfigs.length === 1) {
            setMutationInProgress(true);

            setCheckIfExistsQuery((store) => {
              const existsQuery = store.queryOrders({ filter: { order_number_equals: orderInputValue } }, ordersQS.ALL);
              existsQuery.then(
                ({ orders }) => {
                  if (orders.length === 1) {
                    setSelectedOrder(orders[0]);
                    setMutationInProgress(false);
                  } else {
                    const order = OrderModel.create({ id: uuidv4(), number: orderInputValue });
                    const createOrderQuery = ordersMutations.create({
                      rootStore,
                      order,
                      onSuccess: () => {
                        rootStore.addOrder(order);

                        setSelectedOrder(order);
                        setMutationInProgress(false);

                        notify.success(
                          !!orderConfig.alternativeName
                            ? intl.formatMessage({
                                defaultMessage: 'Order successfully created',
                                description: 'Order form notify alternative message success',
                              })
                            : intl.formatMessage({
                                defaultMessage: 'Order successfully created',
                                description: 'Order form notify message success',
                              }),
                        );
                      },
                      onError: (error, isValidationError) => {
                        setMutationInProgress(false);

                        if (isValidationError) {
                          notify.error(
                            !!orderConfig?.alternativeName
                              ? intl.formatMessage(
                                  {
                                    defaultMessage: 'There was a problem creating an order: {error}',
                                    description: 'Create order validation alternative error message',
                                  },
                                  { error },
                                )
                              : intl.formatMessage(
                                  {
                                    defaultMessage: 'There was a problem creating an order: {error}',
                                    description: 'Create order validation error message',
                                  },
                                  { error },
                                ),
                          );
                        } else {
                          notifyServerError(error, intl);
                        }
                      },
                    });
                    setMutationQuery(createOrderQuery);
                  }
                },
                (error) => {
                  handleQueryError(error);
                },
              );
              return existsQuery;
            });
          } else {
            setOrderModalOpened(true);
          }
        }
      }
    },
    handleOrderFieldClear: () => {
      clearOrderInputValue();
      setSelectedOrder(null);
    },
    handleProductFieldEnterPress: (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        if (!mutationInProgress) {
          const fieldRegexp = formatECMAScriptRegexp(getProductFormFieldConfig(productConfig, 'number')?.regexp || '');
          const isValidNumber = !!productInputValue && (!fieldRegexp || RegExp(fieldRegexp).exec(productInputValue));

          if (isValidNumber && productConfig.formFieldsConfigs.length === 1) {
            setMutationInProgress(true);

            setCheckIfExistsQuery((store) => {
              const existsQuery = store.queryProducts({ filter: { number_equals: productInputValue } }, productsQS.ALL);
              existsQuery.then(
                ({ products }) => {
                  if (products.length === 1) {
                    setSelectedProduct(products[0]);
                    setMutationInProgress(false);
                  } else {
                    const product = ProductModel.create({
                      id: uuidv4(),
                      name: productInputValue,
                      number: productInputValue,
                    });
                    const createProductQuery = productsMutations.create({
                      rootStore,
                      product,
                      onSuccess: () => {
                        rootStore.addProduct(product);

                        setSelectedProduct(product);
                        setMutationInProgress(false);

                        notify.success(
                          !!productConfig?.alternativeName
                            ? intl.formatMessage({
                                defaultMessage: 'Product successfully created',
                                description: 'Product form notify alternative message success',
                              })
                            : intl.formatMessage({
                                defaultMessage: 'Product successfully created',
                                description: 'Product form notify message success',
                              }),
                        );
                      },
                      onError: (error, isValidationError) => {
                        setMutationInProgress(false);

                        if (isValidationError) {
                          notify.error(
                            !!orderConfig?.alternativeName
                              ? intl.formatMessage(
                                  {
                                    defaultMessage: 'There was a problem creating the product: {error}',
                                    description: 'Create product validation alternative error message',
                                  },
                                  { error },
                                )
                              : intl.formatMessage(
                                  {
                                    defaultMessage: 'There was a problem creating the product: {error}',
                                    description: 'Create product validation error message',
                                  },
                                  { error },
                                ),
                          );
                        } else {
                          notifyServerError(error, intl);
                        }
                      },
                    });
                    setMutationQuery(createProductQuery);
                  }
                },
                (error) => {
                  handleQueryError(error);
                },
              );
              return existsQuery;
            });
          } else {
            setProductModalOpened(true);
          }
        }
      }
    },
    handleProductFieldClear: () => {
      clearProductInputValue();
      setSelectedProduct(null);
    },
    handleCancel: () => {
      clearOrderInputValue();
      clearProductInputValue();
      setOrderExecutionModalOpened(false);

      clearSelected();
    },
    handleSubmit: async (e: React.FormEvent<HTMLFormElement>) => {
      const success = await submit(e);

      if (success) {
        setOrderExecutionModalOpened(false);
        clearSelected();
      }
    },
  };
};

export default useHandlers;
