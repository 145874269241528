import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from 'react-switch';

import { notify, useLoginStatus } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import { Moment } from 'moment';

import { ConfirmationModal, DialogMessage, DialogSubtitle, Separator } from '@/components/core';
import { SwitchLabel } from '@/components/forms';
import { getWorkplaceConfig, mapScreenToPath } from '@/helpers';
import { notifyServerError } from '@/helpers/errors';
import { buildScreenSaverURL } from '@/helpers/urls/buildScreenSaverURL';
import { useStore } from '@/hooks';
import { OrderExecutionModelType, ReasonModelType, ScreenEnum, StatusChangeModelType, useQuery } from '@/models';
import HistoryItemModel, { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import { useCompanyData, useWorkplaceData } from '@/providers';
import { theme } from '@/theme';
import { ReasonsParamTypes } from '@/types';

interface FinishOrderExecutionModalProps {
  open?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orderExecution: OrderExecutionModelType;
  lastStatusChange: StatusChangeModelType;
}

type FinishOrderExecutionMode = 'pause' | 'stop';

const FinishOrderExecutionModal: React.FC<FinishOrderExecutionModalProps> = observer(
  ({ open, setOpen, orderExecution, lastStatusChange }) => {
    const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
    const rootStore = useStore();
    const { authResponse } = useLoginStatus();
    const { data: companyData, loading: companyDataLoading } = useCompanyData();
    const { data: workplaceData, loading: workplaceDataLoading } = useWorkplaceData();
    const { setQuery } = useQuery();
    const [mode, setMode] = useState<FinishOrderExecutionMode>('pause');
    const [afterFinishReason, setAfterFinishReason] = useState<ReasonModelType | null>();
    const intl = useIntl();
    const workplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);
    const screenSaverConfig = workplaceConfig?.screenSaverConfig;
    const redirectToScreenSaver = screenSaverConfig?.enabled && screenSaverConfig?.displayAfterOrderCreated;
    const defaultScreen = workplaceConfig?.defaultScreen || ScreenEnum.STATUS_SCREEN;
    const navigate = useNavigate();

    useEffect(() => {
      if (!companyDataLoading && !workplaceDataLoading) {
        if (mode === 'pause') {
          setAfterFinishReason(
            workplaceData.reasons.find(
              (reason) => reason.id === workplaceConfig?.orderConfig.afterPauseOrderExecutionReasonId,
            ),
          );
        } else {
          setAfterFinishReason(
            workplaceData.reasons.find(
              (reason) => reason.id === workplaceConfig?.orderConfig.afterStopOrderExecutionReasonId,
            ),
          );
        }
      }
    }, [
      companyDataLoading,
      workplaceData,
      workplaceDataLoading,
      mode,
      workplaceConfig?.orderConfig.afterPauseOrderExecutionReasonId,
      workplaceConfig?.orderConfig.afterStopOrderExecutionReasonId,
    ]);

    const toggleMode = () => {
      if (mode === 'pause') {
        setMode('stop');
      } else {
        setMode('pause');
      }
    };

    const getTitleText = () => {
      if (mode === 'pause') {
        return intl.formatMessage({
          defaultMessage: 'Suspend order execution',
          description: 'Finish order execution modal suspend title',
        });
      }
      return intl.formatMessage({
        defaultMessage: 'Finish order execution',
        description: 'Finish order execution modal finish title',
      });
    };

    const getConfirmationText = (): string => {
      if (mode === 'pause') {
        return intl.formatMessage({
          defaultMessage: 'Are you sure you want to suspend the execution of the order?',
          description: 'Finish order execution modal suspend confirmation text',
        });
      }
      return intl.formatMessage({
        defaultMessage: 'Are you sure you want to finish the execution of the order?',
        description: 'Finish order execution modal finish confirmation text',
      });
    };

    const getDetailsText = (): string => {
      return intl.formatMessage(
        {
          defaultMessage: 'The system will automatically set the new status: {reasonName}',
          description: 'Finish order execution modal details set new status text',
        },
        { reasonName: afterFinishReason?.name || lastStatusChange.reason.name },
      );
    };

    const handleConfirm = () => {
      const { reason } = lastStatusChange;

      if (authResponse) {
        let createdStatusChangeHistoryItem: HistoryItemModelType;
        let createdOrderInfoHistoryItem: HistoryItemModelType;

        if (mode === 'pause') {
          setQuery(() =>
            orderExecution.pause({
              workplaceId: workplaceID,
              userData: authResponse.user,
              lastStatusChange,
              reason: afterFinishReason || reason,
              optimisticUpdate: (statusChange) => {
                createdStatusChangeHistoryItem = HistoryItemModel.create({
                  date: statusChange.startedAt as Moment,
                  instance: castToReferenceSnapshot(statusChange),
                  type: HistoryItemType.STATUS_CHANGE,
                });

                createdOrderInfoHistoryItem = HistoryItemModel.create({
                  date: statusChange.startedAt as Moment,
                  instance: castToReferenceSnapshot(orderExecution),
                  type: HistoryItemType.ORDER_EXECUTION_END,
                });

                rootStore.addHistoryItem(createdOrderInfoHistoryItem);
                rootStore.addHistoryItem(createdStatusChangeHistoryItem);

                if (redirectToScreenSaver) {
                  navigate(buildScreenSaverURL(workplaceID));
                } else {
                  navigate(mapScreenToPath(defaultScreen, workplaceID));
                }
              },
              revertUpdate: () => {
                rootStore.removeHistoryItem(createdOrderInfoHistoryItem);
                rootStore.removeHistoryItem(createdStatusChangeHistoryItem);
              },
              onSuccess: () => {
                notify.success(
                  intl.formatMessage({
                    defaultMessage: 'Order execution has been suspended',
                    description: 'Order execution suspended notify success message',
                  }),
                );
              },
              onError: (error) => {
                notifyServerError(error, intl);
              },
            }),
          );
        } else {
          setQuery(() =>
            orderExecution.stop({
              workplaceId: workplaceID,
              userData: authResponse.user,
              lastStatusChange,
              reason: afterFinishReason || reason,
              optimisticUpdate: (statusChange) => {
                createdStatusChangeHistoryItem = HistoryItemModel.create({
                  date: statusChange.startedAt as Moment,
                  instance: castToReferenceSnapshot(statusChange),
                  type: HistoryItemType.STATUS_CHANGE,
                });

                createdOrderInfoHistoryItem = HistoryItemModel.create({
                  date: statusChange.startedAt as Moment,
                  instance: castToReferenceSnapshot(orderExecution),
                  type: HistoryItemType.ORDER_EXECUTION_END,
                });

                rootStore.addHistoryItem(createdOrderInfoHistoryItem);
                rootStore.addHistoryItem(createdStatusChangeHistoryItem);

                if (redirectToScreenSaver) {
                  navigate(buildScreenSaverURL(workplaceID));
                } else {
                  navigate(mapScreenToPath(defaultScreen, workplaceID));
                }
              },
              revertUpdate: () => {
                rootStore.removeHistoryItem(createdOrderInfoHistoryItem);
                rootStore.removeHistoryItem(createdStatusChangeHistoryItem);
              },
              onSuccess: () => {
                notify.success(
                  intl.formatMessage({
                    defaultMessage: 'Order execution has been finished',
                    description: 'Order execution finished notify success message',
                  }),
                );
              },
              onError: (error) => {
                notifyServerError(error, intl);
              },
            }),
          );
        }
        setOpen(false);
        setMode('pause');
      }
    };

    const handleCancel = () => {
      setOpen(false);
      setMode('pause');
    };

    return (
      <ConfirmationModal
        isOpen={open}
        title={getTitleText()}
        data-testid="finish-order-execution-modal"
        onSuccess={handleConfirm}
        onFailure={handleCancel}
      >
        <DialogSubtitle style={{ textAlign: 'center' }}>{getConfirmationText()}</DialogSubtitle>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <SwitchLabel htmlFor="finish-order-execution-mode-switch">
          <Switch
            id="finish-order-execution-mode-switch"
            data-testid="finish-order-execution-mode-switch"
            checked={mode === 'stop'}
            onChange={toggleMode}
            onColor={theme.colors.dodgerBlue}
          />
          <span>
            <FormattedMessage
              defaultMessage="The order is complete and will not be continued in the future"
              description="Finish order execution modal mode switch text"
            />
          </span>
        </SwitchLabel>
        <Separator
          text={intl.formatMessage({
            defaultMessage: 'Important',
            description: 'Finish order execution modal separator text',
          })}
          important
        />
        <DialogMessage style={{ fontSize: '90%' }}>{getDetailsText()}</DialogMessage>
      </ConfirmationModal>
    );
  },
);

export default FinishOrderExecutionModal;
