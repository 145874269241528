/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import { factory } from 'factory-girl';

// overridden buildMany methods provides only attrs related to one model
factory.buildMany = async function (name, num, attrs, buildOptions = {}) {
  const adapter = this.getAdapter(name);
  const factory = this.getFactory(name);

  factory.buildMany = async function (adapter, num, attrsArray = [], buildOptionsArray = [], buildCallbacks = true) {
    const attrs = await this.attrsMany(num, attrsArray, buildOptionsArray);
    const models = attrs.map((attr) => adapter.build(this.Model, attr));

    return Promise.all(models).then((builtModels) =>
      this.options.afterBuild && buildCallbacks
        ? Promise.all(
            builtModels.map((builtModel, i) => this.options.afterBuild(builtModel, attrsArray[i], buildOptionsArray)),
          )
        : builtModels,
    );
  };

  return factory
    .buildMany(adapter, num, attrs, buildOptions)
    .then((models) =>
      this.options.afterBuild
        ? Promise.all(models.map((model, i) => this.options.afterBuild(model, attrs[i], buildOptions)))
        : models,
    );
};
export { factory };
