import { Navigate, Outlet } from 'react-router-dom';

import DataLoadingPage from '@/pages/DataLoadingPage';
import NotFoundPage from '@/pages/NotFoundPage';
import ScreenSaverPage from '@/pages/ScreenSaverPage';
import { ServicePage } from '@/pages/ServicePage';
import WorkplacePage from '@/pages/WorkplacePage';
import WorkplaceSelectPage from '@/pages/WorkplaceSelectPage';
import { CompanyDataProvider, useCompanyData, WorkplaceDataProvider } from '@/providers';

interface DataProviderProps {
  children?: React.ReactNode;
}

const CompanyPageContext: React.FC<DataProviderProps> = () => (
  <CompanyDataProvider>
    <Outlet />
  </CompanyDataProvider>
);

const WorkplacePageContext: React.FC<DataProviderProps> = () => {
  const { loading: companyDataLoading } = useCompanyData();

  return companyDataLoading ? (
    <DataLoadingPage />
  ) : (
    <WorkplaceDataProvider>
      <Outlet />
    </WorkplaceDataProvider>
  );
};

const routes = (isAuthenticated: boolean) => [
  {
    path: '/',
    element: isAuthenticated ? <CompanyPageContext /> : <Navigate to="/login" />,
    children: [
      {
        path: '',
        element: <Navigate to="/workplaces" />,
      },
      {
        path: 'service-page',
        element: <ServicePage />,
      },
      {
        path: 'workplaces',
        element: <WorkplaceSelectPage />,
      },
      {
        path: '/workplaces/:workplaceID',
        element: <WorkplacePageContext />,
        children: [
          {
            path: 'screen-saver',
            element: <ScreenSaverPage />,
          },
          {
            path: 'counters',
            element: <WorkplacePage />,
          },
          {
            path: 'reasons/:reasonID',
            element: <WorkplacePage />,
          },
          {
            path: 'reasons',
            element: <WorkplacePage />,
          },
          {
            path: 'status-screen',
            element: <WorkplacePage />,
          },
          {
            path: '',
            element: <WorkplacePage />,
          },
        ],
      },
    ],
  },
  {
    path: '/404',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default routes;
