import React, { forwardRef } from 'react';

import { Input as StyledInput, InputAdornment, InputField } from './styled';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.LegacyRef<HTMLInputElement>;
  focus?: boolean;
  error?: boolean;
  startAdornment?: React.ReactNode;
  startAdornmentSize?: 'small' | 'large';
  endAdornment?: React.ReactNode;
  endAdornmentSize?: 'small' | 'large';
  gridArea?: string;
  containerStyles?: React.CSSProperties;
  'data-testid'?: string;
}

export const Input: React.FC<InputProps> = forwardRef<HTMLInputElement>(
  (
    {
      error,
      startAdornment,
      startAdornmentSize = 'small',
      endAdornment,
      endAdornmentSize = 'small',
      gridArea,
      containerStyles,
      ...props
    }: InputProps,
    forwardedRef,
  ) => {
    return (
      <StyledInput style={containerStyles} gridArea={gridArea} error={error}>
        {startAdornment && (
          <InputAdornment position="start" size={startAdornmentSize} visible>
            {startAdornment}
          </InputAdornment>
        )}
        <InputField {...props} ref={forwardedRef} />
        <InputAdornment position="end" size={endAdornmentSize} visible={!!endAdornment}>
          {endAdornment}
        </InputAdornment>
      </StyledInput>
    );
  },
);
