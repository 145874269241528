import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@/components/core';
import { getProductDisplayName } from '@/helpers/products';
import { useStartOrderExecution, useStore } from '@/hooks';
import { ItemModelType, OrderExecutionModel, useQuery } from '@/models';
import { ReasonsParamTypes } from '@/types';

import { OrderExecutionModalProps } from '..';

import {
  ItemContainer,
  ItemDetails,
  ItemDetailsLabel,
  ItemDetailsRow,
  ItemDetailsValue,
  ItemRadioButton,
  ItemsList,
} from './styled';

const MAX_ITEMS_COUNT = 100;

const PlannedModeView: React.FC<OrderExecutionModalProps> = observer(({ state }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const rootStore = useStore();
  const { data } = useQuery((store) =>
    store.queryItems({ filter: { order_finished_at_null: 'true', order_workplace_id_equals: workplaceID } }),
  );
  const startOrderExecution = useStartOrderExecution(state);
  const [saving, setSaving] = useState<boolean>(false);
  const [sortedItems, setSortedItems] = useState<ItemModelType[]>([]);

  const { selectedItem, setSelectedItem, setOrderExecutionModalOpened } = state;
  const isSelected = (item: ItemModelType) => selectedItem?.id === item.id;

  useEffect(() => {
    if (data?.items.length) {
      setSortedItems(
        data.items
          .sort((i1, i2) => {
            const order1 = i1.order;
            const order2 = i2.order;

            if (order1.position === null) return 1;
            if (order2.position === null) return -1;

            return (order1.position || 0) - (order2.position || 0);
          })
          .slice(0, MAX_ITEMS_COUNT),
      );
    }
  }, [data?.items]);

  useEffect(() => {
    if (!selectedItem && data?.items.length) {
      setSelectedItem(data.items[0]);
    }
  }, [data, selectedItem, setSelectedItem]);

  const handleItemChange = (item: ItemModelType) => {
    setSelectedItem(item);
  };

  const handleStart = () => {
    if (selectedItem && startOrderExecution) {
      setSaving(true);

      const orderExection = OrderExecutionModel.create({
        id: uuidv4(),
        workplaceId: workplaceID,
      });
      rootStore.addOrderExecution(orderExection);

      orderExection.update({
        item: selectedItem,
        order: selectedItem.order,
        product: selectedItem.product,
        quantity: selectedItem.count,
      });

      startOrderExecution(orderExection)
        .currentPromise()
        .then(() => {
          setSaving(false);
          setOrderExecutionModalOpened(false);

          return null;
        })
        .catch(() => {
          setSaving(false);
        });
    }
  };

  return (
    <>
      <ItemsList>
        {sortedItems.map((item) => (
          <ItemContainer key={item.id} active={isSelected(item)} onClick={() => handleItemChange(item)}>
            <ItemDetails active={isSelected(item)}>
              <ItemDetailsRow>
                <ItemDetailsLabel>
                  <FormattedMessage defaultMessage="Order" description="Planned mode view item order label" />:
                </ItemDetailsLabel>
                <ItemDetailsValue>{item.order.number}</ItemDetailsValue>
              </ItemDetailsRow>
              <ItemDetailsRow>
                <ItemDetailsLabel>
                  <FormattedMessage defaultMessage="Product" description="Planned mode view item product label" />:
                </ItemDetailsLabel>
                <ItemDetailsValue>{getProductDisplayName(item.product)}</ItemDetailsValue>
              </ItemDetailsRow>
              <ItemDetailsRow>
                <ItemDetailsLabel>
                  <FormattedMessage defaultMessage="Quantity" description="Planned mode view item quantity label" />:
                </ItemDetailsLabel>
                <ItemDetailsValue>{item.count}</ItemDetailsValue>
              </ItemDetailsRow>
            </ItemDetails>
            <ItemRadioButton type="radio" checked={isSelected(item)} onChange={() => handleItemChange(item)} />
          </ItemContainer>
        ))}
      </ItemsList>
      <Row>
        <Col col={6}>
          <Button
            type="button"
            onClick={() => setOrderExecutionModalOpened(false)}
            data-testid="planned-mode-view-cancel-button"
          >
            <FormattedMessage defaultMessage="Cancel" description="Planned mode view cancel button" />
          </Button>
        </Col>
        <Col col={6}>
          <Button
            type="submit"
            onClick={() => handleStart()}
            data-testid="planned-mode-view-submit-button"
            disabled={saving}
            primary
          >
            <FormattedMessage defaultMessage="Start" description="Planned mode view submit button" />
          </Button>
        </Col>
      </Row>
    </>
  );
});

export default PlannedModeView;
