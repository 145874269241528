/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { getRoot, IAnyModelType, IMSTMap, types } from 'mobx-state-tree';

import { HistoryDataType } from '@/types';

import { OrderExecutionModel } from './OrderExecutionModel';
import { ShiftModel } from './ShiftModel';
import { StatusChangeModel } from './StatusChangeModel';
import { DatePrimitive } from './types';
import { UserPresenceModel } from './UserPresenceModel';

export enum HistoryItemType {
  DATE_SEPARATOR = 'DATE_SEPARATOR',
  STATUS_CHANGE = 'STATUS_CHANGE',
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  ORDER_EXECUTION_START = 'ORDER_EXECUTION_START',
  ORDER_EXECUTION_END = 'ORDER_EXECUTION_END',
  SHIFT_START = 'SHIFT_START',
  SHIFT_END = 'SHIFT_END',
}

// eslint-disable-next-line no-underscore-dangle
export const getInstanceId = (item: HistoryDataType): string => `${item.__typename}_${item.id}`;

const HistoryItemModel = types.model({
  date: DatePrimitive,
  instance: types.maybeNull(
    types.reference(types.union(OrderExecutionModel, ShiftModel, StatusChangeModel, UserPresenceModel), {
      get(identifier: string, parent: IAnyModelType) {
        const rootStore = getRoot(parent);

        let instancesList: IMSTMap<IAnyModelType>;
        let instanceTypename: string;

        // ignore typescript because of referencing self type
        switch (parent.type) {
          case HistoryItemType.SHIFT_START:
          case HistoryItemType.SHIFT_END:
            instancesList = rootStore.shifts;
            instanceTypename = 'Shift';
            break;
          case HistoryItemType.CHECK_IN:
          case HistoryItemType.CHECK_OUT:
            instancesList = rootStore.userPresences;
            instanceTypename = 'UserPresence';
            break;
          case HistoryItemType.ORDER_EXECUTION_START:
          case HistoryItemType.ORDER_EXECUTION_END:
            instancesList = rootStore.orderExecutions;
            instanceTypename = 'OrderExecution';
            break;
          default:
            instancesList = rootStore.statusChanges;
            instanceTypename = 'StatusChange';
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return [...instancesList.values()].find(
          // eslint-disable-next-line
          (item) => item.id === identifier.replace(`${instanceTypename}_`, ''),
        );
      },
      set(value: HistoryDataType) {
        // eslint-disable-next-line no-underscore-dangle
        return getInstanceId(value);
      },
    }),
  ),
  type: types.enumeration(Object.values(HistoryItemType)),
});

export type HistoryItemModelType = typeof HistoryItemModel.Type;

export default HistoryItemModel;
