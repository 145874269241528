import styled from 'styled-components';

import { HistoryItemContainer } from '../HistoryRow/styled';

export const HistoryListContainer = styled.ul`
  padding: 0;
  margin: 0;

  & ${HistoryItemContainer}:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.youtubeBlack};
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
