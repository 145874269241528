import { createUserPresencePayloadModelPrimitives } from '@/models/CreateUserPresencePayloadModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { removeUserPresencePayloadModelPrimitives } from '@/models/RemoveUserPresencePayloadModel.base';
import { removeWorkplaceUsersPresencesPayloadModelPrimitives } from '@/models/RemoveWorkplaceUsersPresencesPayloadModel.base';
import { userModelPrimitives } from '@/models/UserModel.base';
import { userPresenceModelPrimitives } from '@/models/UserPresenceModel.base';

export default {
  ALL: userPresenceModelPrimitives.user(userModelPrimitives).author(userModelPrimitives).toString(),
  CURRENT: userPresenceModelPrimitives.toString(),
  REMOVE_WORKPLACE_USER_PRESENCES_ALL: removeWorkplaceUsersPresencesPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .toString(),
  CREATE_USER_PRESENCE_PAYLOAD_ALL: createUserPresencePayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .userPresence(userPresenceModelPrimitives)
    .toString(),
  REMOVE_USER_PRESENCE_PAYLOAD_ALL: removeUserPresencePayloadModelPrimitives.errors(mutationErrorModelPrimitives),
};
