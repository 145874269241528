import { IntlShape } from 'react-intl';

import { Button } from '@/components/core';
import { UserPresenceModelType } from '@/models';
import { ListItem } from '@/types';

interface ICreatePresencesListItems {
  presences: UserPresenceModelType[];
  intl: IntlShape;
  onRemoveSinglePresenceButtonClick: (id: string) => void;
}

export const createPresencesListItems = ({
  intl,
  presences,
  onRemoveSinglePresenceButtonClick,
}: ICreatePresencesListItems): ListItem[] => {
  const list: ListItem[] = [];

  const presencesButtonStyle = {
    height: '100%',
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '5px 20px',
  };

  presences.forEach((presence) => {
    list.push({
      dataTestId: `presence-row-${presence.id}`,
      label: presence.user.name as string,
      value: (
        <Button
          size="small"
          key={presence.id}
          onClick={() => {
            onRemoveSinglePresenceButtonClick(presence.id);
          }}
          data-testid={`presence-removal-button-${presence.id}`}
          style={presencesButtonStyle}
        >
          {intl.formatMessage({
            defaultMessage: 'Remove presence',
            description: 'Presences list item button remove presence',
          })}
        </Button>
      ),
    });
  });
  return list;
};
