/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Instance } from 'mobx-state-tree';

import { StatusChangeModelType } from './StatusChangeModel';
import { WorkplaceModelBase } from './WorkplaceModel.base';

/* The TypeScript type of an instance of WorkplaceModel */
export interface WorkplaceModelType extends Instance<typeof WorkplaceModel.Type> {}

/* A graphql query fragment builders for WorkplaceModel */
export { selectFromWorkplace, workplaceModelPrimitives, WorkplaceModelSelector } from './WorkplaceModel.base';

/**
 * WorkplaceModel
 */
export const WorkplaceModel = WorkplaceModelBase.actions((self) => ({
  updateCurrentStatusChange(statusChange: StatusChangeModelType) {
    self.currentStatusChange = statusChange;
  },
}));
