import { useEffect, useState } from 'react';

import { ProductConfigModelType } from '@/models';
import { useCompanyData } from '@/providers';

const useProductConfig = () => {
  const { data, loading } = useCompanyData();
  const [productConfig, setProductConfig] = useState<ProductConfigModelType>();

  useEffect(() => {
    if (!loading) setProductConfig(data?.companyConfig?.productConfig);
  }, [data, loading]);

  return productConfig;
};

export default useProductConfig;
