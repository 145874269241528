import { Button as StyledButton } from './styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  size?: 'small' | 'normal' | 'large';
  'data-testid'?: string;
  selected?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  type,
  primary,
  size = 'normal',
  onClick,
  disabled,
  style,
  'data-testid': dataTestID,
  selected,
}) => (
  <StyledButton
    style={style}
    type={type}
    $primary={primary}
    size={size}
    whileTap="pressed"
    onClick={onClick}
    disabled={disabled}
    data-testid={dataTestID}
    selected={selected}
  >
    {children}
  </StyledButton>
);

export { ButtonHint, ButtonIcon } from './styled';
