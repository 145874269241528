/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * NotificationBase
 * auto generated base class for the model NotificationModel.
 */
export const NotificationModelBase = ModelBase
  .named('Notification')
  .props({
    __typename: types.optional(types.literal("Notification"), "Notification"),
    channel: types.union(types.undefined, types.null, types.string),
    details: types.union(types.undefined, types.null, types.string),
    emitterId: types.union(types.undefined, types.string),
    emitterType: types.union(types.undefined, types.string),
    id: types.identifier,
    message: types.union(types.undefined, types.string),
    receiverId: types.union(types.undefined, types.string),
    receiverType: types.union(types.undefined, types.string),
    status: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class NotificationModelSelector extends QueryBuilder {
  get channel() { return this.__attr(`channel`) }
  get details() { return this.__attr(`details`) }
  get emitterId() { return this.__attr(`emitterId`) }
  get emitterType() { return this.__attr(`emitterType`) }
  get id() { return this.__attr(`id`) }
  get message() { return this.__attr(`message`) }
  get receiverId() { return this.__attr(`receiverId`) }
  get receiverType() { return this.__attr(`receiverType`) }
  get status() { return this.__attr(`status`) }
}
export function selectFromNotification() {
  return new NotificationModelSelector()
}

export const notificationModelPrimitives = selectFromNotification().channel.details.emitterId.emitterType.message.receiverId.receiverType.status
