import { useIntl } from 'react-intl';

import { UserPresenceModelType } from '@/models';
import { HistoryItemDetail } from '@/types';

const useUserPresenceModalDetails = (userPresence: UserPresenceModelType): HistoryItemDetail[] => {
  const intl = useIntl();
  let modalDetails: HistoryItemDetail[] = [];
  if (userPresence?.startedAt) {
    modalDetails = [
      {
        label: intl.formatMessage({
          defaultMessage: 'Login date',
          description: 'User Presence login date detail',
        }),
        value: userPresence.startedAt.format('YYYY-MM-DD'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Login time',
          description: 'User Presence login time detail',
        }),
        value: userPresence.startedAt.format('HH:mm:ss'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Person details',
          description: 'User Presence person details',
        }),
        value: userPresence.user.name,
      },
    ];
  }
  if (userPresence?.finishedAt) {
    modalDetails = [
      {
        label: intl.formatMessage({
          defaultMessage: 'Logout date',
          description: 'User Presence logout date detail',
        }),
        value: userPresence.finishedAt.format('YYYY-MM-DD'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Logout time',
          description: 'User Presence logout time detail',
        }),
        value: userPresence.finishedAt.format('HH:mm:ss'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Person details',
          description: 'User Presence person details',
        }),
        value: userPresence.user.name,
      },
    ];
  }

  return modalDetails;
};

export default useUserPresenceModalDetails;
