/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MutationErrorModel, MutationErrorModelType } from "./MutationErrorModel"
import { MutationErrorModelSelector } from "./MutationErrorModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  product: ProductModelType;
}

/**
 * CreateProductPayloadBase
 * auto generated base class for the model CreateProductPayloadModel.
 *
 * Autogenerated return type of CreateProduct
 */
export const CreateProductPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('CreateProductPayload')
  .props({
    __typename: types.optional(types.literal("CreateProductPayload"), "CreateProductPayload"),
    errors: types.union(types.undefined, types.null, types.array(types.late((): any => MutationErrorModel))),
    product: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ProductModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CreateProductPayloadModelSelector extends QueryBuilder {
  errors(builder: string | MutationErrorModelSelector | ((selector: MutationErrorModelSelector) => MutationErrorModelSelector) | undefined) { return this.__child(`errors`, MutationErrorModelSelector, builder) }
  product(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined) { return this.__child(`product`, ProductModelSelector, builder) }
}
export function selectFromCreateProductPayload() {
  return new CreateProductPayloadModelSelector()
}

export const createProductPayloadModelPrimitives = selectFromCreateProductPayload()
