import styled, { css } from 'styled-components';

interface ItemProps {
  isHighlighted?: boolean;
  isSelected?: boolean;
}

export const Menu = styled.div`
  margin-top: 10px;
  overflow: auto;
  height: 250px;
  color: rgba(255, 255, 255, 0.75);
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.05);
  z-index: 1000;
`;

export const Item = styled.div<ItemProps>`
  padding: 1em 2em;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  &:nth-child(2n-1) {
    background-color: ${({ theme }) => theme.colors.youtubeBlack};
  }
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.dodgerBlue};
      &:nth-child(2n-1) {
        background-color: ${({ theme }) => theme.colors.dodgerBlue};
      }
    `}
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.05);
`;
