import styled, { css } from 'styled-components';

interface ItemContainerProps {
  active?: boolean;
}

interface ItemDetailsProps {
  active?: boolean;
}

export const ItemsList = styled.ul`
  list-style: none;
  max-height: 465px;
  padding: 0 10px;
  overflow-y: auto;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.02);
`;

export const ItemContainer = styled.li<ItemContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px 15px;
  font-size: 14px;
  border: 2px solid #424242;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    css`
      border-color: #155ed1;
      background: rgba(21, 94, 209, 0.35);
    `}
`;

export const ItemDetails = styled.div<ItemDetailsProps>`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  margin-right: 10px;
  padding-right: 20px;
  border-right: 1px solid #424242;
  ${({ active }) =>
    active &&
    css`
      border-right-color: rgba(21, 94, 209, 0.75);
    `}
`;

export const ItemDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-bottom: 0.5em;
    font-size: 16px;
    line-height: 1.8em;
    border-bottom: 1px dashed #595959;
  }
`;

export const ItemDetailsLabel = styled.p`
  margin: 0;
  font-weight: 500;
`;

export const ItemDetailsValue = styled.p`
  margin: 0;
  font-weight: 400;
`;

export const ItemRadioButton = styled.input`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;
