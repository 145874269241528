import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { notificationModelPrimitives } from '@/models/NotificationModel.base';
import { updateNotificationPayloadModelPrimitives } from '@/models/UpdateNotificationPayloadModel.base';

export default {
  ALL: notificationModelPrimitives.toString(),
  UPDATE_NOTIFICATION_PAYLOAD_ALL: updateNotificationPayloadModelPrimitives
    .notification(notificationModelPrimitives)
    .errors(mutationErrorModelPrimitives)
    .toString(),
};
