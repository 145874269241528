import { types } from 'mobx-state-tree';
import type { Moment } from 'moment';
import moment from 'moment';

export const DatePrimitive = types.custom<string, Moment | null>({
  name: 'Date',
  fromSnapshot(value: string) {
    return value ? moment(value) : null;
  },
  toSnapshot(value: Moment) {
    return value.toISOString();
  },
  isTargetType(value: string | Moment): boolean {
    return value instanceof moment;
  },
  getValidationMessage(value: string): string {
    if (value && moment(value).isValid()) return '';
    return `'${value}' doesn't look like a valid date`;
  },
});
