/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AndonLightColorEnumType } from "./AndonLightColorEnum"
import { MetricTypeEnumType } from "./MetricTypeEnum"
import { MetricUnitEnumType } from "./MetricUnitEnum"
import { RootStoreType } from "./index"


/**
 * MetricValueBase
 * auto generated base class for the model MetricValueModel.
 */
export const MetricValueModelBase = ModelBase
  .named('MetricValue')
  .props({
    __typename: types.optional(types.literal("MetricValue"), "MetricValue"),
    active: types.union(types.undefined, types.boolean),
    id: types.identifier,
    metricId: types.union(types.undefined, types.string),
    statusColor: types.union(types.undefined, AndonLightColorEnumType),
    textValue: types.union(types.undefined, types.null, types.string),
    timer: types.union(types.undefined, types.null, types.integer),
    type: types.union(types.undefined, MetricTypeEnumType),
    unit: types.union(types.undefined, types.null, MetricUnitEnumType),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MetricValueModelSelector extends QueryBuilder {
  get active() { return this.__attr(`active`) }
  get id() { return this.__attr(`id`) }
  get metricId() { return this.__attr(`metricId`) }
  get statusColor() { return this.__attr(`statusColor`) }
  get textValue() { return this.__attr(`textValue`) }
  get timer() { return this.__attr(`timer`) }
  get type() { return this.__attr(`type`) }
  get unit() { return this.__attr(`unit`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
}
export function selectFromMetricValue() {
  return new MetricValueModelSelector()
}

export const metricValueModelPrimitives = selectFromMetricValue().active.metricId.statusColor.textValue.timer.type.unit.workplaceId
