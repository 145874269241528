import { OrderExecutionModelType, RootStoreType } from '@/models';

export const getWorkplaceOrdersExecutions = (
  workplaceId: string,
  ordersExecutions: OrderExecutionModelType[],
): OrderExecutionModelType[] => {
  return ordersExecutions.filter((orderExecution) => orderExecution.workplaceId === workplaceId);
};

export const getLastOrderExecution = (
  rootStore: RootStoreType,
  workplaceId: string,
): OrderExecutionModelType | null => {
  return [...rootStore.orderExecutions.values()]
    .filter((orderExecution) => orderExecution.workplaceId === workplaceId)
    .sort((v1, v2) => {
      if (v2.startedAt) return v2.startedAt.diff(v1.startedAt);
      return 0;
    })[0];
};
