import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { Button, Dialog, DialogSubtitle } from '@/components/core';
import { OrderExecutionModelType, useQuery } from '@/models';
import { standardRatesQS } from '@/queries';

import { List, ListItemButton } from './styled';

interface StandardRateModalProps {
  open: boolean;
  orderExecution: OrderExecutionModelType;
}

const StandardRateModal: React.FC<StandardRateModalProps> = observer(({ open, orderExecution }) => {
  const { data, loading } = useQuery((store) =>
    store.queryStandardRates({ orderExecutionId: orderExecution.id }, standardRatesQS.ALL),
  );
  const [selectedStandardRateId, setSelectedStandardRateId] = useState<string | null>(null);
  const intl = useIntl();

  const handleConfirm = async () => {
    if (selectedStandardRateId) {
      await orderExecution.updateStandardRate({
        standardRateId: selectedStandardRateId,
        optimisticUpdate: () => setSelectedStandardRateId(null),
      });
    }
  };

  return (
    <Dialog
      title={intl.formatMessage({
        defaultMessage: 'Choose standard rate',
        description: 'Standard rate modal title',
      })}
      open={open}
      data-testid="standard-rate-modal"
    >
      <DialogSubtitle>
        <FormattedMessage
          defaultMessage="Select the correct performance standard in accordance with the contract work performed"
          description="Standard rate modal subtitle"
        />
      </DialogSubtitle>
      <List>
        {!loading &&
          data?.standardRates.map(({ id, label }) => (
            <ListItemButton
              key={id}
              data-testid={`standard-rate-${id}`}
              selected={selectedStandardRateId === id}
              onClick={() => setSelectedStandardRateId(id)}
            >
              {label}
            </ListItemButton>
          ))}
      </List>
      <Row justifyContent="end">
        <Col col={6}>
          <Button
            primary
            data-testid="standard-rate-modal-confirm-button"
            onClick={handleConfirm}
            disabled={!selectedStandardRateId}
          >
            <FormattedMessage defaultMessage="Save" description="Standard rate modal confirm button" />
          </Button>
        </Col>
      </Row>
    </Dialog>
  );
});

export default StandardRateModal;
