import { Instance } from 'mobx-state-tree';
import { Query } from 'mst-gql';

import { getMutationErrorText, handleQueryError } from '@/helpers/errors';
import { notificationsQS } from '@/queries';

import { NotificationModelBase } from './NotificationModel.base';

/* The TypeScript type of an instance of NotificationModel */
export interface NotificationModelType extends Instance<typeof NotificationModel.Type> {}

/* A graphql query fragment builders for NotificationModel */
export {
  selectFromNotification,
  notificationModelPrimitives,
  NotificationModelSelector,
} from './NotificationModel.base';

interface MarkReceivedProps {
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}
/**
 * NotificationModel
 */
export const NotificationModel = NotificationModelBase.actions((self) => ({
  markReceived({ optimisticUpdate, revertUpdate, onSuccess, onError }: MarkReceivedProps): Query {
    const query = self.store.mutateUpdateNotification(
      { id: self.id, status: 'received' },
      notificationsQS.UPDATE_NOTIFICATION_PAYLOAD_ALL,
      () => {
        self.status = 'received';

        if (optimisticUpdate) optimisticUpdate();
      },
    );
    const revert = () => {
      self.status = 'created';

      if (revertUpdate) revertUpdate();
    };
    // eslint-disable-next-line promise/catch-or-return
    query.then(
      ({ updateNotification }) => {
        const { errors } = updateNotification;

        if (errors?.length) {
          if (onError !== undefined) onError(getMutationErrorText(errors[0]));
          return revert();
        }
        if (onSuccess !== undefined) onSuccess();
        return null;
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
}));
