import { AndonLightColor } from '@/models';
import { theme } from '@/theme';

export const mapStatusColorToTextColor = (color: AndonLightColor): string => {
  switch (color) {
    case 'gray':
      return theme.colors.white;
    default:
      return theme.colors.youtubeBlack;
  }
};
