import { ReactElement } from 'react';

import { ContainerBodyWrapper, ContainerHeader, ContainerHeaderText, ContainerWrapper } from './styled';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  extraHeaderContent?: ReactElement[] | ReactElement;
  headerStyles?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  headerTextStyles?: React.CSSProperties;
  scrollable?: boolean;
  'data-testid'?: string;
  overlayComponent?: ReactElement;
  onClick?: () => void;
}

export const Container = ({
  title,
  children,
  extraHeaderContent,
  headerStyles,
  style,
  bodyStyle,
  overlayComponent,
  headerTextStyles,
  scrollable = true,
  'data-testid': dataTestID,
  onClick,
}: ContainerProps): ReactElement => {
  return (
    <ContainerWrapper onClick={onClick} style={style} data-testid={dataTestID}>
      <ContainerHeader style={headerStyles}>
        <ContainerHeaderText style={headerTextStyles}>{title}</ContainerHeaderText>
        {extraHeaderContent}
      </ContainerHeader>
      <ContainerBodyWrapper scrollable={scrollable} style={bodyStyle}>
        {overlayComponent}
        {children}
      </ContainerBodyWrapper>
    </ContainerWrapper>
  );
};
