import { Instance } from 'mobx-state-tree';
import { Query } from 'mst-gql';

import { getMutationErrorText, handleQueryError } from '@/helpers/errors';
import { countersQS } from '@/queries';

import { CounterModelBase } from './CounterModel.base';

/* The TypeScript type of an instance of CounterModel */
export interface CounterModelType extends Instance<typeof CounterModel.Type> {}

/* A graphql query fragment builders for CounterModel */
export { selectFromCounter, counterModelPrimitives, CounterModelSelector } from './CounterModel.base';

interface ISetCounterValueProps {
  orderExecutionId?: string;
  newValue: number;
  currentValue: number;
  note?: string;
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}
/**
 * CounterModel
 */
export const CounterModel = CounterModelBase.actions((self) => ({
  setCounterValue({
    orderExecutionId,
    newValue,
    currentValue,
    note,
    optimisticUpdate,
    revertUpdate,
    onSuccess,
    onError,
  }: ISetCounterValueProps): Query {
    const query = self.store.mutateSetCounter(
      { id: self.id, orderExecutionId, value: newValue, note },
      countersQS.SET_COUNTER_PAYLOAD_ALL,
      () => {
        self.value = newValue;
        if (optimisticUpdate) optimisticUpdate();
      },
    );
    const revert = () => {
      self.value = currentValue;
      if (revertUpdate) revertUpdate();
    };
    // eslint-disable-next-line promise/catch-or-return
    query.then(
      ({ setCounter }) => {
        const { errors } = setCounter;

        if (errors?.length) {
          if (onError !== undefined) onError(getMutationErrorText(errors[0]));
          return revert();
        }
        if (onSuccess !== undefined) onSuccess();
        return null;
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
}));
