import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { TimelineLoader } from '@/components/loaders/HistoryLoaders';
import { StatusChangeModelType } from '@/models';

import { TimelineElement, TimelineTimeInfo, TimelineTimeInfoContainer, TimelineWrapper } from './styled';

export interface HistoryTimelineProps {
  statuses: StatusChangeModelType[];
  loading?: boolean;
  style?: React.CSSProperties;
}

const HistoryTimeline: React.FC<HistoryTimelineProps> = observer(({ statuses, loading, style }) => {
  const intl = useIntl();

  const [updateCount, setUpdateCount] = useState<number>(0);
  const calculateCurrentStatusesDuration = useCallback(() => {
    return statuses.reduce<number>((accumulator, current) => accumulator + current.durationMinutes(new Date()), 0) || 0;
  }, [statuses]);
  const [totalDuration, setTotalDuration] = useState<number>(
    calculateCurrentStatusesDuration() > 1440 ? 1440 : calculateCurrentStatusesDuration(),
  );
  const [reversedStatuses, setReversedStatuses] = useState<StatusChangeModelType[]>();

  useEffect(() => {
    const statusesCopy = [...statuses];
    setReversedStatuses(statusesCopy.reverse());
  }, [statuses]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setUpdateCount((count) => count + 1);
    }, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setTotalDuration(calculateCurrentStatusesDuration() > 1440 ? 1440 : calculateCurrentStatusesDuration());
  }, [statuses, setTotalDuration, calculateCurrentStatusesDuration, updateCount]);

  if (loading) return <TimelineLoader />;

  return (
    <div style={{ margin: '0 20px', ...style }}>
      <TimelineWrapper data-testid="timeline">
        {reversedStatuses &&
          reversedStatuses.map(
            (status) =>
              status.reason.statusColor && (
                <TimelineElement
                  data-testid={`timeline-element-${status.id}-color-${status.reason.statusColor}`}
                  key={`${status.id}${status.author.id}${updateCount}`}
                  color={status.reason.statusColor}
                  percentWidth={totalDuration ? (status.durationMinutes(new Date()) / totalDuration) * 100 : 100}
                />
              ),
          )}
      </TimelineWrapper>
      <TimelineTimeInfoContainer>
        <TimelineTimeInfo>-{Math.floor(totalDuration / 60)}h</TimelineTimeInfo>
        {/* optionally we can place as many time points as we want */}
        {/* <TimelineTimeInfo>-{Math.floor(((totalDuration / 60) * 2) / 3)}h</TimelineTimeInfo>
        <TimelineTimeInfo>-{Math.floor(totalDuration / 60 / 3)}h</TimelineTimeInfo> */}
        <TimelineTimeInfo>
          {intl.formatMessage({ defaultMessage: 'now', description: 'History timeline time info now' })}
        </TimelineTimeInfo>
      </TimelineTimeInfoContainer>
    </div>
  );
});

export default HistoryTimeline;
