import React, { ReactElement } from 'react';

import { Row } from 'styled-bootstrap-grid';

import { WorkplaceModelType } from '@/models';

import { ListItem } from './ListItem';
import { StyledContainer } from './styled';

interface ListContainerProps {
  items: WorkplaceModelType[];
  'data-testid': string;
}

const ListContainer: React.FC<ListContainerProps> = ({ items, 'data-testid': dataTestId }): ReactElement => {
  return (
    <Row>
      <StyledContainer data-testid={dataTestId} height={Math.ceil(items.length / 2)} col={12}>
        {items.map((item) => (
          <ListItem key={item.id} item={item} />
        ))}
      </StyledContainer>
    </Row>
  );
};
export default ListContainer;
