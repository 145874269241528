import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { reaction, toJS } from 'mobx';
import { Col, Row } from 'styled-bootstrap-grid';

import { useCurrentOrderExecution, useStore } from '@/hooks';
import { CounterModelType, useQuery } from '@/models';
import { CounterModalCallback, CounterModalCallbackResult } from '@/types';

import { CounterOperation } from '../const';
import { Button, Container } from '../core';
import { Input } from '../core/Input';

import { KeyboardButtons } from './KeyboardButtons';
import {
  GridContainer,
  HeaderContentWrapper,
  Message,
  ValueDisplay,
  ValueDisplayDescription,
  ValueDisplayText,
} from './styled';

interface CountersKeyboardProps {
  selectedCounter?: CounterModelType;
  operationType: CounterOperation | undefined;
  setOperationType: Dispatch<SetStateAction<CounterOperation | undefined>>;
  setSelectedCounter: Dispatch<SetStateAction<CounterModelType | undefined>>;
  setCounterModalCallback: Dispatch<SetStateAction<CounterModalCallback | null>>;
}

const CountersKeyboard: React.FC<CountersKeyboardProps> = ({
  operationType = CounterOperation.ADD,
  selectedCounter,
  setOperationType,
  setSelectedCounter,
  setCounterModalCallback,
}) => {
  const currentOrderExecution = useCurrentOrderExecution();
  const intl = useIntl();
  const { setQuery } = useQuery();
  const [inputValue, setInputValue] = useState(``);
  const [expectedValue, setExpectedValue] = useState<number>();
  const [countersUpdateCount, setCountersUpdateCount] = useState<number>(1);
  const keyboardButtonsDisabled = !selectedCounter;
  const store = useStore();

  useEffect(() => {
    if (operationType === CounterOperation.ADD && selectedCounter?.value !== undefined) {
      setExpectedValue(selectedCounter.value + +inputValue);
    }
    if (operationType === CounterOperation.SUBTRACT && selectedCounter?.value !== undefined) {
      setExpectedValue(selectedCounter.value - +inputValue);
    }
  }, [inputValue, operationType, selectedCounter?.value]);

  useEffect(() => {
    const countersReactionDisposer = reaction(
      () => toJS(store.counters),
      () => {
        setCountersUpdateCount((c) => c + 1);
      },
    );
    return () => {
      countersReactionDisposer();
    };
  });

  const renderToastMessage = (): string | null => {
    if (selectedCounter?.name !== undefined)
      // eslint-disable-next-line default-case
      switch (operationType) {
        case CounterOperation.ADD:
          return intl.formatMessage(
            {
              defaultMessage: `Increased {counterName} by {value}`,
              description: 'Counters keyboard counter increased by custom value toast message',
            },
            { counterName: selectedCounter.name, value: inputValue },
          );
        case CounterOperation.SUBTRACT:
          return intl.formatMessage(
            {
              defaultMessage: `Decreased {counterName} by {value}`,
              description: 'Counters keyboard counter decreased by custom value toast message',
            },
            { counterName: selectedCounter.name, value: inputValue },
          );
      }
    /* istanbul ignore next */
    return null;
  };

  const onConfirmationAction = (note = ''): CounterModalCallbackResult => {
    if (operationType === CounterOperation.ADD) {
      if (selectedCounter?.value !== undefined) {
        const newValue = selectedCounter.value + +inputValue;
        setQuery(
          selectedCounter.setCounterValue({
            orderExecutionId: currentOrderExecution?.id,
            newValue,
            currentValue: selectedCounter.value,
            note,
            optimisticUpdate: () => {},
            revertUpdate: () => {
              notify.error('There was an issue trying to change value of counter');
            },
            onSuccess: () => {
              notify.success(renderToastMessage());
            },
          }),
        );
      }
    }
    if (operationType === CounterOperation.SUBTRACT) {
      if (selectedCounter?.value !== undefined) {
        const newValue = selectedCounter.value - +inputValue;
        setQuery(
          selectedCounter.setCounterValue({
            orderExecutionId: currentOrderExecution?.id,
            newValue,
            currentValue: selectedCounter.value,
            note,
            optimisticUpdate: () => {},
            revertUpdate: () => {
              notify.error('There was an issue trying to change value of counter');
            },
            onSuccess: () => {
              notify.success(renderToastMessage());
            },
          }),
        );
      }
    }
    setInputValue('');
    setOperationType(undefined);
    setCounterModalCallback(null);

    return null;
  };

  const onRejectionAction = () => {
    setInputValue('');
    setOperationType(undefined);
    setSelectedCounter(undefined);
  };

  const renderMessage = (operation?: CounterOperation): string | null => {
    if (!selectedCounter) {
      return intl.formatMessage({
        defaultMessage: 'Choose counter',
        description: 'Counters keyboard message select counter',
      });
    }
    if (selectedCounter?.name !== undefined)
      // eslint-disable-next-line default-case
      switch (operation) {
        case CounterOperation.ADD:
          return intl.formatMessage(
            {
              defaultMessage: 'How much do you want to increase counter value?',
              description: 'Counters keyboard message add operation',
            },
            { counterName: selectedCounter.name },
          );
        case CounterOperation.SUBTRACT:
          return intl.formatMessage(
            {
              defaultMessage: 'How much do you want to decrease counter value?',
              description: 'Counters keyboard message subtract operation',
            },
            { counterName: selectedCounter.name },
          );
      }
    /* istanbul ignore next */
    return intl.formatMessage({
      defaultMessage: 'Choose operation type',
      description: 'Counters keyboard message choose operation type',
    });
  };

  const renderExtraHeaderContent = () => {
    return (
      <HeaderContentWrapper key={countersUpdateCount}>
        <ValueDisplay>
          <ValueDisplayDescription>
            {intl.formatMessage({
              defaultMessage: 'Current value',
              description: 'Counters keyboard extra header content current value',
            })}
          </ValueDisplayDescription>
          {selectedCounter?.value !== undefined ? (
            <ValueDisplayText>{selectedCounter.value}</ValueDisplayText>
          ) : (
            <ValueDisplayText>-</ValueDisplayText>
          )}
        </ValueDisplay>
        <ValueDisplay>
          <ValueDisplayDescription>
            {intl.formatMessage({
              defaultMessage: 'New value',
              description: 'Counters keyboard extra header content new value',
            })}
          </ValueDisplayDescription>
          {expectedValue !== undefined && inputValue !== '' && selectedCounter ? (
            <ValueDisplayText style={{ color: '#3897FE' }}>{expectedValue}</ValueDisplayText>
          ) : (
            <ValueDisplayText>-</ValueDisplayText>
          )}
        </ValueDisplay>
      </HeaderContentWrapper>
    );
  };

  // eslint-disable-next-line consistent-return
  const renderInputAdornment = (operation?: CounterOperation) => {
    if (operation === CounterOperation.ADD) return <span style={{ color: '#3897FE', fontSize: '22px' }}>+</span>;
    if (operation === CounterOperation.SUBTRACT) return <span style={{ color: '#3897FE', fontSize: '22px' }}>-</span>;
  };
  return (
    <Container
      style={{ height: 'calc(100vh - 177px)' }}
      title={intl.formatMessage({ defaultMessage: 'Value change', description: 'Counters keyboard container title' })}
      extraHeaderContent={renderExtraHeaderContent()}
    >
      <Row data-testid="counters-keyboard">
        <Message>{renderMessage(operationType)}</Message>
        <Col col={12} style={{ padding: '0 30px 0 30px', margin: '0 auto' }}>
          <GridContainer>
            <Input
              startAdornment={renderInputAdornment(operationType)}
              readOnly
              value={inputValue}
              containerStyles={{ marginBottom: '2vh', alignItems: 'center' }}
              style={{
                color: '#3897FE',
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              gridArea="input"
              data-testid="counters-keyboard-input"
            />
            <KeyboardButtons
              operationType={operationType}
              disabled={keyboardButtonsDisabled}
              setInputValue={setInputValue}
              setOperationType={setOperationType}
            />
            <Button
              style={{ gridArea: 'confirm', marginTop: '0' }}
              data-testid="counters-keyboard-submit-button"
              type="button"
              primary
              onClick={() =>
                selectedCounter?.hasNote ? setCounterModalCallback(() => onConfirmationAction) : onConfirmationAction()
              }
              disabled={keyboardButtonsDisabled || !inputValue.length}
            >
              {intl.formatMessage({ defaultMessage: 'Confirm', description: 'Counters keyboard confirm button' })}
            </Button>
            <Button
              style={{ gridArea: 'reject', marginTop: '0' }}
              data-testid="counters-keyboard-reject-button"
              onClick={onRejectionAction}
              type="button"
              disabled={keyboardButtonsDisabled}
            >
              {intl.formatMessage({ defaultMessage: 'Reject', description: 'Counters keyboard reject button' })}
            </Button>
          </GridContainer>
        </Col>
      </Row>
    </Container>
  );
};
export default CountersKeyboard;
