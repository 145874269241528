import { Instance, types } from 'mobx-state-tree';

import { ShiftModelBase } from './ShiftModel.base';
import { DatePrimitive } from './types';

/* The TypeScript type of an instance of ShiftModel */
export interface ShiftModelType extends Instance<typeof ShiftModel.Type> {}

/* A graphql query fragment builders for ShiftModel */
export { selectFromShift, shiftModelPrimitives, ShiftModelSelector } from './ShiftModel.base';

/**
 * ShiftModel
 */
export const ShiftModel = ShiftModelBase.props({
  startedAt: types.union(types.undefined, DatePrimitive),
  finishedAt: types.union(types.undefined, types.null, DatePrimitive),
});
