/* eslint-disable promise/catch-or-return, promise/always-return */
import { Query } from 'mst-gql';

import { getMutationErrorText, handleQueryError, isValidationError } from '@/helpers/errors';
import { ProductModelType, RootStoreType } from '@/models';
import { productsQS } from '@/queries';

interface ICreateProductProps {
  rootStore: RootStoreType;
  product: ProductModelType;
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string, isValidationError?: boolean) => void;
}

const create = ({
  rootStore,
  product,
  optimisticUpdate,
  revertUpdate,
  onSuccess,
  onError,
}: ICreateProductProps): Query => {
  const { id, name, number } = product;

  const query = rootStore.mutateCreateProduct(
    {
      id,
      name: name as string | undefined,
      number: number as string,
    },
    productsQS.CREATE_PRODUCT_PAYLOAD_ALL,
    () => {
      rootStore.addProduct(product);

      if (optimisticUpdate) optimisticUpdate();
    },
  );
  const revert = () => {
    if (revertUpdate) revertUpdate();

    rootStore.removeProduct(product);
  };

  query.then(
    ({ createProduct }) => {
      const { errors } = createProduct;

      if (errors?.length) {
        if (onError) onError(getMutationErrorText(errors[0]), isValidationError(errors[0]));
        revert();
        return;
      }
      if (onSuccess) onSuccess();
    },
    (error) => {
      handleQueryError(error, onError);
      revert();
    },
  );
  return query;
};

export default { create };
