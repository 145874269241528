import 'moment-duration-format';

import moment from 'moment';

import { MetricModelType, MetricUnitEnum, MetricValueModelType } from '@/models';
import { ListItem } from '@/types';

const getKpiLabel = (metrics: MetricModelType[], kpiId: string) => {
  return metrics.find((metric) => metric.id === kpiId)?.name || kpiId;
};

const findValue = (metricValues: MetricValueModelType[], workplaceId: string, kpiId: string) => {
  return metricValues.find((metricValue) => metricValue.workplaceId === workplaceId && metricValue.metricId === kpiId);
};

const findProgressValue = (metricValues: MetricValueModelType[], workplaceId: string, kpiId: string) => {
  const progressItem = metricValues.find(
    (metricValue) => metricValue.workplaceId === workplaceId && metricValue.metricId === `${kpiId}_progress`,
  );

  if (progressItem) {
    return progressItem.textValue || undefined;
  }
  return undefined;
};

const buildKpiList = (
  workplaceId: string,
  metrics: MetricModelType[],
  metricValues: MetricValueModelType[],
  displayedKpiItems: readonly string[],
): (ListItem & { progress?: string; unit?: string; symbol?: string })[] => {
  const formatFloat = (value: number) => value.toFixed(1).replace('.', ',');
  const formatValue = (metricValue: MetricValueModelType) => {
    const value = metricValue.textValue;

    if (value) {
      switch (metricValue.unit) {
        case MetricUnitEnum.DURATION:
          return moment.duration(value, 'seconds').format('m[m] s[s]');
        case MetricUnitEnum.ROUND:
          return formatFloat(parseFloat(value));
        case MetricUnitEnum.PERCENT:
          return `${formatFloat(parseFloat(value) * 100)}%`;
        default:
          return value.toString();
      }
    }
    return '';
  };

  return displayedKpiItems.map((kpiId) => {
    if (kpiId.toUpperCase() === 'REASON') {
      return {
        label: 'Status',
        symbol: 'reason',
      };
    }
    const label = getKpiLabel(metrics, kpiId);
    const isValid = metrics.find((metric) => metric.id === kpiId);

    if (kpiId.includes('_progress')) {
      const progressValue = findProgressValue(metricValues, workplaceId, kpiId.replace('_progress', ''));

      if (progressValue) {
        return {
          label,
          value: '',
          symbol: kpiId,
          progress: progressValue,
          invalid: !isValid,
        };
      }
    }
    const metricValue = findValue(metricValues, workplaceId, kpiId);
    const progressValue = findProgressValue(metricValues, workplaceId, kpiId);

    return {
      label,
      value: metricValue ? formatValue(metricValue) : '-',
      symbol: kpiId,
      progress: progressValue,
      invalid: !isValid,
    };
  });
};

export default buildKpiList;
