import React from 'react';

import { RootStoreType, StoreContext } from '@/models';

const useStore = (): RootStoreType => {
  const store = React.useContext(StoreContext);

  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};

export default useStore;
