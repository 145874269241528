/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ProductionMode {
  MANUAL="MANUAL",
PLANNED="PLANNED"
}

/**
* ProductionMode
*/
export const ProductionModeEnumType = types.enumeration("ProductionMode", [
        "MANUAL",
  "PLANNED",
      ])
