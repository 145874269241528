import { Instance } from 'mobx-state-tree';
import { withTypedRefs } from 'mst-gql';

import { ScreenSaverConfigModelType } from './ScreenSaverConfigModel';
import { WorkplaceConfigModelBase } from './WorkplaceConfigModel.base';
import { OrderConfigModelType } from '.';

/* The TypeScript type of an instance of WorkplaceConfigModel */
export interface WorkplaceConfigModelType extends Instance<typeof WorkplaceConfigModel.Type> {}

type Refs = {
  orderConfig: OrderConfigModelType;
  screenSaverConfig: ScreenSaverConfigModelType;
};

/* A graphql query fragment builders for WorkplaceConfigModel */
export {
  selectFromWorkplaceConfig,
  workplaceConfigModelPrimitives,
  WorkplaceConfigModelSelector,
} from './WorkplaceConfigModel.base';

/**
 * WorkplaceConfigModel
 */
export const WorkplaceConfigModel = withTypedRefs<Refs>()(WorkplaceConfigModelBase);
