import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';
import { applySnapshot } from 'mobx-state-tree';

import { Container } from '@/components/core';
import { getWorkplaceOrdersExecutions } from '@/helpers/orders-executions';
import { getWorkplaceStatusChanges } from '@/helpers/status-changes';
import { getWorkplaceUsersPresences } from '@/helpers/users-presences';
import { useStore } from '@/hooks';
import { OrderExecutionModelType, StatusChangeModelType, UserPresenceModelType } from '@/models';
import { useCompanyData, useWorkplaceData } from '@/providers';
import { HistoryDataType } from '@/types';

import { prepareHistoryItems, sortHistoryStatuses } from './helpers';
import HistoryList from './HistoryList';
import HistoryTimeline from './HistoryTimeline';

export interface HistoryProps {
  workplaceID: string;
}

export const History: React.FC<HistoryProps> = observer(({ workplaceID }) => {
  const { historyItems } = useStore();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const { data: workplaceData, loading: workplaceDataLoading, refetch } = useWorkplaceData();
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const [historyData, setHistoryData] = useState<HistoryDataType[]>([]);
  const [historyTimelineData, setHistoryTimelineData] = useState<StatusChangeModelType[]>([]);
  const [workplaceOrdersExecutions, setWorkplaceOrdersExecutions] = useState<OrderExecutionModelType[]>();
  const [workplaceUsersPresences, setWorkplaceUsersPresences] = useState<UserPresenceModelType[]>();
  const [workplaceStatusChanges, setWorkplaceStatusChanges] = useState<StatusChangeModelType[]>();

  const intl = useIntl();

  useEffect(() => {
    if (refetch) {
      refetch(['ordersExecutions', 'statusChanges', 'usersPresences']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingData(companyDataLoading || workplaceDataLoading);
  }, [companyDataLoading, workplaceDataLoading]);

  useEffect(() => {
    // get initial data
    // any further changes should by made by optimistic or revert updates on historyItems
    setWorkplaceOrdersExecutions(getWorkplaceOrdersExecutions(workplaceID, workplaceData.ordersExecutions));
    setWorkplaceStatusChanges(getWorkplaceStatusChanges(workplaceID, workplaceData.statusChanges));
    setWorkplaceUsersPresences(getWorkplaceUsersPresences(workplaceID, workplaceData.usersPresences));
  }, [workplaceID, workplaceData.ordersExecutions, workplaceData.statusChanges, workplaceData.usersPresences]);

  useEffect(() => {
    if (!loadingData) {
      setHistoryData([
        ...(workplaceOrdersExecutions || []),
        ...(workplaceStatusChanges || []),
        ...(workplaceUsersPresences || []),
      ]);
    }
  }, [workplaceOrdersExecutions, workplaceStatusChanges, workplaceUsersPresences, loadingData]);

  useEffect(() => {
    if (!loadingData && historyData) {
      const items = prepareHistoryItems(historyData, companyData?.shifts || []);

      if (items) {
        applySnapshot(historyItems, items);
      }
    }
  }, [loadingData, companyData?.shifts, historyData, historyItems, workplaceStatusChanges]);

  useEffect(() => {
    if (!loadingData && workplaceStatusChanges) {
      setHistoryTimelineData(sortHistoryStatuses(workplaceStatusChanges));
    }
  }, [loadingData, workplaceStatusChanges]);

  const renderTimeline = () => <HistoryTimeline statuses={historyTimelineData} loading={loadingData} />;

  return (
    <Container
      style={{ height: 'calc(100vh - 177px)', marginTop: '15px' }}
      title={intl.formatMessage({
        defaultMessage: 'History',
        description: 'History container title',
      })}
      extraHeaderContent={renderTimeline()}
      headerStyles={{ paddingBottom: 20 }}
      data-testid="history-container"
    >
      <HistoryList loading={loadingData} />
    </Container>
  );
});
