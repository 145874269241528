import styled from 'styled-components';

import { theme } from '@/theme';

export const AccordionItemButtonStyles: React.CSSProperties = {
  fontWeight: 'normal',
  fontSize: '17px',
  lineHeight: '20px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.colors.white,
  background: 'transparent',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: `2px solid ${theme.colors.mineSchaftDark}`,
};

export const CountersValueCount = styled.div`
  border: 2px solid rgba(118, 118, 128, 0.24);
  box-sizing: border-box;
  border-radius: 6px;
  min-width: 105px;
  padding: 20px 30px;
  margin-right: 260px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: rgba(235, 235, 245, 0.6);
`;
