import greenStatusColorIndicator from '@/images/greenStatusColorIndicator.svg';
import redStatusColorIndicator from '@/images/redStatusColorIndicator.svg';
import yellowStatusColorIndicator from '@/images/yellowStatusColorIndicator.svg';
import { AndonLightColor } from '@/models';

export const mapStatusColorToBlackIndicator = (color: AndonLightColor): string => {
  if (color === 'red') return redStatusColorIndicator;
  if (color === 'yellow') return yellowStatusColorIndicator;
  return greenStatusColorIndicator;
};
