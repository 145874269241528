import styled, { css } from 'styled-components';

interface CenteringContainerProps {
  height?: string;
}

export const CenteringContainer = styled.div<CenteringContainerProps>`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`;
