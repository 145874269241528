import React from 'react';

import backdropPattern from '@/images/backdropPattern.svg';
import backdropPatternSmall from '@/images/backdropPatternSmall.svg';

interface IOverlay {
  children?: React.ReactNode;
  label?: string;
  small?: boolean;
}

export const Overlay: React.FC<IOverlay> = ({ children, small }) => {
  return (
    <div
      data-testid="overlay-component"
      style={{
        borderRadius: '4px',
        position: 'absolute',
        backgroundImage: small ? `url(${backdropPatternSmall})` : `url(${backdropPattern})`,
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',
        zIndex: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        opacity: 1,
        transition: 'opacity .15s ease-in-out',
      }}
    >
      <div style={{ zIndex: 301, height: '100%', width: '100%' }}>{children}</div>
    </div>
  );
};
