/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CounterModel, CounterModelType } from "./CounterModel"
import { CounterModelSelector } from "./CounterModel.base"
import { ItemModel, ItemModelType } from "./ItemModel"
import { ItemModelSelector } from "./ItemModel.base"
import { MetricValueModel, MetricValueModelType } from "./MetricValueModel"
import { MetricValueModelSelector } from "./MetricValueModel.base"
import { NotificationModel, NotificationModelType } from "./NotificationModel"
import { NotificationModelSelector } from "./NotificationModel.base"
import { OrderExecutionModel, OrderExecutionModelType } from "./OrderExecutionModel"
import { OrderExecutionModelSelector } from "./OrderExecutionModel.base"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { ShiftModel, ShiftModelType } from "./ShiftModel"
import { ShiftModelSelector } from "./ShiftModel.base"
import { StatusChangeModel, StatusChangeModelType } from "./StatusChangeModel"
import { StatusChangeModelSelector } from "./StatusChangeModel.base"
import { UserPresenceModel, UserPresenceModelType } from "./UserPresenceModel"
import { UserPresenceModelSelector } from "./UserPresenceModel.base"
import { WorkplaceEventEnumType } from "./WorkplaceEventEnum"
import { WorkplaceEventSubjectUnionModelSelector } from "./WorkplaceEventSubjectUnionModelSelector"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  Counter: CounterModelType;
  Item: ItemModelType;
  MetricValue: MetricValueModelType;
  Notification: NotificationModelType;
  Order: OrderModelType;
  OrderExecution: OrderExecutionModelType;
  Shift: ShiftModelType;
  StatusChange: StatusChangeModelType;
  UserPresence: UserPresenceModelType;
}

/**
 * WorkplaceEventSubscriptionPayloadBase
 * auto generated base class for the model WorkplaceEventSubscriptionPayloadModel.
 *
 * Autogenerated return type of WorkplaceEventSubscription
 */
export const WorkplaceEventSubscriptionPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('WorkplaceEventSubscriptionPayload')
  .props({
    __typename: types.optional(types.literal("WorkplaceEventSubscriptionPayload"), "WorkplaceEventSubscriptionPayload"),
    event: types.union(types.undefined, WorkplaceEventEnumType),
    subject: types.union(types.undefined, types.null, types.union(MSTGQLRef(types.late((): any => CounterModel)), MSTGQLRef(types.late((): any => ItemModel)), MSTGQLRef(types.late((): any => MetricValueModel)), MSTGQLRef(types.late((): any => NotificationModel)), MSTGQLRef(types.late((): any => OrderModel)), MSTGQLRef(types.late((): any => OrderExecutionModel)), MSTGQLRef(types.late((): any => ShiftModel)), MSTGQLRef(types.late((): any => StatusChangeModel)), MSTGQLRef(types.late((): any => UserPresenceModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class WorkplaceEventSubscriptionPayloadModelSelector extends QueryBuilder {
  get event() { return this.__attr(`event`) }
  subject(builder: string | WorkplaceEventSubjectUnionModelSelector | ((selector: WorkplaceEventSubjectUnionModelSelector) => WorkplaceEventSubjectUnionModelSelector) | undefined) { return this.__child(`subject`, WorkplaceEventSubjectUnionModelSelector, builder) }
}
export function selectFromWorkplaceEventSubscriptionPayload() {
  return new WorkplaceEventSubscriptionPayloadModelSelector()
}

export const workplaceEventSubscriptionPayloadModelPrimitives = selectFromWorkplaceEventSubscriptionPayload().event
