import styled, { css } from 'styled-components';

interface ButtonProps {
  selected?: boolean;
}
export const SwitchContainer = styled.div`
  width: 100%;
  background-color: #4f4f4f;
  margin-top: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 3px;
`;

export const Button = styled.button<ButtonProps>`
  border: none;
  font-size: 16px;
  line-height: 20px;
  border-radius: 10px;
  flex-basis: 50%;
  padding: 12px;
  color: white;
  transition: background-color 0.2s;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
  ${({ selected }) =>
    selected &&
    css`
      pointer-events: none;
      background-color: #3897fe;
    `}
`;
