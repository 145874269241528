import { Col } from 'styled-bootstrap-grid';

import { NotificationModelType } from '@/models';

import { ReactComponent as WarningIcon } from '../../images/warningIcon.svg';

import {
  NotificationCloseButton,
  NotificationContent,
  NotificationDetails,
  NotificationMessage,
  NotificationOverlay,
} from './styled';

interface NotificationProps {
  notification: NotificationModelType;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ notification, onClose }) => {
  return (
    <NotificationOverlay>
      <NotificationContent alignItems="center">
        <Col auto>
          <WarningIcon />
        </Col>
        <Col auto style={{ flexGrow: 1 }}>
          <NotificationMessage>{notification.message}</NotificationMessage>
          {notification.details && <NotificationDetails>{notification.details}</NotificationDetails>}
        </Col>
        <Col auto>
          <NotificationCloseButton onClick={onClose} />
        </Col>
      </NotificationContent>
    </NotificationOverlay>
  );
};

export default Notification;
