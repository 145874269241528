/* eslint-disable promise/catch-or-return, promise/always-return */
import { Session, UserData } from 'andoncloud-sdk';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import moment, { Moment } from 'moment';
import { Query } from 'mst-gql';
import { v4 as uuidv4 } from 'uuid';

import { getMutationErrorText, handleQueryError } from '@/helpers/errors';
import { RootStoreType, UserModel, UserPresenceModel, UserPresenceModelType } from '@/models';
import HistoryItemModel, { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import { usersPresencesQS } from '@/queries';

interface ICreateUserPresenceProps {
  rootStore: RootStoreType;
  workplaceId: string;
  authorData: UserData;
  session: Session;
  optimisticUpdate: (userPresence: UserPresenceModelType) => void;
  revertUpdate: (userPresence: UserPresenceModelType) => void;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

interface IRemoveUserPresenceProps {
  rootStore: RootStoreType;
  workplaceId: string;
  usersPresences: UserPresenceModelType[];
  optimisticUpdate: () => void;
  revertUpdate: (usersPresences: UserPresenceModelType[]) => void;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

const create = ({
  rootStore,
  workplaceId,
  authorData,
  session,
  optimisticUpdate,
  revertUpdate,
  onSuccess,
  onError,
}: ICreateUserPresenceProps): Query => {
  const { accessToken, user: userData } = session;
  const authorId = authorData.id.toString();
  const userId = userData.id.toString();

  const author =
    rootStore.users.get(authorId) ||
    UserModel.create({
      id: authorId,
      login: authorData.email,
      name: authorData.name,
    });

  const user =
    rootStore.users.get(userId) ||
    UserModel.create({
      id: userId,
      login: userData.email,
      name: userData.name,
    });

  const userPresence = UserPresenceModel.create({
    id: uuidv4(),
    workplaceId,
    startedAt: moment().toISOString(),
  });

  const historyItem = HistoryItemModel.create({
    date: userPresence.startedAt as Moment,
    instance: castToReferenceSnapshot(userPresence),
    type: HistoryItemType.CHECK_IN,
  });

  const query = rootStore.mutateCreateUserPresence(
    { id: userPresence.id, workplaceId, userToken: accessToken },
    usersPresencesQS.CREATE_USER_PRESENCE_PAYLOAD_ALL,
    () => {
      rootStore.addUser(author);
      rootStore.addUser(user);
      rootStore.addUserPresence(userPresence);

      userPresence.update({ author, user });

      rootStore.addHistoryItem(historyItem);

      optimisticUpdate(userPresence);
    },
  );

  const revert = () => {
    revertUpdate(userPresence);

    rootStore.removeHistoryItem(historyItem);
    rootStore.removeUserPresence(userPresence);
  };

  query.then(
    ({ createUserPresence }) => {
      const { errors } = createUserPresence;

      if (errors?.length) {
        if (onError) onError(getMutationErrorText(errors[0]));
        revert();
        return;
      }
      if (onSuccess) onSuccess();
    },
    (error) => {
      handleQueryError(error, onError);
      revert();
    },
  );
  return query;
};

const remove = ({
  rootStore,
  workplaceId,
  usersPresences,
  optimisticUpdate,
  revertUpdate,
  onSuccess,
  onError,
}: IRemoveUserPresenceProps): Query => {
  const createdHistoryItems: HistoryItemModelType[] = [];
  const removedUsersPresences: UserPresenceModelType[] = [];

  const query = rootStore.mutateRemoveWorkplaceUsersPresences(
    { workplaceId },
    usersPresencesQS.REMOVE_WORKPLACE_USER_PRESENCES_ALL,
    () => {
      usersPresences.forEach((userPresence) => {
        userPresence.finish();

        const historyItem = HistoryItemModel.create({
          date: userPresence.finishedAt as Moment,
          instance: castToReferenceSnapshot(userPresence),
          type: HistoryItemType.CHECK_OUT,
        });
        rootStore.addHistoryItem(historyItem);

        removedUsersPresences.push(userPresence);
        createdHistoryItems.push(historyItem);
      });
      optimisticUpdate();
    },
  );

  const revert = () => {
    createdHistoryItems.forEach((historyItem) => {
      rootStore.removeHistoryItem(historyItem);
    });
    removedUsersPresences.forEach((userPresence) => {
      userPresence.update({ finishedAt: null });
    });
    revertUpdate(removedUsersPresences);
  };

  query.then(
    ({ removeWorkplaceUsersPresences }) => {
      const { errors } = removeWorkplaceUsersPresences;

      if (errors?.length) {
        if (onError) onError(getMutationErrorText(errors[0]));
        revert();
        return;
      }
      if (onSuccess) onSuccess();
    },
    (error) => {
      handleQueryError(error, onError);
      revert();
    },
  );
  return query;
};

export default { create, remove };
