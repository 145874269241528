import { itemModelPrimitives } from '@/models/ItemModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { orderExecutionModelPrimitives } from '@/models/OrderExecutionModel.base';
import { orderModelPrimitives } from '@/models/OrderModel.base';
import { pauseOrderExecutionPayloadModelPrimitives } from '@/models/PauseOrderExecutionPayloadModel.base';
import { productModelPrimitives } from '@/models/ProductModel.base';
import { startOrderExecutionPayloadModelPrimitives } from '@/models/StartOrderExecutionPayloadModel.base';
import { stopOrderExecutionPayloadModelPrimitives } from '@/models/StopOrderExecutionPayloadModel.base';
import { updateOrderExecutionPayloadModelPrimitives } from '@/models/UpdateOrderExecutionPayloadModel.base';

export default {
  ALL: orderExecutionModelPrimitives
    .item(itemModelPrimitives.order(orderModelPrimitives).product(productModelPrimitives))
    .order(orderModelPrimitives)
    .product(productModelPrimitives)
    .toString(),
  START_ORDER_EXECUTION_PAYLOAD_ALL: startOrderExecutionPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .orderExecution(orderExecutionModelPrimitives)
    .toString(),
  STOP_ORDER_EXECUTION_PAYLOAD_ALL: stopOrderExecutionPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .orderExecution(orderExecutionModelPrimitives)
    .toString(),
  PAUSE_ORDER_EXECUTION_PAYLOAD_ALL: pauseOrderExecutionPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .orderExecution(orderExecutionModelPrimitives)
    .toString(),
  UPDATE_ORDER_EXECUTION_PAYLOAD_ALL: updateOrderExecutionPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .orderExecution(orderExecutionModelPrimitives)
    .toString(),
};
