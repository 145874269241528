import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import AutocompleteBase from 'react-autocomplete';

import { observer } from 'mobx-react-lite';

import { IconButton, Input } from '@/components/core';
import enterIcon from '@/images/enter.svg';
import plusIcon from '@/images/plusImage.svg';
import searchIcon from '@/images/searchIcon.svg';
import xGrayIcon from '@/images/xGrayIcon.svg';

import { Loader } from '../Loader';

import { EmptyContainer, Item, Menu } from './styled';

interface AutocompleteProps extends Omit<AutocompleteBase.Props, 'value' | 'getItemValue' | 'renderItem'> {
  placeholder: string;
  createButtonText?: string;
  itemIdKey?: string;
  itemLabelKey: string;
  itemSearchKeys?: string[];
  ignoreCase?: boolean;
  prefix?: string | null;
  initialValue?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCreate?: () => void;
  onClear?: () => void;
  error?: boolean;
  focus?: boolean;
  hideSearchIcon?: boolean;
  disableClear?: boolean;
  emptyMessage?: React.ReactNode;
  disabled?: boolean;
  pending?: boolean;
  'data-testid'?: string;
}

export const Autocomplete: React.FC<AutocompleteProps> = observer((props) => {
  const {
    placeholder,
    createButtonText,
    itemIdKey = 'id',
    itemLabelKey,
    itemSearchKeys,
    ignoreCase = true,
    items,
    prefix,
    initialValue = '',
    onKeyDown,
    onChange,
    onSelect,
    onFocus,
    onBlur,
    onCreate,
    onClear,
    error,
    focus,
    hideSearchIcon,
    disableClear,
    emptyMessage,
    disabled,
    pending,
    'data-testid': dataTestID,
  } = props;

  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setMenuOpened(true);
  }, []);

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);

      if (initialValue !== prefix) {
        setShowClearButton(true);
      }
    }
  }, [prefix, initialValue]);

  const clearInput = () => {
    setInputValue(prefix || '');

    if (onClear && onClear instanceof Function) {
      onClear();
    }
    setShowClearButton(false);

    inputRef.current?.focus();
  };

  const getItemValue = (item: Record<string, unknown>) => {
    const itemLabelKeys = itemLabelKey.split('|');

    return item[itemLabelKeys.find((key) => (item[key] as string)?.length > 0) || itemLabelKeys[0]] as string;
  };

  const isSelected = (item: Record<string, unknown>, value?: string) => {
    return (itemSearchKeys || itemLabelKey.split('|')).some((key) => {
      const itemValue = item[key] as string;

      if (ignoreCase) {
        return itemValue.toLowerCase() === (value || inputValue).toLowerCase();
      }
      return itemValue === value || itemValue === inputValue;
    });
  };

  const onSelectCallback = (value: string, item: Record<string, unknown>) => {
    if (onSelect && onSelect instanceof Function) {
      onSelect(value, item);
    }
    setShowClearButton(true);
  };

  const handleOnCreate = () => {
    if (onCreate && onCreate instanceof Function) {
      onCreate();
    }
  };

  const shouldItemRender = (item: Record<string, unknown>, value: string) => {
    return (itemSearchKeys || itemLabelKey.split('|')).some((key) => {
      const itemValue = (item[key] as string) || '';

      if (ignoreCase) {
        return itemValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
      }
      return itemValue.indexOf(value) > -1;
    });
  };

  const handleOnBlur = () => {
    items.forEach((item) => {
      if (isSelected(item, inputValue)) {
        const renderedItemsCount = items.filter((item) => shouldItemRender(item, inputValue)).length;

        if (renderedItemsCount === 1) {
          onSelectCallback(inputValue, item);
        }
      }
    });
    if (onBlur && onBlur instanceof Function) {
      onBlur();
    }
  };

  const renderLoader = () => <Loader width="40px" />;

  const renderCreateButton = () => {
    const inputHasTypedValue = inputValue !== prefix && inputValue !== '';

    return (
      createButtonText && (
        <IconButton
          text={inputHasTypedValue ? 'Enter' : createButtonText}
          src={inputHasTypedValue ? enterIcon : plusIcon}
          onClick={() => handleOnCreate()}
          data-testid="autocomplete-create-button"
        />
      )
    );
  };

  const renderClearButton = () => (
    <IconButton src={xGrayIcon} onClick={clearInput} disabled={disableClear} data-testid="autocomplete-clear-button" />
  );

  return (
    <AutocompleteBase
      {...props}
      value={inputValue}
      getItemValue={(item: Record<string, unknown>) => getItemValue(item)}
      onChange={(e, value) => {
        setInputValue(value);

        if (onChange && onChange instanceof Function) {
          onChange(e, value);
        }
      }}
      onSelect={(value, item) => {
        setInputValue(value);

        onSelectCallback(value, item);
      }}
      wrapperStyle={{ display: 'block' }}
      renderMenu={(items) =>
        items.length ? (
          <Menu data-testid={`${dataTestID || 'autocomplete'}-menu`}>{items.slice(0, 10)}</Menu>
        ) : (
          <EmptyContainer>{emptyMessage}</EmptyContainer>
        )
      }
      shouldItemRender={shouldItemRender}
      renderItem={(item: Record<string, unknown>, isHighlighted: boolean) => (
        <Item key={item[itemIdKey] as string} isHighlighted={isHighlighted} isSelected={isSelected(item)}>
          {getItemValue(item)}
        </Item>
      )}
      inputProps={{
        placeholder,
        onFocus,
        onBlur: handleOnBlur,
        disabled: disabled || pending,
        readOnly: showClearButton,
      }}
      renderInput={(inputProps) => {
        // initialize inner input reference with same element
        (inputProps.ref as React.RefCallback<HTMLInputElement>)(inputRef.current);

        return (
          <Input
            {...inputProps}
            ref={inputRef}
            error={error}
            onKeyDown={onKeyDown || inputProps.onKeyDown}
            startAdornment={!hideSearchIcon && <img src={searchIcon} alt="🔍" />}
            endAdornment={pending ? renderLoader() : showClearButton ? renderClearButton() : renderCreateButton()}
            endAdornmentSize="large"
            data-testid={dataTestID}
          />
        );
      }}
      open={menuOpened}
    />
  );
});
