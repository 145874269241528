import { FormattedMessage } from 'react-intl';

import { observer } from 'mobx-react-lite';

import ExtendedListItemConsumer from '@/components/ExtendedListItemConsumer';
import { useLivetimeKpiList } from '@/hooks/useLivetimeKpiList';
import { ExtendedListItem } from '@/types';

import { StatusScreenElement, StatusScreenElementHeader, Title } from '../styled';

import { ItemsContainer } from './styled';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LiveTimeProps {}

const LiveTime: React.FC<LiveTimeProps> = observer(() => {
  const { loading, items } = useLivetimeKpiList();

  return (
    <StatusScreenElement data-testid="LIVETIME-container" gridArea="LIVETIME">
      <StatusScreenElementHeader>
        <Title>
          <FormattedMessage defaultMessage="The most important KPIs for shift" description="Livetime section title" />
        </Title>
      </StatusScreenElementHeader>
      <ItemsContainer single={items.length === 1} data-testid="livetime-items-container">
        {items.map((item: ExtendedListItem) => {
          return <ExtendedListItemConsumer key={item.label} item={item} small loading={loading} />;
        })}
      </ItemsContainer>
    </StatusScreenElement>
  );
});

export default LiveTime;
