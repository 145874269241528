/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * FieldConfigBase
 * auto generated base class for the model FieldConfigModel.
 */
export const FieldConfigModelBase = ModelBase
  .named('FieldConfig')
  .props({
    __typename: types.optional(types.literal("FieldConfig"), "FieldConfig"),
    name: types.union(types.undefined, types.string),
    prefix: types.union(types.undefined, types.null, types.string),
    regexp: types.union(types.undefined, types.null, types.string),
    unit: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FieldConfigModelSelector extends QueryBuilder {
  get name() { return this.__attr(`name`) }
  get prefix() { return this.__attr(`prefix`) }
  get regexp() { return this.__attr(`regexp`) }
  get unit() { return this.__attr(`unit`) }
}
export function selectFromFieldConfig() {
  return new FieldConfigModelSelector()
}

export const fieldConfigModelPrimitives = selectFromFieldConfig().name.prefix.regexp.unit
