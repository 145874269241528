import styled from 'styled-components';

import { Button } from '@/components/core/Button/styled';

export const ButtonsStack = styled.div`
  display: flex;
  flex-direction: 'row';
  flex-grow: 0;
  justify-content: space-evenly;
`;

export const StatusColorButton = styled(Button)`
  width: auto;
  min-width: 42px;
  margin-top: 0;
`;
