import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Col } from 'styled-bootstrap-grid';

import { Button } from '@/components/core/Button/styled';
import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { AndonLightColor, WorkplaceModelType } from '@/models';

import { ButtonContent, StyledItem } from './styled';

interface ListItemProps {
  item: WorkplaceModelType;
}

export const ListItem: React.FC<ListItemProps> = ({ item }): ReactElement => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <StyledItem
      statusColor={
        item.currentStatusChange &&
        mapStatusColorToColorString(item.currentStatusChange.reason.statusColor as AndonLightColor)
      }
      data-testid={`workplace-select-list-item-${item.id}`}
      onClick={() => {
        navigate(`/workplaces/${item.id}`);
      }}
    >
      <Col col={8}>{item.name}</Col>
      <Col col={4}>
        <Button
          style={{ margin: 0 }}
          onClick={() => {
            navigate(`/workplaces/${item.id}`);
          }}
          data-testid={`workplace-select-list-item-button-${item.id}`}
        >
          <ButtonContent>
            {intl.formatMessage({ defaultMessage: 'Enter', description: 'workplace select list item button text' })}
          </ButtonContent>
        </Button>
      </Col>
    </StyledItem>
  );
};
