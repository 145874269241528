(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mobx-state-tree"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define(["mobx-state-tree", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["mstform"] = factory(require("mobx-state-tree"), require("mobx"));
	else
		root["mstform"] = factory(root["mobx-state-tree"], root["mobx"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__613__, __WEBPACK_EXTERNAL_MODULE__259__) => {
return 