import { createContext, useContext } from 'react';
import { IntlProvider } from 'react-intl';

import { getLocale, setLocale as setAndonCloudLocale } from 'andoncloud-sdk';
import Cookies from 'js-cookie';

import localeEn from '@/lang/compiled/en.json';
import localePl from '@/lang/compiled/pl.json';

interface Locale {
  currentLocale: string;
  toggleLocale: () => void;
}

interface LocaleProviderProps {
  children: React.ReactNode;
}

const LocaleContext = createContext<Locale>({ currentLocale: 'pl', toggleLocale: () => {} });

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
  const currentLocale = Cookies.get('locale') ?? getLocale();

  setAndonCloudLocale(currentLocale);

  const toggleLocale = () => {
    if (currentLocale === 'pl') {
      Cookies.set('locale', 'en');
    } else {
      Cookies.set('locale', 'pl');
    }
    window.location.reload();
  };

  const getMessages = (locale: string): Record<string, string> => {
    return { en: localeEn, pl: localePl }[locale] as Record<string, string>;
  };

  return (
    <LocaleContext.Provider value={{ currentLocale, toggleLocale }}>
      <IntlProvider messages={getMessages(currentLocale)} locale={currentLocale} defaultLocale="en">
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = (): Locale => {
  return useContext<Locale>(LocaleContext);
};
