import { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';

import { getRowKey } from '../helpers';
import HistoryGroup from '../HistoryGroup';
import HistoryRow from '../HistoryRow';

const getHistoryRow = (item: HistoryItemModelType) => {
  return <HistoryRow key={getRowKey(item)} item={{ ...item }} />;
};

const getHistoryGroup = (items: HistoryItemModelType[]) => {
  // clone referenced items to prevent changes
  const groupedItems = [...items];

  const lastItem = groupedItems[groupedItems.length - 1];
  const lastItemKey = getRowKey(lastItem);

  return <HistoryGroup key={lastItemKey} items={groupedItems} />;
};

const addHistoryItem = (
  groupedChecksIn: HistoryItemModelType[],
  groupedChecksOut: HistoryItemModelType[],
  item: HistoryItemModelType,
  isLastItem: boolean,
) => {
  const hasGroupedChecksIn = !!groupedChecksIn.length;
  const hasGroupedChecksOut = !!groupedChecksOut.length;

  const clearItems = (grouped: HistoryItemModelType[]) => {
    grouped.splice(0, grouped.length);
  };
  const addGroupedItems = (addItem?: boolean) => {
    const listItems: React.ReactElement[] = [];

    if (hasGroupedChecksOut) {
      if (groupedChecksOut.length > 1) {
        listItems.push(getHistoryGroup(groupedChecksOut));
      } else {
        listItems.push(getHistoryRow(groupedChecksOut[0]));
      }
    }
    if (hasGroupedChecksIn) {
      if (groupedChecksIn.length > 1) {
        listItems.push(getHistoryGroup(groupedChecksIn));
      } else {
        listItems.push(getHistoryRow(groupedChecksIn[0]));
      }
    }
    if (hasGroupedChecksOut) clearItems(groupedChecksOut);
    if (hasGroupedChecksIn) clearItems(groupedChecksIn);

    if (addItem) listItems.push(getHistoryRow(item));

    return listItems;
  };

  if (item.type !== HistoryItemType.CHECK_IN && item.type !== HistoryItemType.CHECK_OUT) {
    return addGroupedItems(true);
  }
  if (item.type === HistoryItemType.CHECK_IN) {
    groupedChecksIn.push(item);
  } else if (item.type === HistoryItemType.CHECK_OUT) {
    groupedChecksOut.push(item);
  }
  if (isLastItem) {
    return addGroupedItems();
  }
  return [];
};

export const getHistoryListItems = (items: HistoryItemModelType[]): React.ReactElement[] => {
  const listItems: React.ReactElement[] = [];

  const groupedChecksIn: HistoryItemModelType[] = [];
  const groupedChecksOut: HistoryItemModelType[] = [];

  items.forEach((item, index) => {
    const isLastItem = index === items.length - 1;

    listItems.push(...addHistoryItem(groupedChecksIn, groupedChecksOut, item, isLastItem));
  });
  return listItems;
};
