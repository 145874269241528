import { Instance, types } from 'mobx-state-tree';
import moment from 'moment';

import { StatusChangeModelBase } from './StatusChangeModel.base';
import { DatePrimitive } from './types';

/* The TypeScript type of an instance of StatusChangeModel */
export interface StatusChangeModelType extends Instance<typeof StatusChangeModel.Type> {}

/* A graphql query fragment builders for StatusChangeModel */
export {
  selectFromStatusChange,
  statusChangeModelPrimitives,
  StatusChangeModelSelector,
} from './StatusChangeModel.base';

/**
 * StatusChangeModel
 */
export const StatusChangeModel = StatusChangeModelBase.props({
  startedAt: types.union(types.undefined, DatePrimitive),
  finishedAt: types.union(types.undefined, types.null, DatePrimitive),
}).views((self) => ({
  durationMinutes(currentDate: Date) {
    if (self.finishedAt && self.startedAt) {
      return Math.abs(self.startedAt.diff(self.finishedAt, 'minutes'));
    }
    return Math.abs(moment(currentDate).diff(self.startedAt, 'minutes'));
  },
  durationSeconds(currentDate: Date) {
    if (self.finishedAt && self.startedAt) {
      return Math.abs(self.startedAt.diff(self.finishedAt, 'seconds') % 60);
    }
    return Math.abs(moment(currentDate).diff(self.startedAt, 'seconds') % 60);
  },
}));
