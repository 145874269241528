import React, { useState } from 'react';

import { HistoryItemModelType } from '@/models/HistoryItemModel';

import { getRowKey } from '../helpers';
import HistoryRow from '../HistoryRow';

export interface HistoryGroupProps {
  items: HistoryItemModelType[];
}

const HistoryGroup: React.FC<HistoryGroupProps> = ({ items }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed((value) => !value);
  };

  const getHistoryRows = () => {
    return items.map((item, index) => {
      const itemKey = getRowKey(item);
      const resultRows: React.ReactElement[] = [];

      if (index === 0) {
        resultRows.push(
          <HistoryRow
            key={`grouped-${itemKey}`}
            item={item}
            groupedCount={items.length}
            toggleCollapsed={toggleCollapsed}
            collapsed={collapsed}
          />,
        );
      }
      if (collapsed) {
        resultRows.push(<HistoryRow key={`${itemKey}`} item={item} />);
      }
      return resultRows;
    });
  };
  return <>{getHistoryRows()}</>;
};

export default HistoryGroup;
