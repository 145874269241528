import { useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { Textarea } from '@/components/core';
import useForm from '@/forms';
import type { FormProps } from '@/forms/types';
import NoteModel from '@/models/NoteModel';

import { FieldError, Form } from './styled';

interface NoteFormProps extends FormProps<typeof NoteModel> {
  children: React.ReactNode;
  ignoreRequired?: boolean;
}

const NoteForm: React.FC<NoteFormProps> = observer(({ children, options, ignoreRequired }) => {
  const intl = useIntl();

  const modelInitial = { content: '' };
  const { fields, submit } = useForm<typeof NoteModel>(NoteModel, undefined, modelInitial, {
    addMode: true,
    requiredError: intl.formatMessage({
      defaultMessage: 'This field is required',
      description: 'Note form content required text',
    }),
    ...options,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    await submit(e, { ignoreRequired });
  };

  return (
    <Form data-testid="note-form" onSubmit={handleSubmit} autoComplete="off" noValidate>
      <Textarea data-testid="content-field" rows={5} {...fields.content.inputProps} error={!!fields.content.error} />
      <FieldError data-testid="content-field-error">{fields.content.error}</FieldError>
      {children}
    </Form>
  );
});

export default NoteForm;
