import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { CenteringContainer } from '@/components/core';
import { Box } from '@/components/core/Box';
import List from '@/components/core/List';
import ListLoader from '@/components/loaders/ListLoader';
import { StatusScreenElement, StatusScreenElementHeader, Title } from '@/components/StatusScreen/styled';
import { buildKpiList } from '@/helpers';
import { mapProgressEnumToImageIndicator } from '@/helpers/mapProgressEnumToImageIndicator';
import { useStore } from '@/hooks';
import { useCurrentWorkplace } from '@/hooks/useCurrentWorkplace';
import { useCompanyData } from '@/providers';
import { ListItem } from '@/types';

const ShiftKpiContainer: React.FC = observer(() => {
  const { workplaceID } = useCurrentWorkplace();
  const { metrics, metricValues } = useStore();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const intl = useIntl();

  useEffect(() => {
    return () => {
      reaction(
        () => toJS(metricValues),
        () => {
          setUpdateCount((c) => c + 1);
        },
      );
    };
  });

  useEffect(() => {
    if (!companyDataLoading) {
      const displayedKpiItems = companyData?.companyConfig?.displayedKpiItems;

      if (displayedKpiItems && metricValues)
        setListItems(
          buildKpiList(workplaceID, [...metrics.values()], [...metricValues.values()], displayedKpiItems)
            .slice(2)
            .filter((kpi) => !(kpi.symbol?.toUpperCase() === 'REASON'))
            .filter((kpi) => !(kpi.symbol?.toUpperCase() === 'UNKNOWN'))
            .map((item) => {
              if (item.progress)
                return {
                  ...item,
                  dataTestId: `kpi-item-${item.label}`,
                  value: (
                    <Box>
                      {item.value}
                      <img
                        width="45px"
                        style={{ marginLeft: '6px' }}
                        src={mapProgressEnumToImageIndicator(item.progress)}
                        alt=""
                      />
                    </Box>
                  ),
                };
              return item;
            }),
        );
    }
  }, [workplaceID, metrics, metricValues, companyData, companyDataLoading, intl, updateCount]);

  const renderKpiHeader = () => {
    return (
      <StatusScreenElementHeader>
        <Title>
          <FormattedMessage defaultMessage="KPI for shift" description="KPI container title" />
        </Title>
      </StatusScreenElementHeader>
    );
  };

  if (companyDataLoading)
    return (
      <StatusScreenElement data-testid="KPI-container-loading" gridArea="KPI">
        {renderKpiHeader()}
        <ListLoader n={3} />
      </StatusScreenElement>
    );

  return (
    <StatusScreenElement data-testid="KPI-container" gridArea="KPI">
      {renderKpiHeader()}
      {listItems.length ? (
        <List
          rowHeight="10%"
          data-testid="KPI-list"
          rowStyles={{ minHeight: '40px', gridTemplateColumns: '5fr 2fr' }}
          items={listItems}
        />
      ) : (
        <CenteringContainer>
          {intl.formatMessage({
            defaultMessage: 'No KPIs to be displayed',
            description: 'KPI container empty list message',
          })}
        </CenteringContainer>
      )}
    </StatusScreenElement>
  );
});

export default ShiftKpiContainer;
