/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MutationErrorBase
 * auto generated base class for the model MutationErrorModel.
 */
export const MutationErrorModelBase = ModelBase
  .named('MutationError')
  .props({
    __typename: types.optional(types.literal("MutationError"), "MutationError"),
    field: types.union(types.undefined, types.null, types.string),
    messages: types.union(types.undefined, types.array(types.string)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MutationErrorModelSelector extends QueryBuilder {
  get field() { return this.__attr(`field`) }
  get messages() { return this.__attr(`messages`) }
}
export function selectFromMutationError() {
  return new MutationErrorModelSelector()
}

export const mutationErrorModelPrimitives = selectFromMutationError().field.messages
