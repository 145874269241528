import styled from 'styled-components';

import { ConfigRow } from '@/components/ConfigContainer';

interface WrapperProps {
  height?: string;
}

interface ContainerBodyWrapperProps {
  scrollable?: boolean;
}

export const ContainerHeader = styled.header`
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: ${({ theme }) => theme.gradients.reasonsHeader};
`;
export const ContainerWrapper = styled.div<WrapperProps>`
  position: relative;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`;

export const ContainerHeaderText = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin: 0px;
  padding: 10px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const ContainerBodyWrapper = styled.div<ContainerBodyWrapperProps>`
  position: relative;
  height: 100%;
  overflow-y: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  background-color: ${({ theme }) => theme.colors.mineSchaftDark};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  ${ConfigRow}:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.cannonSmoke};
  }
`;
