import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

interface HistoryItemTimeProps {
  $groupedCount?: number;
}

interface IHistoryItemContainer {
  $withoutStyles?: boolean;
}

export const DateSeparator = styled(motion.div)`
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.85);
`;

export const HistoryItemTime = styled.div<HistoryItemTimeProps>`
  font-size: 17px;
  display: flex;
  line-height: 20px;
  padding: 2px 16px;
  padding-right: 10px;
  text-align: center;
  align-items: center;
`;
export const HistoryItemContent = styled.div``;

export const HistoryItemContainer = styled(motion.li)<IHistoryItemContainer>`
  display: grid;
  grid-template-columns: 95px 1fr;
  padding: 3px 0 1px;
  border-left: 6px solid black;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  ${({ color }) =>
    color &&
    css`
      border-color: ${color};
    `}
  ${({ $withoutStyles }) =>
    $withoutStyles &&
    css`
      border: none;
      display: block;
    `}
`;
