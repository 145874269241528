import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getWorkplaceConfig } from '@/helpers';
import { OrderConfigModelType } from '@/models';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

const useOrderConfig = () => {
  const { data, loading } = useCompanyData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [orderConfig, setOrderConfig] = useState<OrderConfigModelType>();

  useEffect(() => {
    if (!loading) setOrderConfig(getWorkplaceConfig(data?.companyConfig, workplaceID)?.orderConfig);
  }, [data, loading, workplaceID]);

  return orderConfig;
};

export default useOrderConfig;
