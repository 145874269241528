/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * DepartmentBase
 * auto generated base class for the model DepartmentModel.
 */
export const DepartmentModelBase = ModelBase
  .named('Department')
  .props({
    __typename: types.optional(types.literal("Department"), "Department"),
    id: types.identifier,
    name: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DepartmentModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
}
export function selectFromDepartment() {
  return new DepartmentModelSelector()
}

export const departmentModelPrimitives = selectFromDepartment().name
