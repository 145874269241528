import styled, { css } from 'styled-components';

interface ItemsContainerProps {
  single?: boolean;
}

export const ItemsContainer = styled.div<ItemsContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
  ${({ single }) =>
    single &&
    css`
      grid-template-columns: 1fr;
    `}
`;
