import { useIntl } from 'react-intl';

import { Container } from 'andoncloud-sdk';

import WorkplacesList from '@/components/WorkplacesList';
import { useLocale } from '@/providers';

const WorkplaceSelectPage: React.FC = () => {
  const { toggleLocale } = useLocale();
  const intl = useIntl();

  const menuItems = [
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Change language to {languageShort}',
          description: 'App bar change language menu item text',
        },
        { languageShort: intl.locale === 'pl' ? 'Angielski' : 'Polish' },
      ),
      action: () => {
        toggleLocale();
      },
    },
  ];

  return (
    <Container
      headerProps={{
        position: 'static',
        leadingText: intl.formatMessage({
          defaultMessage: 'Workplaces',
          description: 'Workplaces page leading text',
        }),
        menuProps: {
          items: menuItems,
          buttonColor: 'white',
        },
      }}
    >
      <WorkplacesList />
    </Container>
  );
};

export default WorkplaceSelectPage;
