import { cast } from 'mobx-state-tree';

import { factory } from '@/factories';
import {
  CompanyConfigModelType,
  DeviceConfigModelType,
  FieldConfigModelType,
  OrderConfigModelType,
  ProductConfigModelType,
  RoleConfigModelType,
  RootStore,
  RootStoreType,
  ScreenSaverConfigModelType,
  WorkplaceConfigModelType,
} from '@/models';

export const buildDefaultOrderConfig = async ({ store }: { store?: RootStoreType }): Promise<OrderConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<OrderConfigModelType>(
    'OrderConfig',
    {
      featureEnabled: true,
      alternativeName: false,
      orderExecutionFormFieldsConfigs: await factory.buildMany<FieldConfigModelType>('FieldConfig', 4, [
        {
          __typename: 'FieldConfig',
          name: 'order',
          regexp: null,
          prefix: null,
          unit: null,
        },
        {
          __typename: 'FieldConfig',
          name: 'product',
          regexp: null,
          prefix: null,
          unit: null,
        },
        {
          __typename: 'FieldConfig',
          name: 'quantity',
          regexp: null,
          prefix: null,
          unit: 'szt.',
        },
        {
          __typename: 'FieldConfig',
          name: 'note',
          regexp: null,
          prefix: null,
          unit: null,
        },
      ]),
      orderFormFieldsConfigs: await factory.buildMany<FieldConfigModelType>('FieldConfig', 1, [
        {
          __typename: 'FieldConfig',
          name: 'number',
          regexp: '^[0-9]+$',
          prefix: 'GXC',
          unit: null,
        },
      ]),
      stopOrderExecutionEnabled: true,
    },
    { rootStore },
  );
};

export const buildDefaultProductConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<ProductConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<ProductConfigModelType>(
    'ProductConfig',
    {
      alternativeName: false,
      formFieldsConfigs: await factory.buildMany<FieldConfigModelType>(
        'FieldConfig',
        3,
        [
          {
            name: 'name',
            regexp: null,
            prefix: null,
            unit: null,
          },
          {
            name: 'number',
            regexp: '^[0-9]+$',
            prefix: 'CVD',
            unit: null,
          },
        ],
        { rootStore },
      ),
    },
    { rootStore },
  );
};

export const buildDefaultRoleConfig = async ({ store }: { store?: RootStoreType }): Promise<RoleConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<RoleConfigModelType>(
    'RoleConfig',
    {
      historyVisibilityEnabled: true,
    },
    { rootStore },
  );
};

export const buildDefaultScreenSaverConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<ScreenSaverConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<ScreenSaverConfigModelType>(
    'ScreenSaverConfig',
    {
      idleTime: 100000,
      enabled: true,
      displayAfterOrderCreated: false,
      displayAfterStatusChangeCreated: false,
      autoSignOut: false,
    },
    { rootStore },
  );
};

export const buildDefaultWorkplaceConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<WorkplaceConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<WorkplaceConfigModelType>(
    'WorkplaceConfig',
    {
      workplaceId: '320',
      orderConfig: await buildDefaultOrderConfig({ store: rootStore }),
      screenSaverConfig: await buildDefaultScreenSaverConfig({ store: rootStore }),
    },
    { rootStore },
  );
};
export const buildDefaultDeviceConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<DeviceConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<DeviceConfigModelType>(
    'DeviceConfig',
    {
      deviceId: '1',
      name: 'Stanowisko przy oknie',
      defaultWorkplaceId: '320',
    },
    { rootStore },
  );
};
export const buildDefaultCompanyConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<CompanyConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<CompanyConfigModelType>(
    'CompanyConfig',
    {
      productConfig: await buildDefaultProductConfig({ store: rootStore }),
      roleConfig: await buildDefaultRoleConfig({ store: rootStore }),
      workplacesConfigs: [await buildDefaultWorkplaceConfig({ store: rootStore })],
      displayedKpiItems: cast(['oee', 'performance', 'availability']),
      devicesConfigs: [await buildDefaultDeviceConfig({ store: rootStore })],
    },
    { rootStore },
  );
};
