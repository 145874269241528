import { Col } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';

import { StyledItem } from './ListItem/styled';

interface StyledContainerProps {
  height: number;
}

export const StyledContainer = styled(Col)<StyledContainerProps>`
  padding: 0;
  display: grid;
  column-gap: 1px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  ${StyledItem} {
    &:nth-child(2n) {
      background-color: ${({ theme }) => theme.colors.mineSchaftDark};
    }
  }
  ${({ height }) =>
    height &&
    css`
      grid-template-rows: repeat(${height}, auto);
    `}
`;
