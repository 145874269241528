/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * StatusChangeTransitionPermissionBase
 * auto generated base class for the model StatusChangeTransitionPermissionModel.
 */
export const StatusChangeTransitionPermissionModelBase = ModelBase
  .named('StatusChangeTransitionPermission')
  .props({
    __typename: types.optional(types.literal("StatusChangeTransitionPermission"), "StatusChangeTransitionPermission"),
    fromReasonId: types.union(types.undefined, types.string),
    roleId: types.union(types.undefined, types.string),
    toReasonId: types.union(types.undefined, types.string),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class StatusChangeTransitionPermissionModelSelector extends QueryBuilder {
  get fromReasonId() { return this.__attr(`fromReasonId`) }
  get roleId() { return this.__attr(`roleId`) }
  get toReasonId() { return this.__attr(`toReasonId`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
}
export function selectFromStatusChangeTransitionPermission() {
  return new StatusChangeTransitionPermissionModelSelector()
}

export const statusChangeTransitionPermissionModelPrimitives = selectFromStatusChangeTransitionPermission().fromReasonId.roleId.toReasonId.workplaceId
