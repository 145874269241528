import { Instance } from 'mobx-state-tree';
import { withTypedRefs } from 'mst-gql';

import { FieldConfigModelType } from './FieldConfigModel';
import { OrderConfigModelBase } from './OrderConfigModel.base';

/* The TypeScript type of an instance of OrderConfigModel */
export interface OrderConfigModelType extends Instance<typeof OrderConfigModel.Type> {}

/* A graphql query fragment builders for OrderConfigModel */
export { selectFromOrderConfig, orderConfigModelPrimitives, OrderConfigModelSelector } from './OrderConfigModel.base';

type Refs = {
  orderExecutionFormFieldsConfigs: FieldConfigModelType[];
  orderFormFieldsConfigs: FieldConfigModelType[];
};
/**
 * OrderConfigModel
 */
export const OrderConfigModel = withTypedRefs<Refs>()(OrderConfigModelBase).views((self) => ({
  orderExecutionFormFieldConfig(field: string) {
    return self.orderExecutionFormFieldsConfigs.find((config) => config.name === field);
  },
  orderFormFieldConfig(field: string) {
    return self.orderFormFieldsConfigs.find((config) => config.name === field);
  },
}));
