import { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';

export const getHistoryRowTestId = (item: HistoryItemModelType, grouped?: boolean): string => {
  const testId = `history-row-${item.type}-${item.date?.format('YYYY-MM-DD') || ''}-${item.instance?.id || ''}`;

  return grouped ? `grouped-${testId}` : testId;
};

export const getHistoryButtonTestId = (id: string, type: HistoryItemType, grouped?: boolean): string => {
  const testId = `history-button-${type}-${id}`;

  return grouped ? `grouped-${testId}` : testId;
};
