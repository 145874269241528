import { Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const WarningLayer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-left: 10px;
  background: rgba(45, 45, 45, 0.15);
  box-shadow: 0px -1px 0px #424242;
  backdrop-filter: blur(32px);
  z-index: 1;
`;

export const WarningContent = styled(Row)`
  flex-wrap: nowrap;
  height: 100%;
  color: rgba(255, 255, 255, 0.9);
`;
