import styled from 'styled-components';

import { Button } from '../Button/styled';

interface ButtonProps {
  size?: 'small' | 'normal';
  hasText?: boolean;
}

export const IconButton = styled(Button)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  min-height: 0;
  width: ${({ hasText }) => (hasText ? '5em' : '1.25em')};
  height: 1.25em;
  margin-top: 0;
  padding: 0;
  border-radius: 0.625em;
  &:disabled {
    opacity: 0.5;
  }
`;

export const ButtonText = styled.span<ButtonProps>`
  margin-left: 1em;
  margin-right: 0.5em;
  font-size: 0.85em;
`;

export const ButtonImg = styled.img<ButtonProps>`
  width: 1.25em;
  height: 1.25em;
`;
