import { FormattedMessage, useIntl } from 'react-intl';

import { ProcessResult } from 'mstform';
import { Col, Row } from 'styled-bootstrap-grid';

import { Button, Dialog } from '@/components/core';
import { NoteForm } from '@/components/forms';
import { NoteModelType } from '@/models/NoteModel';

interface CounterModalProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: (note: string) => Partial<ProcessResult> | null | undefined;
}

const CounterModal: React.FC<CounterModalProps> = ({ open, onCancel, onSubmit }) => {
  const intl = useIntl();

  const noteFormOptions = {
    backend: {
      // eslint-disable-next-line @typescript-eslint/require-await
      save: async ({ content }: NoteModelType) => {
        return onSubmit(content);
      },
    },
  };

  return (
    <Dialog
      title={intl.formatMessage({
        defaultMessage: 'Counter note',
        description: 'Counter modal title',
      })}
      open={open}
      data-testid="counter-modal"
    >
      <NoteForm options={noteFormOptions} ignoreRequired>
        <Row>
          <Col col={6}>
            <Button type="button" data-testid="counter-modal-cancel-button" onClick={onCancel}>
              <FormattedMessage defaultMessage="Cancel" description="Counter modal cancel button" />
            </Button>
          </Col>
          <Col col={6}>
            <Button type="submit" data-testid="counter-modal-submit-button" primary>
              <FormattedMessage defaultMessage="Send" description="Counter modal submit button" />
            </Button>
          </Col>
        </Row>
      </NoteForm>
    </Dialog>
  );
};

export default CounterModal;
