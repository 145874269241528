/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * OrderBase
 * auto generated base class for the model OrderModel.
 */
export const OrderModelBase = ModelBase
  .named('Order')
  .props({
    __typename: types.optional(types.literal("Order"), "Order"),
    executedCount: types.union(types.undefined, types.integer),
    id: types.identifier,
    note: types.union(types.undefined, types.null, types.string),
    number: types.union(types.undefined, types.string),
    plannedCount: types.union(types.undefined, types.integer),
    position: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrderModelSelector extends QueryBuilder {
  get executedCount() { return this.__attr(`executedCount`) }
  get id() { return this.__attr(`id`) }
  get note() { return this.__attr(`note`) }
  get number() { return this.__attr(`number`) }
  get plannedCount() { return this.__attr(`plannedCount`) }
  get position() { return this.__attr(`position`) }
}
export function selectFromOrder() {
  return new OrderModelSelector()
}

export const orderModelPrimitives = selectFromOrder().executedCount.note.number.plannedCount.position
