import { motion } from 'framer-motion';
import styled from 'styled-components';

export const HistoryOrderSeparator = styled(motion.div)`
  display: flex;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.85);
  padding: 12px 0;
  align-items: center;
  justify-content: center;
`;
