import styled, { css } from 'styled-components';

interface ListItemButtonProps {
  selected?: boolean;
}

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

export const ListItemButton = styled.button<ListItemButtonProps>`
  appearance: none;
  padding: 1em;
  font-size: 0.95em;
  color: white;
  border: none;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  &:nth-child(2n-1) {
    background-color: ${({ theme }) => theme.colors.youtubeBlack};
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.denim};
      cursor: unset;
      &:nth-child(2n-1) {
        background-color: ${({ theme }) => theme.colors.denim};
      }
    `};
`;
