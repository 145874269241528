/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum TrainingStatus {
  TRAINED="TRAINED",
TRAINING="TRAINING",
DISABLED="DISABLED",
INSUFFICIENT_DATA="INSUFFICIENT_DATA",
SERVER_ERROR="SERVER_ERROR"
}

/**
* TrainingStatus
*/
export const TrainingStatusEnumType = types.enumeration("TrainingStatus", [
        "TRAINED",
  "TRAINING",
  "DISABLED",
  "INSUFFICIENT_DATA",
  "SERVER_ERROR",
      ])
