import type { IntlShape } from 'react-intl';

import { converters, Field } from 'mstform';

import { OrderModel, ProductModel } from '@/models';

interface Context {
  intl: IntlShape;
}

export default {
  order: new Field(converters.model(OrderModel)),
  product: new Field(converters.model(ProductModel)),
  quantity: new Field(converters.maybeNull(converters.number), {
    conversionError: ({ intl }: Context) => {
      return intl.formatMessage({
        defaultMessage: 'The value provided is not valid',
        description: 'Order form quantity field conversion error',
      });
    },
  }),
  note: new Field(converters.string),
};
