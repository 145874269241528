import styled, { css } from 'styled-components';

interface MillerColumnsContainerProps {
  fullHeight?: boolean;
}
interface ButtonProps {
  selected?: boolean;
}
interface ColumnsContainerProps {
  directAction?: boolean;
}
interface BackButtonProps {
  hidden?: boolean;
}

export const MillerColumnsContainer = styled.div<MillerColumnsContainerProps>`
  position: relative;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'calc(100% - 155px)')};
`;

export const ColumnsContainer = styled.div<ColumnsContainerProps>`
  height: 100%;
  scroll-snap-type: x mandatory;
  scroll-snap-points-x: repeat(33%);
  display: flex;
  overflow-y: auto;
`;

export const ButtonIcon = styled.img`
  width: 16px;
  margin-right: 10px;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  align-items: center;
  border: none;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  padding: 32px 20px;
  background-color: ${({ theme }) => theme.colors.emperor};
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.cannonSmoke};
      color: ${({ theme }) => theme.colors.white};
    `}
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const ColumnHeaderPlaceholder = styled.div`
  position: absolute;
  top: 0;
  height: 30px;
  width: 100%;
  padding: 4px 20px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  border-top: 1px solid #717171;
  border-bottom: 1px solid #717171;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.scorpion};
`;
export const BackImg = styled.img`
  height: 20px;
  width: 20px;
`;
export const BackButton = styled.button<BackButtonProps>`
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
  top: 47%;
  left: 10px;
  padding: 26px;
  border-radius: 50%;
  background-color: rgba(40, 40, 40, 0.75);
  border: 2px solid rgba(56, 151, 254, 0.25);
  transition: all 0.35s;
  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`;
