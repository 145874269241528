import { Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import strokePattern from '../../images/strokePattern.svg';
import xDarkGrayIcon from '../../images/xDarkGrayIcon.svg';
import xGrayIcon from '../../images/xGrayIcon.svg';

export const NotificationOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 150px;
  border-top: #ffd60a 2px solid;
  background: #2d2d2d;
  z-index: 1000;
`;

export const NotificationContent = styled(Row)`
  padding: 38px 50px;
  background: url(${strokePattern}), linear-gradient(329.66deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-size: 'cover';
`;

export const NotificationMessage = styled.h2`
  margin: 0;
  color: #ffd60a;
`;

export const NotificationDetails = styled.span`
  color: rgba(255, 255, 255, 0.9);
`;

export const NotificationCloseButton = styled.button`
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: none;
  background: transparent url(${xDarkGrayIcon});
  &:hover {
    background: transparent url(${xGrayIcon});
  }
`;
