import { Col } from 'styled-bootstrap-grid';

import { ReactComponent as WarningOutlineIcon } from '../../images/warningOutlineIcon.svg';

import { WarningContent, WarningLayer } from './styled';

interface NotificationProps {
  message: React.ReactNode;
}

const WarningOverlay: React.FC<NotificationProps> = ({ message }) => {
  return (
    <WarningLayer>
      <WarningContent alignItems="center">
        <Col auto>
          <WarningOutlineIcon style={{ display: 'block', width: '35px', height: '35px' }} />
        </Col>
        <Col auto style={{ flexGrow: 1, maxWidth: 'calc(100% - 55px)' }}>
          {message}
        </Col>
      </WarningContent>
    </WarningLayer>
  );
};

export default WarningOverlay;
