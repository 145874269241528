import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { reaction, toJS } from 'mobx';

import { buildKpiList } from '@/helpers';
import { useCompanyData } from '@/providers';
import { ExtendedListItem } from '@/types';

import { useCurrentWorkplace } from './useCurrentWorkplace';
import { useLastStatusChange, useStore } from '.';

export const useLivetimeKpiList = (): {
  items: ExtendedListItem[];
  loading: boolean;
} => {
  const { workplaceID } = useCurrentWorkplace();
  const { metrics, metricValues } = useStore();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const [updateCount, setUpdateCount] = useState<number>(0);
  const lastStatusChange = useLastStatusChange();
  const intl = useIntl();
  const [kpiItems, setKpiItems] = useState<ExtendedListItem[]>();
  const [displayedItems, setDisplayedItems] = useState<ExtendedListItem[]>([]);

  const [lastStatusTime, setLastStatusTime] = useState<string>();

  useEffect(() => {
    return () => {
      reaction(
        () => toJS(metricValues),
        () => {
          setUpdateCount((c) => c + 1);
        },
      );
    };
  });

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (lastStatusChange)
      intervalId = setInterval(() => {
        setLastStatusTime(
          `${lastStatusChange.durationMinutes(new Date())}min ${lastStatusChange.durationSeconds(new Date())}sek`,
        );
      }, 1000);
    return () => clearInterval(intervalId);
  }, [lastStatusChange]);

  useEffect(() => {
    if (metricValues && companyData?.companyConfig?.displayedKpiItems) {
      setKpiItems(
        buildKpiList(
          workplaceID,
          [...metrics.values()],
          [...metricValues.values()],
          companyData.companyConfig.displayedKpiItems,
        )
          .filter((kpi) => !kpi.symbol?.toUpperCase().includes('UNKNOWN'))
          .slice(0, 2),
      );
    }
  }, [
    workplaceID,
    metrics,
    metricValues,
    intl,
    companyData?.companyConfig?.displayedKpiItems,
    kpiItems?.length,
    updateCount,
  ]);

  useEffect(() => {
    if (kpiItems && kpiItems.length) {
      setDisplayedItems(() => {
        return kpiItems.slice(0, 2).map((item) => {
          if (item.symbol?.toUpperCase() === 'REASON')
            return {
              label: 'Status',
              value: lastStatusChange?.reason.name,
              extraContent: lastStatusTime,
              statusColor: lastStatusChange?.reason.statusColor,
            } as ExtendedListItem;
          return item;
        });
      });
    } else {
      setDisplayedItems([
        {
          label: 'Status',
          value: lastStatusChange?.reason.name,
          extraContent: lastStatusTime,
          statusColor: lastStatusChange?.reason.statusColor,
        } as ExtendedListItem,
      ]);
    }
  }, [
    setDisplayedItems,
    intl,
    kpiItems,
    lastStatusChange?.reason.statusColor,
    lastStatusChange?.reason.name,
    lastStatusTime,
    updateCount,
  ]);

  return { items: displayedItems, loading: companyDataLoading };
};
