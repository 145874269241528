import { CompanyConfigModelType, WorkplaceConfigModelType } from '@/models';

export const getWorkplaceConfig = (
  companyConfig: CompanyConfigModelType | null,
  workplaceId?: string,
): WorkplaceConfigModelType | null => {
  return (
    companyConfig?.workplacesConfigs.find(
      (workplaceConfig: WorkplaceConfigModelType) => workplaceConfig.workplaceId === workplaceId,
    ) || null
  );
};

export default getWorkplaceConfig;
