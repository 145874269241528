import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { LoadingList } from '@/components/loaders/HistoryLoaders';
import { useStore } from '@/hooks';
import useHistoryItemModal, { modalContext } from '@/hooks/useHistoryItemModal';
import type { ModalStore } from '@/types';

import HistoryItemModal from '../HistoryItemModal';

import { getHistoryListItems } from './helpers';
import { CenteredContainer, HistoryListContainer } from './styled';

export interface HistoryListProps {
  loading?: boolean;
}

const HistoryList: React.FC<HistoryListProps> = observer(({ loading }) => {
  const { historyItems } = useStore();
  const [historyListItems, setHistoryListItems] = useState<React.ReactElement[]>(getHistoryListItems(historyItems));
  const modalStore: ModalStore = useHistoryItemModal();
  const intl = useIntl();

  useEffect(() => {
    return reaction(
      () => toJS(historyItems),
      () => {
        setHistoryListItems(getHistoryListItems(historyItems));
      },
    );
  });

  if (loading) return <LoadingList />;

  if (historyItems.length === 0)
    return (
      <HistoryListContainer>
        <CenteredContainer data-testid="empty-list-message">
          {intl.formatMessage({
            defaultMessage: 'No items to display',
            description: 'History list empty list message',
          })}
        </CenteredContainer>
      </HistoryListContainer>
    );

  return (
    <>
      <modalContext.Provider value={modalStore}>
        <HistoryListContainer data-testid="history-list">{historyListItems}</HistoryListContainer>
      </modalContext.Provider>
      <HistoryItemModal
        handleClose={modalStore.toggleOpen}
        open={modalStore.state.open}
        details={modalStore.state.details}
      />
    </>
  );
});

export default HistoryList;
