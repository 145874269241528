import { createContext, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useLoginStatus } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import { Moment } from 'moment';

import { getPrevStatusChange } from '../helpers/status-changes';
import { useQueryCompanyData, useQueryWorkplaceData, useStore } from '../hooks';
import {
  StatusChangeModelType,
  UserPresenceModelType,
  WorkplaceEvent,
  WorkplaceEventSubscriptionPayloadModelType,
} from '../models';
import HistoryItemModel, { HistoryItemType } from '../models/HistoryItemModel';
import workplaceEventQS from '../queries/workplace-event';
import { CompanyQueriesResults, ReasonsParamTypes, WorkplaceQueriesResults } from '../types';
import wsClient from '../utils/ws-client';

interface CompanyDataProviderProps {
  children: React.ReactNode;
}

interface WorkplaceDataProviderProps {
  children: React.ReactNode;
}

export const defaultCompanyResults = {
  data: {
    companyConfig: null,
    departments: [],
    metrics: [],
    shifts: [],
    users: [],
    workplaces: [],
  },
  loading: false,
  error: false,
};

export const defaultWorkplaceResults = {
  data: {
    counters: [],
    counterDirectories: [],
    metricValues: [],
    notifications: [],
    orders: [],
    ordersExecutions: [],
    products: [],
    reasons: [],
    statusChanges: [],
    usersPresences: [],
    statusChangeTransitionPermissions: null,
  },
  getStatusChangeTransitionPermissions: null,
  refetchStatusChangeTransitionPermissions: null,
  refetch: null,
  statusChangeTransitionPermissionsLoaded: false,
  loading: false,
  error: false,
};

export const CompanyDataContext = createContext<CompanyQueriesResults>(defaultCompanyResults);

export const CompanyDataProvider: React.FC<CompanyDataProviderProps> = observer(({ children }) => {
  const results = useQueryCompanyData();

  return <CompanyDataContext.Provider value={results}>{children}</CompanyDataContext.Provider>;
});

export const useCompanyData = (): CompanyQueriesResults => {
  return useContext<CompanyQueriesResults>(CompanyDataContext);
};

export const WorkplaceDataContext = createContext<WorkplaceQueriesResults>(defaultWorkplaceResults);

export const WorkplaceDataProvider: React.FC<WorkplaceDataProviderProps> = observer(({ children }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { authResponse } = useLoginStatus();
  const rootStore = useStore();
  const results = useQueryWorkplaceData(workplaceID);
  const {
    getStatusChangeTransitionPermissions,
    refetchStatusChangeTransitionPermissions,
    refetch,
    statusChangeTransitionPermissionsLoaded,
  } = results;
  // use ref to pass current loading state to subscription event callback
  const permissionsLoadedRef = useRef<boolean>();

  useEffect(() => {
    permissionsLoadedRef.current = statusChangeTransitionPermissionsLoaded;
  }, [statusChangeTransitionPermissionsLoaded]);

  useEffect(() => {
    if (
      !results.loading &&
      authResponse?.user.roleId &&
      // null means that there is no data and data fetching has not started yet
      results.data.statusChangeTransitionPermissions === null &&
      !!getStatusChangeTransitionPermissions
    ) {
      void getStatusChangeTransitionPermissions(authResponse.user.roleId.toString());
    }
    if (!results.loading && !wsClient.hasSubscription(workplaceID)) {
      rootStore.subscribeWorkplaceEvent(
        { id: workplaceID },
        workplaceEventQS.ALL,
        (data: WorkplaceEventSubscriptionPayloadModelType) => {
          switch (data.event) {
            case WorkplaceEvent.STATUS_CHANGE_CREATED:
              const statusChange = data.subject as StatusChangeModelType;
              const prevStatusChange = getPrevStatusChange(rootStore, workplaceID);

              prevStatusChange?.update({ finishedAt: statusChange.startedAt });

              if (!rootStore.historyItems.find((item) => item.instance?.id === statusChange.id)) {
                rootStore.addHistoryItem(
                  HistoryItemModel.create({
                    date: statusChange.startedAt as Moment,
                    instance: castToReferenceSnapshot(statusChange),
                    type: HistoryItemType.STATUS_CHANGE,
                  }),
                );
              }
              break;
            case WorkplaceEvent.USER_PRESENCE_CREATED:
            case WorkplaceEvent.USER_PRESENCE_UPDATED:
              const userPresence = data.subject as UserPresenceModelType;

              if (!rootStore.historyItems.find((item) => item.instance?.id === userPresence.id)) {
                if (userPresence.finishedAt) {
                  rootStore.addHistoryItem(
                    HistoryItemModel.create({
                      date: userPresence.finishedAt as Moment,
                      instance: castToReferenceSnapshot(userPresence),
                      type: HistoryItemType.CHECK_OUT,
                    }),
                  );
                } else {
                  rootStore.addHistoryItem(
                    HistoryItemModel.create({
                      date: userPresence.startedAt as Moment,
                      instance: castToReferenceSnapshot(userPresence),
                      type: HistoryItemType.CHECK_IN,
                    }),
                  );
                }
              }
              break;
            case WorkplaceEvent.ALL_STATUS_CHANGE_TRANSITION_PERMISSIONS_UPDATED:
              if (
                authResponse?.user?.roleId &&
                permissionsLoadedRef.current &&
                refetchStatusChangeTransitionPermissions
              ) {
                // eslint-disable-next-line no-void
                void refetchStatusChangeTransitionPermissions(authResponse.user.roleId.toString());
              }
              break;
            case WorkplaceEvent.CURRENT_SHIFT_CHANGED:
              if (refetch) {
                refetch(['ordersExecutions', 'statusChanges', 'usersPresences']);
              }
              break;
          }
        },
      );
    }
  }, [
    workplaceID,
    authResponse?.user?.roleId,
    rootStore,
    results.data.statusChangeTransitionPermissions,
    results.loading,
    getStatusChangeTransitionPermissions,
    refetchStatusChangeTransitionPermissions,
    refetch,
  ]);

  return <WorkplaceDataContext.Provider value={results}>{children}</WorkplaceDataContext.Provider>;
});

export const useWorkplaceData = (): WorkplaceQueriesResults => {
  return useContext<WorkplaceQueriesResults>(WorkplaceDataContext);
};
