/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MutationErrorModel, MutationErrorModelType } from "./MutationErrorModel"
import { MutationErrorModelSelector } from "./MutationErrorModel.base"
import { StatusChangeModel, StatusChangeModelType } from "./StatusChangeModel"
import { StatusChangeModelSelector } from "./StatusChangeModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  statusChange: StatusChangeModelType;
}

/**
 * CreateStatusChangePayloadBase
 * auto generated base class for the model CreateStatusChangePayloadModel.
 *
 * Autogenerated return type of CreateStatusChange
 */
export const CreateStatusChangePayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('CreateStatusChangePayload')
  .props({
    __typename: types.optional(types.literal("CreateStatusChangePayload"), "CreateStatusChangePayload"),
    errors: types.union(types.undefined, types.null, types.array(types.late((): any => MutationErrorModel))),
    statusChange: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => StatusChangeModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class CreateStatusChangePayloadModelSelector extends QueryBuilder {
  errors(builder: string | MutationErrorModelSelector | ((selector: MutationErrorModelSelector) => MutationErrorModelSelector) | undefined) { return this.__child(`errors`, MutationErrorModelSelector, builder) }
  statusChange(builder: string | StatusChangeModelSelector | ((selector: StatusChangeModelSelector) => StatusChangeModelSelector) | undefined) { return this.__child(`statusChange`, StatusChangeModelSelector, builder) }
}
export function selectFromCreateStatusChangePayload() {
  return new CreateStatusChangePayloadModelSelector()
}

export const createStatusChangePayloadModelPrimitives = selectFromCreateStatusChangePayload()
