/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FieldConfigModel, FieldConfigModelType } from "./FieldConfigModel"
import { FieldConfigModelSelector } from "./FieldConfigModel.base"
import { RootStoreType } from "./index"


/**
 * OrderConfigBase
 * auto generated base class for the model OrderConfigModel.
 */
export const OrderConfigModelBase = ModelBase
  .named('OrderConfig')
  .props({
    __typename: types.optional(types.literal("OrderConfig"), "OrderConfig"),
    afterPauseOrderExecutionReasonId: types.union(types.undefined, types.null, types.string),
    afterStartOrderExecutionReasonId: types.union(types.undefined, types.null, types.string),
    afterStopOrderExecutionReasonId: types.union(types.undefined, types.null, types.string),
    alternativeName: types.union(types.undefined, types.boolean),
    featureEnabled: types.union(types.undefined, types.boolean),
    orderExecutionFormFieldsConfigs: types.union(types.undefined, types.array(types.late((): any => FieldConfigModel))),
    orderFormFieldsConfigs: types.union(types.undefined, types.array(types.late((): any => FieldConfigModel))),
    stopOrderExecutionEnabled: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrderConfigModelSelector extends QueryBuilder {
  get afterPauseOrderExecutionReasonId() { return this.__attr(`afterPauseOrderExecutionReasonId`) }
  get afterStartOrderExecutionReasonId() { return this.__attr(`afterStartOrderExecutionReasonId`) }
  get afterStopOrderExecutionReasonId() { return this.__attr(`afterStopOrderExecutionReasonId`) }
  get alternativeName() { return this.__attr(`alternativeName`) }
  get featureEnabled() { return this.__attr(`featureEnabled`) }
  get stopOrderExecutionEnabled() { return this.__attr(`stopOrderExecutionEnabled`) }
  orderExecutionFormFieldsConfigs(builder: string | FieldConfigModelSelector | ((selector: FieldConfigModelSelector) => FieldConfigModelSelector) | undefined) { return this.__child(`orderExecutionFormFieldsConfigs`, FieldConfigModelSelector, builder) }
  orderFormFieldsConfigs(builder: string | FieldConfigModelSelector | ((selector: FieldConfigModelSelector) => FieldConfigModelSelector) | undefined) { return this.__child(`orderFormFieldsConfigs`, FieldConfigModelSelector, builder) }
}
export function selectFromOrderConfig() {
  return new OrderConfigModelSelector()
}

export const orderConfigModelPrimitives = selectFromOrderConfig().afterPauseOrderExecutionReasonId.afterStartOrderExecutionReasonId.afterStopOrderExecutionReasonId.alternativeName.featureEnabled.stopOrderExecutionEnabled
