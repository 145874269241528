import { companyConfigModelPrimitives } from '@/models/CompanyConfigModel.base';
import { deviceConfigModelPrimitives } from '@/models/DeviceConfigModel.base';
import { fieldConfigModelPrimitives } from '@/models/FieldConfigModel.base';
import { orderConfigModelPrimitives } from '@/models/OrderConfigModel.base';
import { productConfigModelPrimitives } from '@/models/ProductConfigModel.base';
import { roleConfigModelPrimitives } from '@/models/RoleConfigModel.base';
import { screenSaverConfigModelPrimitives } from '@/models/ScreenSaverConfigModel.base';
import { workplaceConfigModelPrimitives } from '@/models/WorkplaceConfigModel.base';

export default {
  ALL: companyConfigModelPrimitives
    .devicesConfigs(deviceConfigModelPrimitives)
    .workplacesConfigs(
      workplaceConfigModelPrimitives
        .orderConfig(
          orderConfigModelPrimitives
            .orderExecutionFormFieldsConfigs(fieldConfigModelPrimitives)
            .orderFormFieldsConfigs(fieldConfigModelPrimitives),
        )
        .screenSaverConfig(screenSaverConfigModelPrimitives),
    )
    .roleConfig(roleConfigModelPrimitives)
    .productConfig(productConfigModelPrimitives.formFieldsConfigs(fieldConfigModelPrimitives))

    .toString(),
};
