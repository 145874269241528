import { Dispatch, SetStateAction } from 'react';

import { CounterOperation } from '@/components/const';
import backspaceIcon from '@/images/backspaceIcon.svg';

import { KeyboardButton } from './styled';

interface IKeyboardButtons {
  setInputValue: Dispatch<SetStateAction<string>>;
  setOperationType: Dispatch<SetStateAction<CounterOperation | undefined>>;
  disabled: boolean;
  operationType: CounterOperation;
}

export const KeyboardButtons: React.FC<IKeyboardButtons> = ({
  setInputValue,
  setOperationType,
  disabled,
  operationType,
}) => {
  return (
    <>
      {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((value) => (
        <KeyboardButton
          type="button"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            const clickedButtonValue = event.currentTarget.value;
            setInputValue((prev) => `${prev + clickedButtonValue}`);
          }}
          data-testid={`keyboard-button-${value}`}
          key={`keyboard-button-${value}`}
          gridArea={`num-${value}`}
          disabled={disabled}
          value={value}
        >
          {value}
        </KeyboardButton>
      ))}
      <KeyboardButton
        type="button"
        onClick={() => {
          setInputValue((prev) => prev.slice(0, -1));
        }}
        data-testid="keyboard-button-backspace"
        gridArea="backspace"
        value="backspace"
        disabled={disabled}
      >
        <img width="40px" src={backspaceIcon} alt="" />
      </KeyboardButton>
      <KeyboardButton
        type="button"
        style={{ gridArea: 'addOperation', margin: 0 }}
        onClick={() => {
          setOperationType(CounterOperation.ADD);
        }}
        data-testid="keyboard-button-add"
        selected={operationType === CounterOperation.ADD}
      >
        +
      </KeyboardButton>
      <KeyboardButton
        type="button"
        onClick={() => {
          setOperationType(CounterOperation.SUBTRACT);
        }}
        data-testid="keyboard-button-subtract"
        gridArea="subtractOperation"
        selected={operationType === CounterOperation.SUBTRACT}
      >
        -
      </KeyboardButton>
    </>
  );
};
