import { FormattedMessage } from 'react-intl';

import arrowExpand from '@/images/arrowExpand.svg';

import { FloatingButton, FloatingButtonIcon } from './styled';

interface ShowRecommendationsButtonProps {
  showRecommendations: boolean;
  toggleShowRecommendations: () => void;
}

const ShowRecommendationsButton: React.FC<ShowRecommendationsButtonProps> = ({
  showRecommendations,
  toggleShowRecommendations,
}) => {
  return (
    !showRecommendations && (
      <FloatingButton onClick={() => toggleShowRecommendations()}>
        <FloatingButtonIcon src={arrowExpand} />
        <FormattedMessage defaultMessage="AI recommendations" description="Show recommendations button text" />
      </FloatingButton>
    )
  );
};

export default ShowRecommendationsButton;
