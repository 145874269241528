import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useLoginStatus } from 'andoncloud-sdk';
import { v4 as uuidv4 } from 'uuid';

import useForm from '@/forms';
import { prepareErrorMessages } from '@/forms/helpers';
import { FormStateOptions } from '@/forms/types';
import { formatECMAScriptRegexp } from '@/helpers/formatECMAScriptRegexp';
import { useStartOrderExecution, useStore } from '@/hooks';
import {
  FieldConfigModelType,
  OrderConfigModelType,
  OrderExecutionModel,
  OrderExecutionModelType,
  StartOrderExecutionPayloadModelType,
} from '@/models';
import { OrderExecutionModalState } from '@/types';

const useOrderExecutionForm = (
  workplaceID: string,
  orderExecutionModalState: OrderExecutionModalState,
  orderConfig: OrderConfigModelType,
  options?: FormStateOptions<typeof OrderExecutionModel>,
) => {
  const { authResponse } = useLoginStatus();
  const rootStore = useStore();
  const startOrderExecution = useStartOrderExecution(orderExecutionModalState);
  const intl = useIntl();

  const modelInitial = { id: uuidv4(), workplaceId: workplaceID };
  const { fieldsNames, setFieldsNames } = orderExecutionModalState;

  // use ref to provide current function for save callback
  const startOrderExecutionRef = useRef(startOrderExecution);

  const fieldsConfigs = orderConfig.orderExecutionFormFieldsConfigs;

  const getFieldConfig = (name: string) => fieldsConfigs.find((field) => field.name === name) as FieldConfigModelType;

  useEffect(() => {
    setFieldsNames(fieldsConfigs.map((field) => field.name as string));
  }, [fieldsConfigs, setFieldsNames]);

  useEffect(() => {
    if (startOrderExecution) {
      startOrderExecutionRef.current = startOrderExecution;
    }
  }, [startOrderExecution]);

  return useForm<typeof OrderExecutionModel>(OrderExecutionModel, undefined, modelInitial, {
    addMode: true,
    fields: fieldsNames,
    backend: {
      save: async (orderExecution: OrderExecutionModelType) => {
        if (authResponse && startOrderExecutionRef.current) {
          rootStore.addOrderExecution(orderExecution);

          const query = startOrderExecutionRef.current(orderExecution);

          const { startOrderExecution } = (await query.currentPromise()) as {
            startOrderExecution: StartOrderExecutionPayloadModelType;
          };

          if (startOrderExecution.errors) {
            const nonFieldError = startOrderExecution.errors.find((error) => error.field === 'base');

            if (nonFieldError) {
              return null;
            }
            return {
              errorValidations: [
                {
                  id: 'save',
                  messages: prepareErrorMessages(startOrderExecution.errors),
                },
              ],
            };
          }
        }
        return null;
      },
    },
    validation: {
      beforeSave: 'no',
    },
    extraValidation: (accessor, value: string) => {
      const fieldName = accessor.path.replace('/', '');

      if (fieldsNames.includes(fieldName)) {
        const fieldRegexp = formatECMAScriptRegexp(getFieldConfig(fieldName).regexp || '');

        if (value && fieldRegexp) {
          if (RegExp(fieldRegexp).exec(value)) return false;

          return intl.formatMessage({
            defaultMessage: 'The value provided is not valid',
            description: 'Order form field default conversion error',
          });
        }
      }
      return false;
    },
    requiredError: intl.formatMessage({
      defaultMessage: 'This field is required',
      description: 'Order form field required text',
    }),
    context: { intl },
    ...options,
  });
};

export default useOrderExecutionForm;
