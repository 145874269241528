import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Cookies from 'js-cookie';
import { Col } from 'styled-bootstrap-grid';

import { DeviceConfigModelType, WorkplaceModelType } from '@/models';

import { ConfigRow } from '../ConfigContainer';
import { Autocomplete, Container } from '../core';

interface IDeviceconfig {
  devicesConfigs: DeviceConfigModelType[];
  workplaces: WorkplaceModelType[];
}
const DeviceConfig: React.FC<IDeviceconfig> = ({ devicesConfigs, workplaces }) => {
  const intl = useIntl();
  const [cookieDeviceConfigId, setCookieDeviceConfigId] = useState(Cookies.get('device_config_id'));
  const [selectedDeviceConfig, setSelectedDeviceConfig] = useState<DeviceConfigModelType>();
  const updateCookieDeviceConfigId = () => {
    setCookieDeviceConfigId(Cookies.get('device_config_id'));
  };
  useEffect(() => {
    setSelectedDeviceConfig(devicesConfigs?.find((config) => config.deviceId === cookieDeviceConfigId));
    updateCookieDeviceConfigId();
  }, [devicesConfigs, cookieDeviceConfigId]);

  return (
    <Container
      style={{ height: 'calc(100vh - 80px)', marginTop: 0 }}
      data-testid="device-config"
      title={intl.formatMessage({
        defaultMessage: 'Device config',
        description: 'Service page device config container title',
      })}
    >
      <ConfigRow>
        <Col col={4} style={{ marginTop: '10px', alignItems: 'center' }}>
          {intl.formatMessage({
            defaultMessage: 'Computer configuration',
            description: 'Service page computer configuration label',
          })}
        </Col>
        <Col col={8} style={{ marginTop: '-20px' }}>
          <Autocomplete
            data-testid="device-config-id-autocomplete"
            initialValue={selectedDeviceConfig?.name}
            placeholder={intl.formatMessage({
              defaultMessage: 'Search for a device configuration',
              description: 'Service page autocomplete device config placeholder',
            })}
            itemIdKey="deviceId"
            itemLabelKey="name"
            onClear={() => {
              Cookies.remove('device_config_id');
              Cookies.remove('default_workplace_id');
              updateCookieDeviceConfigId();
            }}
            onSelect={(value, item: DeviceConfigModelType) => {
              Cookies.set('device_config_id', item.deviceId);
              if (item.defaultWorkplaceId) Cookies.set('default_workplace_id', item.defaultWorkplaceId);
              updateCookieDeviceConfigId();
            }}
            items={devicesConfigs}
          />
        </Col>
      </ConfigRow>
      <ConfigRow>
        <Col col={4}>
          {intl.formatMessage({
            defaultMessage: 'Default workplace',
            description: 'Service page default workplace label',
          })}
        </Col>
        <Col col={8}>{workplaces.find((item) => item.id === selectedDeviceConfig?.defaultWorkplaceId)?.name}</Col>
      </ConfigRow>
    </Container>
  );
};

export default DeviceConfig;
