import { useEffect, useState } from 'react';

import { OrderExecutionModelType } from '../models';

import useLastOrderExecution from './useLastOrderExecution';

const useCurrentOrderExecution = (): OrderExecutionModelType | null => {
  const lastOrderExecution = useLastOrderExecution();
  const [currentOrderExecution, setCurrentOrderExecution] = useState<OrderExecutionModelType | null>(null);

  useEffect(() => {
    if (lastOrderExecution && !lastOrderExecution.finishedAt) {
      setCurrentOrderExecution(lastOrderExecution);
    }
  }, [lastOrderExecution]);

  return currentOrderExecution;
};

export default useCurrentOrderExecution;
