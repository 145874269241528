/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MetricBase
 * auto generated base class for the model MetricModel.
 */
export const MetricModelBase = ModelBase
  .named('Metric')
  .props({
    __typename: types.optional(types.literal("Metric"), "Metric"),
    id: types.identifier,
    name: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MetricModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
}
export function selectFromMetric() {
  return new MetricModelSelector()
}

export const metricModelPrimitives = selectFromMetric().name
