import grayGear from '@/images/grayGear.svg';
import greenGear from '@/images/greenGear.svg';
import redGear from '@/images/redGear.svg';
import yellowGear from '@/images/yellowGear.svg';
import { AndonLightColor } from '@/models';

export const mapStatusColorToGearImg = (color: AndonLightColor): string => {
  if (color === 'yellow') return yellowGear;
  if (color === 'green') return greenGear;
  if (color === 'red') return redGear;
  return grayGear;
};
