import ContentLoader from 'react-content-loader';

interface ListItemsLoaderProps {
  'data-testid'?: string;
}

const ListItemsLoader: React.FC<ListItemsLoaderProps> = ({ 'data-testid': dataTestID }) => (
  <div data-testid={dataTestID}>
    <ContentLoader speed={1.5} width="100%" height="100%" backgroundColor="#282828" foregroundColor="#3e3e3e">
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
  </div>
);

export default ListItemsLoader;
