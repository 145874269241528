import Cookies from 'js-cookie';

export const getRedirectPath = () => {
  const initialPath = window.location.pathname;
  const defaultWorkplaceId = Cookies.get('default_workplace_id');

  if (['/', '/login', '/404'].includes(initialPath)) {
    if (defaultWorkplaceId) return `/workplaces/${defaultWorkplaceId}?loginRedirect`;
    return '/workplaces';
  }
  return initialPath;
};
