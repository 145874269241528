import { mapStatusColorToGearImg } from '@/helpers/mapStatusColorToGearImg';
import { AndonLightColor } from '@/models';

import { ButtonsStack, StatusColorButton } from './styled';

interface StatusColorFilterProps {
  selected: AndonLightColor | null;
  onSelect: (selected: AndonLightColor | null) => void;
}
const StatusColorFilter: React.FC<StatusColorFilterProps> = ({ selected, onSelect }) => {
  return (
    <ButtonsStack>
      {Object.keys(AndonLightColor).map((color) => {
        const isSelected = color === selected;
        return (
          <StatusColorButton
            key={color}
            selected={isSelected}
            onClick={() => onSelect(isSelected ? null : (color as AndonLightColor))}
          >
            <img src={mapStatusColorToGearImg(color as AndonLightColor)} alt={color} />
          </StatusColorButton>
        );
      })}
    </ButtonsStack>
  );
};

export default StatusColorFilter;
