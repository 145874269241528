import { useIntl } from 'react-intl';

import { ConfirmationModal } from '@/components/core';

interface RemoveAllPresencesConfirmationModalProps {
  allPresencesModalOpen: boolean;
  toggleAllPresencesModal: () => void;
  onSuccess: () => void;
}

const RemoveAllPresencesConfirmationModal: React.FC<RemoveAllPresencesConfirmationModalProps> = ({
  allPresencesModalOpen,
  toggleAllPresencesModal,
  onSuccess,
}) => {
  const intl = useIntl();
  return (
    <ConfirmationModal
      title={intl.formatMessage({
        defaultMessage: 'Clock out',
        description: 'Presences logout all users modal title',
      })}
      message={intl.formatMessage({
        defaultMessage: 'Are you sure you want to clock out all people from this workplace?',
        description: 'Presences logout all users modal message',
      })}
      isOpen={allPresencesModalOpen}
      onSuccess={onSuccess}
      onFailure={() => {
        toggleAllPresencesModal();
      }}
      data-testid="remove-all-presences-modal"
    />
  );
};

export default RemoveAllPresencesConfirmationModal;
