import boostIndicator from '@/images/boostIndicator.svg';
import failureIndicator from '@/images/failureIndicator.svg';
import successIndicator from '@/images/successIndicator.svg';

export const mapProgressEnumToBlackImageIndicator = (progress: string): string => {
  if (progress === 'boost') {
    return boostIndicator;
  }
  if (progress === 'failure') {
    return failureIndicator;
  }
  return successIndicator;
};
