import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, CenteringContainer } from '@/components/core';

const NotFoundPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <CenteringContainer style={{ flexDirection: 'column', backgroundColor: '#1E1E1E' }} height="100vh">
      <h2>
        {intl.formatMessage({ defaultMessage: '404 - Page not found', description: 'Not found page title message' })}
      </h2>
      <div>
        <Button
          onClick={() => {
            navigate('/workplaces');
          }}
          size="large"
        >
          {intl.formatMessage({
            defaultMessage: 'Redirect to workplaces',
            description: 'Not found page redirect to workplaces',
          })}
        </Button>
      </div>
    </CenteringContainer>
  );
};

export default NotFoundPage;
