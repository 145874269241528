import { IntlShape } from 'react-intl';

import {
  AccountTree,
  Calculate,
  CorporateFare,
  Desk,
  Inventory,
  More,
  NotificationsActive,
  People,
  RocketLaunch,
  Sensors,
  Summarize,
} from '@mui/icons-material';

import buildAdminURL from '@/helpers/urls/buildAdminUrl';

const getSidebarMenuItems = (intl: IntlShape) => [
  {
    icon: <Desk />,
    label: intl.formatMessage({
      defaultMessage: 'Workplaces',
      description: 'Sidebar menu workplaces menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Workplaces',
          description: 'Sidebar workplaces menu workplaces item text',
        }),
        url: buildAdminURL('subgroups'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Workplaces groups',
          description: 'Sidebar workplaces menu workplaces groups item text',
        }),
        url: buildAdminURL('machines'),
      },
    ],
  },
  {
    icon: <AccountTree />,
    label: intl.formatMessage({
      defaultMessage: 'Statuses',
      description: 'Sidebar menu statuses menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Events',
          description: 'Sidebar statuses menu events item text',
        }),
        url: buildAdminURL('status_changes'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Status dictionaries',
          description: 'Sidebar statuses menu status dictionaries item text',
        }),
        url: buildAdminURL('reason_groups'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Unified status names',
          description: 'Sidebar statuses menu unified status names item text',
        }),
        url: buildAdminURL('grid_reasons'),
      },
    ],
  },
  {
    icon: <NotificationsActive />,
    label: intl.formatMessage({
      defaultMessage: 'Escalation',
      description: 'Sidebar menu escalation menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Notifications',
          description: 'Sidebar escalation menu notifications item text',
        }),
        url: buildAdminURL('notifications'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Alerts',
          description: 'Sidebar escalation menu alerts item text',
        }),
        url: buildAdminURL('alerts_events'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Alerts rules',
          description: 'Sidebar escalation menu alerts rules item text',
        }),
        url: buildAdminURL('alerts_rules'),
      },
    ],
  },
  {
    icon: <Calculate />,
    label: intl.formatMessage({
      defaultMessage: 'Counters',
      description: 'Sidebar menu counters menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Counters',
          description: 'Sidebar counters menu counters item text',
        }),
        url: buildAdminURL('counters'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Counter entries',
          description: 'Sidebar counters menu counter entries item text',
        }),
        url: buildAdminURL('counter_entries'),
      },
    ],
  },
  {
    icon: <Inventory />,
    label: intl.formatMessage({
      defaultMessage: 'Products',
      description: 'Sidebar menu products menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Products',
          description: 'Sidebar products menu products item text',
        }),
        url: buildAdminURL('products'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Performance standards',
          description: 'Sidebar products menu performance standards item text',
        }),
        url: buildAdminURL('standard_rates'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Order executions',
          description: 'Sidebar products menu order executions item text',
        }),
        url: buildAdminURL('order_executions'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Orders',
          description: 'Sidebar products menu orders item text',
        }),
        url: buildAdminURL('orders'),
      },
    ],
  },
  {
    icon: <People />,
    label: intl.formatMessage({
      defaultMessage: 'Users',
      description: 'Sidebar menu users menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Users',
          description: 'Sidebar users menu users item text',
        }),
        url: buildAdminURL('users'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Roles',
          description: 'Sidebar users menu roles item text',
        }),
        url: buildAdminURL('roles'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Teams',
          description: 'Sidebar users menu teams item text',
        }),
        url: buildAdminURL('teams'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'User presences',
          description: 'Sidebar users menu user presences item text',
        }),
        url: buildAdminURL('user_presences'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Negative ratings',
          description: 'Sidebar users menu negative ratings item text',
        }),
        url: buildAdminURL('negative_assessments'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Positive ratings',
          description: 'Sidebar users menu positive ratings item text',
        }),
        url: buildAdminURL('positive_assessments'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Onboardings',
          description: 'Sidebar users menu onboardings item text',
        }),
        url: buildAdminURL('onboardings'),
      },
    ],
  },
  {
    icon: <Summarize />,
    label: intl.formatMessage({
      defaultMessage: 'Reports',
      description: 'Sidebar menu reports menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'OEE reports',
          description: 'Sidebar reports menu oee reports item text',
        }),
        url: buildAdminURL('oee_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Quality reports',
          description: 'Sidebar reports menu quality reports item text',
        }),
        url: buildAdminURL('quality_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Workplaces performance reports',
          description: 'Sidebar reports menu workplaces performance reports item text',
        }),
        url: buildAdminURL('workplace_performance_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Events reports',
          description: 'Sidebar reports menu events reports item text',
        }),
        url: buildAdminURL('event_log_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Reasons reports',
          description: 'Sidebar reports menu reasons reports item text',
        }),
        url: buildAdminURL('reason_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Downtime reports',
          description: 'Sidebar reports menu downtime reports item text',
        }),
        url: buildAdminURL('downtime_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Downtime summary reports',
          description: 'Sidebar reports menu downtime summary reports item text',
        }),
        url: buildAdminURL('downtime_summary_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Counters reports',
          description: 'Sidebar reports menu counters reports item text',
        }),
        url: buildAdminURL('counter_entry_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Employee bonus reports',
          description: 'Sidebar reports menu employee bonus reports item text',
        }),
        url: buildAdminURL('bonus_reports'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Advanced performance reports',
          description: 'Sidebar reports menu advanced performance reports item text',
        }),
        url: buildAdminURL('performance_reports'),
      },
    ],
  },
  {
    icon: <Sensors />,
    label: intl.formatMessage({
      defaultMessage: 'Sensors',
      description: 'Sidebar menu sensors menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Distance sensors',
          description: 'Sidebar sensors menu distance sensors item text',
        }),
        url: buildAdminURL('distance_sensors'),
      },
    ],
  },
  {
    icon: <RocketLaunch />,
    label: intl.formatMessage({
      defaultMessage: 'Automation',
      description: 'Sidebar menu automation menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'State expirations',
          description: 'Sidebar automation menu state expirations item text',
        }),
        url: buildAdminURL('automation_state_expirations'),
      },
    ],
  },
  {
    icon: <CorporateFare />,
    label: intl.formatMessage({
      defaultMessage: 'Organization',
      description: 'Sidebar menu organization menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Departments',
          description: 'Sidebar organization menu departments item text',
        }),
        url: buildAdminURL('departments'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Organizations',
          description: 'Sidebar organization menu organizations item text',
        }),
        url: buildAdminURL('companies'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Floor plans',
          description: 'Sidebar organization menu floor plans item text',
        }),
        url: buildAdminURL('floor_plans'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Break plans',
          description: 'Sidebar organization menu break plans item text',
        }),
        url: buildAdminURL('planned_breaks'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Shifts',
          description: 'Sidebar organization menu shifts item text',
        }),
        url: buildAdminURL('shifts'),
      },
    ],
  },
  {
    icon: <More />,
    label: intl.formatMessage({
      defaultMessage: 'Other options',
      description: 'Sidebar menu other options menu text',
    }),
    items: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Key performance indicators',
          description: 'Sidebar other options menu key performance indicators item text',
        }),
        url: buildAdminURL('key_performance_indicators'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Dashboards',
          description: 'Sidebar other options menu dashboards item text',
        }),
        url: buildAdminURL('dashboards'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'REST hooks',
          description: 'Sidebar other options menu rest hooks item text',
        }),
        url: buildAdminURL('resthook_hooks'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Manageable devices',
          description: 'Sidebar other options menu manageable devices item text',
        }),
        url: buildAdminURL('managed_devices'),
      },
    ],
  },
];

export default getSidebarMenuItems;
