import { useIntl } from 'react-intl';

import { TextTruncate } from '@/components/core';
import { ShiftModelType } from '@/models';
import { HistoryItemType } from '@/models/HistoryItemModel';

import HistoryButton, { HistoryButtonText } from '../HistoryButton';

export interface ShiftProps {
  shift: ShiftModelType;
  type: HistoryItemType.SHIFT_START | HistoryItemType.SHIFT_END;
}

const Shift: React.FC<ShiftProps> = ({ shift, type }) => {
  const intl = useIntl();

  const getButtonText = () => {
    if (type === HistoryItemType.SHIFT_START)
      return intl.formatMessage(
        {
          defaultMessage: `Start of the shift ({shiftName})`,
          description: 'shift start button text',
        },
        { shiftName: shift.name || '' },
      );
    return intl.formatMessage(
      {
        defaultMessage: `End of the shift ({shiftName})`,
        description: 'shift end button text',
      },
      { shiftName: shift.name || '' },
    );
  };

  return (
    <HistoryButton readOnly data-testid={`shift-button-${shift.id}`}>
      {/* empty div container to fit the historyButton grid that makes space for the icon */}
      <div />
      <HistoryButtonText>
        <TextTruncate>{getButtonText()}</TextTruncate>
      </HistoryButtonText>
    </HistoryButton>
  );
};

export default Shift;
