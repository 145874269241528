/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  order: OrderModelType;
  product: ProductModelType;
}

/**
 * ItemBase
 * auto generated base class for the model ItemModel.
 */
export const ItemModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Item')
  .props({
    __typename: types.optional(types.literal("Item"), "Item"),
    count: types.union(types.undefined, types.number),
    id: types.identifier,
    order: types.union(types.undefined, MSTGQLRef(types.late((): any => OrderModel))),
    product: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ProductModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ItemModelSelector extends QueryBuilder {
  get count() { return this.__attr(`count`) }
  get id() { return this.__attr(`id`) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined) { return this.__child(`order`, OrderModelSelector, builder) }
  product(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined) { return this.__child(`product`, ProductModelSelector, builder) }
}
export function selectFromItem() {
  return new ItemModelSelector()
}

export const itemModelPrimitives = selectFromItem().count
