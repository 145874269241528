import styled, { css } from 'styled-components';

import { mapTimelineColor } from '@/helpers/history-colors';
import { AndonLightColor } from '@/models';

export interface TimelineElementProps {
  percentWidth: number;
  color?: string;
}

export const TimelineWrapper = styled.div`
  margin: 6px 0;
  z-index: 3;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 6px;
  height: 12px;
  width: 100%;
  overflow: hidden;
`;
export const TimelineTimeInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TimelineTimeInfo = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
`;
export const TimelineElement = styled.div<TimelineElementProps>`
  ${({ percentWidth, color }: TimelineElementProps) => css`
    flex-basis: ${percentWidth}%;
    flex-shrink: 0;
    background-color: ${mapTimelineColor(color as AndonLightColor)};
  `}
`;
