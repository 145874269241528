import { useIntl } from 'react-intl';

import { useOrderConfig } from '@/hooks';
import { OrderExecutionModelType } from '@/models';
import { HistoryItemType } from '@/models/HistoryItemModel';

import { HistoryOrderSeparator } from './styled';

interface HistoryOrderExecutionProps {
  orderExecution: OrderExecutionModelType;
  type: HistoryItemType.ORDER_EXECUTION_START | HistoryItemType.ORDER_EXECUTION_END;
}

const HistoryOrderExecution: React.FC<HistoryOrderExecutionProps> = ({ orderExecution, type }) => {
  const orderConfig = useOrderConfig();
  const intl = useIntl();

  const renderSeparatorText = () => {
    if (type === HistoryItemType.ORDER_EXECUTION_START) {
      return orderConfig?.alternativeName
        ? intl.formatMessage(
            {
              defaultMessage: `Order number: {orderNumber}`,
              description: 'History order separator order number alternative message',
            },
            { orderNumber: orderExecution.order?.number },
          )
        : intl.formatMessage(
            {
              defaultMessage: `Order number: {orderNumber}`,
              description: 'History order separator order number message',
            },
            { orderNumber: orderExecution.order?.number },
          );
    }
    return orderConfig?.alternativeName
      ? intl.formatMessage({
          defaultMessage: `Order finished`,
          description: 'History order separator end of the order alternative message',
        })
      : intl.formatMessage({
          defaultMessage: `Order finished`,
          description: 'History order separator end of the order message',
        });
  };

  return (
    <HistoryOrderSeparator data-testid={`history-order-separator-${orderExecution?.order?.number || ''}`}>
      {renderSeparatorText()}
    </HistoryOrderSeparator>
  );
};

export default HistoryOrderExecution;
