import downArrow from '@/images/downArrow.svg';
import upArrow from '@/images/upArrow.svg';

import { HistoryItemButton, HistoryItemIcon } from './styled';

interface HistoryItemButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'data-testid'?: string;
  icon?: string;
  readOnly?: boolean;
  arrowUp?: boolean;
  arrowDown?: boolean;
}

const HistoryButton: React.FC<HistoryItemButtonProps> = ({
  children,
  onClick,
  readOnly,
  icon,
  arrowUp,
  arrowDown,
  'data-testid': dataTestID,
}) => (
  <HistoryItemButton
    arrowUp={arrowUp}
    arrowDown={arrowDown}
    readOnly={readOnly}
    onClick={onClick}
    data-testid={dataTestID}
  >
    {icon && <HistoryItemIcon src={icon} />}
    {children}
    {arrowUp && <img src={upArrow} alt="" width="20px" />}
    {arrowDown && <img src={downArrow} alt="" width="20px" />}
  </HistoryItemButton>
);

export { HistoryButtonText, HistoryButtonIcon } from './styled';

export default HistoryButton;
