import { buildCountersURL } from '@/helpers/urls/buildCountersURL';
import { buildReasonURL } from '@/helpers/urls/buildReasonURL';
import { ScreenEnum } from '@/models';

const mapScreenToPath = (item: keyof typeof ScreenEnum, workplaceID: string) => {
  switch (item) {
    case ScreenEnum.STATUS_SCREEN:
      return `/workplaces/${workplaceID}/status-screen`;
    case ScreenEnum.STATUSES:
      return buildReasonURL(workplaceID);
    case ScreenEnum.COUNTERS:
      return buildCountersURL(workplaceID);
    case ScreenEnum.WORKPLACES:
      return '/workplaces';
    default:
      return '/';
  }
};

export default mapScreenToPath;
