/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum MetricUnitEnum {
  DURATION="DURATION",
PERCENT="PERCENT",
ROUND="ROUND"
}

/**
* MetricUnitEnum
*/
export const MetricUnitEnumType = types.enumeration("MetricUnitEnum", [
        "DURATION",
  "PERCENT",
  "ROUND",
      ])
