import styled, { css } from 'styled-components';

interface TextareaProps {
  error?: boolean;
}

export const Textarea = styled.textarea<TextareaProps>`
  appearance: none;
  resize: none;
  width: 100%;
  margin-top: 20px;
  padding: 1em;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  ${(props) =>
    props.error &&
    css`
      border-color: ${({ theme }) => theme.colors.cardinal};
    `}
`;
