import styled from 'styled-components';

import { Button } from '@/components/core/Button/styled';

export const FloatingButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  right: 10px;
  width: auto;
  padding: 10px;
  font-size: 14px;
`;

export const FloatingButtonIcon = styled.img`
  margin-right: 0.5em;
`;
