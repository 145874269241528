import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { DeviceConfigModelType } from '@/models';
import { useCompanyData } from '@/providers';

const useDefaultWorkplaceId = (): string | undefined => {
  const { loading, data } = useCompanyData();
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfigModelType>();

  const devicesConfigs = data?.companyConfig?.devicesConfigs;
  const cookieDeviceConfigId = Cookies.get('device_config_id');

  useEffect(() => {
    if (!loading) {
      setDeviceConfig(devicesConfigs?.find((config) => config.deviceId === cookieDeviceConfigId));
    }
  }, [devicesConfigs, cookieDeviceConfigId, loading, data?.companyConfig?.devicesConfigs]);

  return deviceConfig?.defaultWorkplaceId;
};

export default useDefaultWorkplaceId;
