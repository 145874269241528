/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum WorkplaceEvent {
  ALL_STATUS_CHANGE_TRANSITION_PERMISSIONS_UPDATED="ALL_STATUS_CHANGE_TRANSITION_PERMISSIONS_UPDATED",
COUNTER_UPDATED="COUNTER_UPDATED",
CURRENT_SHIFT_CHANGED="CURRENT_SHIFT_CHANGED",
METRIC_VALUE_UPDATED="METRIC_VALUE_UPDATED",
NOTIFICATION_CREATED="NOTIFICATION_CREATED",
ORDER_EXECUTION_UPDATED="ORDER_EXECUTION_UPDATED",
ORDER_UPDATED="ORDER_UPDATED",
STATUS_CHANGE_CREATED="STATUS_CHANGE_CREATED",
USER_PRESENCE_CREATED="USER_PRESENCE_CREATED",
USER_PRESENCE_UPDATED="USER_PRESENCE_UPDATED"
}

/**
* WorkplaceEvent
*/
export const WorkplaceEventEnumType = types.enumeration("WorkplaceEvent", [
        "ALL_STATUS_CHANGE_TRANSITION_PERMISSIONS_UPDATED",
  "COUNTER_UPDATED",
  "CURRENT_SHIFT_CHANGED",
  "METRIC_VALUE_UPDATED",
  "NOTIFICATION_CREATED",
  "ORDER_EXECUTION_UPDATED",
  "ORDER_UPDATED",
  "STATUS_CHANGE_CREATED",
  "USER_PRESENCE_CREATED",
  "USER_PRESENCE_UPDATED",
      ])
