/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ScreenSaverConfigBase
 * auto generated base class for the model ScreenSaverConfigModel.
 */
export const ScreenSaverConfigModelBase = ModelBase
  .named('ScreenSaverConfig')
  .props({
    __typename: types.optional(types.literal("ScreenSaverConfig"), "ScreenSaverConfig"),
    autoSignOut: types.union(types.undefined, types.boolean),
    displayAfterIdle: types.union(types.undefined, types.boolean),
    displayAfterOrderCreated: types.union(types.undefined, types.boolean),
    displayAfterStatusChangeCreated: types.union(types.undefined, types.boolean),
    enabled: types.union(types.undefined, types.boolean),
    idleTime: types.union(types.undefined, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ScreenSaverConfigModelSelector extends QueryBuilder {
  get autoSignOut() { return this.__attr(`autoSignOut`) }
  get displayAfterIdle() { return this.__attr(`displayAfterIdle`) }
  get displayAfterOrderCreated() { return this.__attr(`displayAfterOrderCreated`) }
  get displayAfterStatusChangeCreated() { return this.__attr(`displayAfterStatusChangeCreated`) }
  get enabled() { return this.__attr(`enabled`) }
  get idleTime() { return this.__attr(`idleTime`) }
}
export function selectFromScreenSaverConfig() {
  return new ScreenSaverConfigModelSelector()
}

export const screenSaverConfigModelPrimitives = selectFromScreenSaverConfig().autoSignOut.displayAfterIdle.displayAfterOrderCreated.displayAfterStatusChangeCreated.enabled.idleTime
