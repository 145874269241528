import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { reaction, toJS } from 'mobx';

import { getWorkplaceUsersPresences } from '@/helpers/users-presences';
import { UserPresenceModelType } from '@/models';
import { ReasonsParamTypes } from '@/types';

import useStore from './useStore';

// TODO deprecated
const useCurrentWorkplacePresences = (): UserPresenceModelType[] => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { userPresences } = useStore();
  const [currentWorkplacePresences, setCurrentWorkplacePresences] = useState<UserPresenceModelType[]>([]);
  useEffect(() => {
    setCurrentWorkplacePresences(getWorkplaceUsersPresences(workplaceID, [...userPresences.values()]));
  }, [workplaceID, userPresences, userPresences.size]);

  useEffect(() => {
    return () => {
      reaction(
        () => toJS(userPresences),
        () => {
          setCurrentWorkplacePresences(getWorkplaceUsersPresences(workplaceID, [...userPresences.values()]));
        },
      );
    };
  });
  return currentWorkplacePresences;
};

export default useCurrentWorkplacePresences;
