import { useRoutes } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import useScreenSaver from '@/hooks/useScreenSaver';

import routes from './routes';

interface AppRoutingProps {
  isAuthenticated: boolean;
  lastWorkplaceId?: string;
}

const AppRouting: React.FC<AppRoutingProps> = observer(({ isAuthenticated, lastWorkplaceId }) => {
  useScreenSaver(lastWorkplaceId);

  return useRoutes(routes(isAuthenticated));
});

export default AppRouting;
