/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ScreenEnum {
  COUNTERS="COUNTERS",
STATUSES="STATUSES",
STATUS_SCREEN="STATUS_SCREEN",
WORKPLACES="WORKPLACES"
}

/**
* ScreenEnum
*/
export const ScreenEnumType = types.enumeration("ScreenEnum", [
        "COUNTERS",
  "STATUSES",
  "STATUS_SCREEN",
  "WORKPLACES",
      ])
