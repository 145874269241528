import { useIntl } from 'react-intl';

import { ConfirmationModal } from '@/components/core';

export interface SinglePresenceModalConfig {
  name: string;
  onSuccess: () => void;
}

interface RemoveSinglePresenceConfirmationModalProps {
  singlePresenceModalConfig: SinglePresenceModalConfig | undefined;
  singlePresenceModalOpen: boolean;
  toggleSinglePresenceModal: () => void;
}

const RemoveSinglePresenceConfirmationModal: React.FC<RemoveSinglePresenceConfirmationModalProps> = ({
  singlePresenceModalConfig,
  singlePresenceModalOpen,
  toggleSinglePresenceModal,
}) => {
  const intl = useIntl();
  return singlePresenceModalConfig ? (
    <ConfirmationModal
      title={intl.formatMessage({
        defaultMessage: 'Clock out',
        description: 'Presences logout single user modal title',
      })}
      message={intl.formatMessage(
        {
          defaultMessage: 'Are you sure you want to clock out {username}?',
          description: 'Presences logout single user modal message',
        },
        { username: singlePresenceModalConfig.name },
      )}
      isOpen={singlePresenceModalOpen}
      onSuccess={singlePresenceModalConfig?.onSuccess}
      onFailure={() => {
        toggleSinglePresenceModal();
      }}
      data-testid="remove-single-presence-modal"
    />
  ) : null;
};

export default RemoveSinglePresenceConfirmationModal;
