/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
import ActionCable from 'actioncable';
import { ExecutionResult } from 'graphql/execution/execute';
import isEmpty from 'lodash.isempty';
import { Observable, Observer, OperationOptions } from 'subscriptions-transport-ws';
import $$observable from 'symbol-observable';

class ActionCableClient {
  private wsURL: string;

  private cable: ActionCable.Cable | null = null;

  private subscriptions: Map<string, ActionCable.Channel> = new Map<string, ActionCable.Channel>();

  constructor(url: string) {
    this.wsURL = url;
  }

  private addSubscription = (id: string, subscription: ActionCable.Channel) => {
    this.subscriptions.set(id, subscription);
  };

  public connect(token: string, lang: string): void {
    this.cable = ActionCable.createConsumer(`${this.wsURL}?token=${token}&lang=${lang}`);
  }

  public request(request: OperationOptions): Observable<ExecutionResult> {
    const { cable, subscriptions, addSubscription } = this;
    const getObserver = this.getObserver.bind(this);

    let subscription: ActionCable.Channel;

    return {
      [$$observable]() {
        return this;
      },
      subscribe(
        observerOrNext: Observer<ExecutionResult> | ((v: ExecutionResult) => void),
        onError?: (error: Error) => void,
        onComplete?: () => void,
      ) {
        if (cable) {
          const subscriptionId = (request.variables as Record<string, string>)?.id || 'company';
          const observer = getObserver(observerOrNext, onError, onComplete);

          if (!subscriptions.has(subscriptionId)) {
            subscription = cable.subscriptions.create(
              {
                channel: 'GraphqlChannel',
                channelId: Math.round(Date.now() + Math.random() * 100000).toString(16),
              },
              {
                connected() {
                  this.perform('execute', request);
                },
                received(payload: any) {
                  if (!payload.more) {
                    if (observer.complete) {
                      observer.complete();
                    }
                  } else if (payload.result.errors) {
                    if (observer.error) {
                      observer.error(payload.result.errors[0]);
                    }
                  } else if (payload.result.data) {
                    if (observer.next) {
                      if (!isEmpty(payload.result.data)) {
                        observer.next(payload.result);
                      }
                    }
                  }
                },
              },
            );
            addSubscription(subscriptionId, subscription);
          }
        }
        return {
          unsubscribe: () => {
            if (subscription) {
              subscription.unsubscribe();
            }
          },
        };
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private getObserver<T>(
    observerOrNext: Observer<T> | ((v: T) => void),
    error?: (e: Error) => void,
    complete?: () => void,
  ) {
    if (typeof observerOrNext === 'function') {
      return {
        next: (v: T) => observerOrNext(v),
        error: (e: Error) => error && error(e),
        complete: () => complete && complete(),
      };
    }
    return observerOrNext;
  }

  public hasSubscription(id: string): boolean {
    return this.subscriptions.has(id);
  }
}

export default ActionCableClient;
