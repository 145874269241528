/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Instance, types } from 'mobx-state-tree';
import moment from 'moment';
import type { Query } from 'mst-gql';

import { getMutationErrorText, handleQueryError } from '@/helpers/errors';
import { usersPresencesQS } from '@/queries';

import { DatePrimitive } from './types';
import { UserPresenceModelBase } from './UserPresenceModel.base';

/* The TypeScript type of an instance of UserPresenceModel */
export interface UserPresenceModelType extends Instance<typeof UserPresenceModel.Type> {}

/* A graphql query fragment builders for UserPresenceModel */
export {
  selectFromUserPresence,
  userPresenceModelPrimitives,
  UserPresenceModelSelector,
} from './UserPresenceModel.base';

interface RemoveUserPresenceProps {
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

/**
 * UserPresenceModel
 */
export const UserPresenceModel = UserPresenceModelBase.props({
  startedAt: types.union(types.undefined, DatePrimitive),
  finishedAt: types.union(types.undefined, types.null, DatePrimitive),
}).actions((self) => ({
  finish() {
    self.finishedAt = moment().toISOString();
  },
  remove({ optimisticUpdate, revertUpdate, onSuccess, onError }: RemoveUserPresenceProps): Query {
    const query = self.store.mutateRemoveUserPresence(
      { id: self.id },
      usersPresencesQS.REMOVE_USER_PRESENCE_PAYLOAD_ALL,
      () => {
        this.finish();

        if (optimisticUpdate) optimisticUpdate();
      },
    );
    const revert = () => {
      self.finishedAt = null;

      if (revertUpdate) revertUpdate();
    };
    // eslint-disable-next-line promise/catch-or-return
    query.then(
      ({ removeUserPresence }) => {
        const { errors } = removeUserPresence;

        if (errors?.length) {
          if (onError) onError(getMutationErrorText(errors[0]));
          revert();
          return;
        }
        // eslint-disable-next-line promise/always-return
        if (onSuccess) onSuccess();
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
}));
