/* eslint-disable promise/catch-or-return, promise/always-return */
import { Query } from 'mst-gql';

import { getMutationErrorText, handleQueryError, isValidationError } from '@/helpers/errors';
import { OrderModelType, RootStoreType } from '@/models';
import { ordersQS } from '@/queries';

interface ICreateOrderProps {
  rootStore: RootStoreType;
  order: OrderModelType;
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string, isValidationError?: boolean) => void;
}

const create = ({ rootStore, order, optimisticUpdate, revertUpdate, onSuccess, onError }: ICreateOrderProps): Query => {
  const { id, number } = order;

  const query = rootStore.mutateCreateOrder(
    {
      id,
      number: number as string,
    },
    ordersQS.CREATE_ORDER_PAYLOAD_ALL,
    () => {
      rootStore.addOrder(order);

      if (optimisticUpdate) optimisticUpdate();
    },
  );
  const revert = () => {
    if (revertUpdate) revertUpdate();

    rootStore.removeOrder(order);
  };

  query.then(
    ({ createOrder }) => {
      const { errors } = createOrder;

      if (errors?.length) {
        if (onError) onError(getMutationErrorText(errors[0]), isValidationError(errors[0]));
        revert();
        return;
      }
      if (onSuccess) onSuccess();
    },
    (error) => {
      handleQueryError(error, onError);
      revert();
    },
  );
  return query;
};

export default { create };
