/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { QueryBuilder } from "mst-gql"
import { CounterModelType } from "./CounterModel"
import { CounterModelSelector, counterModelPrimitives } from "./CounterModel.base"
import { ItemModelType } from "./ItemModel"
import { ItemModelSelector, itemModelPrimitives } from "./ItemModel.base"
import { MetricValueModelType } from "./MetricValueModel"
import { MetricValueModelSelector, metricValueModelPrimitives } from "./MetricValueModel.base"
import { NotificationModelType } from "./NotificationModel"
import { NotificationModelSelector, notificationModelPrimitives } from "./NotificationModel.base"
import { OrderExecutionModelType } from "./OrderExecutionModel"
import { OrderExecutionModelSelector, orderExecutionModelPrimitives } from "./OrderExecutionModel.base"
import { OrderModelType } from "./OrderModel"
import { OrderModelSelector, orderModelPrimitives } from "./OrderModel.base"
import { ShiftModelType } from "./ShiftModel"
import { ShiftModelSelector, shiftModelPrimitives } from "./ShiftModel.base"
import { StatusChangeModelType } from "./StatusChangeModel"
import { StatusChangeModelSelector, statusChangeModelPrimitives } from "./StatusChangeModel.base"
import { UserPresenceModelType } from "./UserPresenceModel"
import { UserPresenceModelSelector, userPresenceModelPrimitives } from "./UserPresenceModel.base"

export type WorkplaceEventSubjectUnionUnion = CounterModelType | ItemModelType | MetricValueModelType | NotificationModelType | OrderModelType | OrderExecutionModelType | ShiftModelType | StatusChangeModelType | UserPresenceModelType

export class WorkplaceEventSubjectUnionModelSelector extends QueryBuilder {
  counter(builder?: string | CounterModelSelector | ((selector: CounterModelSelector) => CounterModelSelector)) { return this.__inlineFragment(`Counter`, CounterModelSelector, builder) }
  item(builder?: string | ItemModelSelector | ((selector: ItemModelSelector) => ItemModelSelector)) { return this.__inlineFragment(`Item`, ItemModelSelector, builder) }
  metricValue(builder?: string | MetricValueModelSelector | ((selector: MetricValueModelSelector) => MetricValueModelSelector)) { return this.__inlineFragment(`MetricValue`, MetricValueModelSelector, builder) }
  notification(builder?: string | NotificationModelSelector | ((selector: NotificationModelSelector) => NotificationModelSelector)) { return this.__inlineFragment(`Notification`, NotificationModelSelector, builder) }
  order(builder?: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector)) { return this.__inlineFragment(`Order`, OrderModelSelector, builder) }
  orderExecution(builder?: string | OrderExecutionModelSelector | ((selector: OrderExecutionModelSelector) => OrderExecutionModelSelector)) { return this.__inlineFragment(`OrderExecution`, OrderExecutionModelSelector, builder) }
  shift(builder?: string | ShiftModelSelector | ((selector: ShiftModelSelector) => ShiftModelSelector)) { return this.__inlineFragment(`Shift`, ShiftModelSelector, builder) }
  statusChange(builder?: string | StatusChangeModelSelector | ((selector: StatusChangeModelSelector) => StatusChangeModelSelector)) { return this.__inlineFragment(`StatusChange`, StatusChangeModelSelector, builder) }
  userPresence(builder?: string | UserPresenceModelSelector | ((selector: UserPresenceModelSelector) => UserPresenceModelSelector)) { return this.__inlineFragment(`UserPresence`, UserPresenceModelSelector, builder) }
}
export function selectFromWorkplaceEventSubjectUnion() {
  return new WorkplaceEventSubjectUnionModelSelector()
}

// provides all primitive fields of union member types combined together
export const workplaceEventSubjectUnionModelPrimitives = selectFromWorkplaceEventSubjectUnion().counter(counterModelPrimitives).item(itemModelPrimitives).metricValue(metricValueModelPrimitives).notification(notificationModelPrimitives).order(orderModelPrimitives).orderExecution(orderExecutionModelPrimitives).shift(shiftModelPrimitives).statusChange(statusChangeModelPrimitives).userPresence(userPresenceModelPrimitives)