import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { truncate } from 'lodash';
import moment from 'moment';

import { useLastOrderExecution, useLastStatusChange, useOrderConfig } from '@/hooks';
import { useCurrentWorkplace } from '@/hooks/useCurrentWorkplace';
import { AndonLightColor } from '@/models';
import { useWorkplaceData } from '@/providers';
import { ListItem } from '@/types';

import { mapStatusColorToColorString } from './mapStatusColorToColorString';

export const useGetWorkplaceDetailsList = (): ListItem[] => {
  const orderConfig = useOrderConfig();
  const [date, setDate] = useState<Date>(new Date());
  const lastOrderExecution = useLastOrderExecution();
  const lastStatusChange = useLastStatusChange();
  const { currentWorkplace } = useCurrentWorkplace();
  const { data } = useWorkplaceData();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  });

  const intl = useIntl();
  const list: ListItem[] = [];
  list.push({
    label: intl.formatMessage({
      defaultMessage: 'Workplace',
      description: 'Screen saver page details list Workplace id',
    }),
    value: truncate(currentWorkplace?.name, { length: 32 }),
  });
  if (lastStatusChange) {
    list.push(
      {
        label: intl.formatMessage({
          defaultMessage: 'Status',
          description: 'Screen saver page details list status',
        }),
        value: (
          <>
            <span
              style={{
                color: `${mapStatusColorToColorString(lastStatusChange.reason.statusColor as AndonLightColor)}`,
              }}
            >
              {lastStatusChange.reason.name} [
              {lastStatusChange.reason?.parentId
                ? data?.reasons?.find(({ id }) => id === lastStatusChange.reason.parentId)?.name
                : ''}
              ]
            </span>
            &nbsp;({lastStatusChange.durationMinutes(new Date())} min {lastStatusChange.durationSeconds(new Date())}{' '}
            sek)
          </>
        ),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Author',
          description: 'Screen saver page details list author',
        }),
        value: lastStatusChange?.author?.name,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Date and time',
          description: 'Screen saver page details list author',
        }),
        value: `${moment(date).format('YYYY-MM-DD')} ${moment(date).format('HH:mm:ss')}`,
      },
    );
  }
  if (lastOrderExecution?.order.number) {
    list.push({
      label: !!orderConfig?.alternativeName
        ? intl.formatMessage({
            defaultMessage: 'Order number',
            description: 'Screen saver page details list order number alternative text',
          })
        : intl.formatMessage({
            defaultMessage: 'Order number',
            description: 'Screen saver page details list order number',
          }),
      value: lastOrderExecution.order.number,
    });
  }
  if (lastOrderExecution?.product?.number) {
    list.push({
      label: intl.formatMessage({
        defaultMessage: 'Product number',
        description: 'Screen saver page details list product number',
      }),
      value: lastOrderExecution.product.number,
    });
  }

  return list;
};
