import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Container } from 'andoncloud-sdk';
import { Col, Row } from 'styled-bootstrap-grid';

import CompanyConfigContainer from '@/components/CompanyConfig';
import { CenteringContainer } from '@/components/core';
import DeviceConfig from '@/components/DeviceConfig';
import loader from '@/images/loader.svg';
import { useCompanyData, useLocale } from '@/providers';

export const ServicePage: React.FC = (): ReactElement => {
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const intl = useIntl();
  const { toggleLocale } = useLocale();

  const menuItems = [
    {
      label: intl.formatMessage({
        defaultMessage: 'Select workplace',
        description: 'App bar select workplace menu item text',
      }),
      url: `/workplaces`,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Change language to {languageShort}',
          description: 'App bar change language menu item text',
        },
        { languageShort: intl.locale === 'pl' ? 'Angielski' : 'Polish' },
      ),
      action: () => {
        toggleLocale();
      },
    },
  ];

  const companyConfig = companyData?.companyConfig;
  const devicesConfigs = companyData?.companyConfig?.devicesConfigs;
  const workplaces = companyData?.workplaces || [];

  return (
    <Container
      headerProps={{
        position: 'static',
        leadingText: intl.formatMessage({
          defaultMessage: 'Configuration',
          description: 'Service page leading text',
        }),
        userProps: {
          show: false,
        },
        menuProps: {
          items: menuItems,
          buttonColor: 'white',
        },
      }}
    >
      {companyDataLoading ? (
        <CenteringContainer height="90vh">
          <img width="800px" src={loader} alt="loading animation" data-testid="service-page-loader" />
        </CenteringContainer>
      ) : (
        <Row>
          <Col col={6}>{companyConfig && <CompanyConfigContainer companyConfig={companyConfig} />}</Col>
          <Col col={6} style={{ paddingLeft: 0 }}>
            {devicesConfigs && workplaces && <DeviceConfig workplaces={workplaces} devicesConfigs={devicesConfigs} />}
          </Col>
        </Row>
      )}
    </Container>
  );
};
