import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'blank search';
  min-height: 100px;
`;
