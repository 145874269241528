import styled, { css } from 'styled-components';

interface ItemProps {
  textColor: string;
  backgroundColor: string;
  disabled?: boolean;
}

interface MessageIconContainerProps {
  type: 'info' | 'error';
}

interface MessageTitleProps {
  type: 'info' | 'error';
}

export const Container = styled.div`
  height: 155px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  padding: 4px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border-top: 1px solid #717171;
  border-bottom: 1px solid #717171;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.scorpion};
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 115px;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  overflow-x: auto;
`;

export const Item = styled.li<ItemProps>`
  position: relative;
  flex-shrink: 0;
  width: 20vw;
  margin: 10px 0 10px 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      filter: brightness(0.4);
    `}
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    margin-right: 10px;
  }
  > div {
    height: 100%;
    padding: 10px 15px;
    color: ${({ textColor }) => `${textColor} !important`};
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const ItemTitle = styled.p`
  margin: 0;
  padding: 0;
`;

export const ItemSubtitle = styled.p`
  margin: 0;
  font-size: 12px;
  padding: 0;
  white-space: pre-wrap;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  height: calc(100% - 30px);
`;

export const MessageIconContainer = styled.div<MessageIconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin: 0 25px;
  border-radius: 50%;
  background-color: ${({ theme, type }) => (type === 'info' ? theme.colors.skyan : theme.colors.persimmon)};
`;

export const MessageIcon = styled.img``;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const MessageTitle = styled.p<MessageTitleProps>`
  margin: 0;
  font-size: 25px;
  color: ${({ theme, type }) => (type === 'info' ? theme.colors.skyan : theme.colors.persimmon)};
`;

export const MessageText = styled.p`
  margin: 0;
`;
