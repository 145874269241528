import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { ListItem } from '@/types';

import { Container } from '../core';
import List from '../core/List';
import ListLoader from '../loaders/ListLoader';

interface IWorkplaceDetails {
  loading?: boolean;
  workplaceDetailsList: ListItem[];
}

export const WorkplaceDetails: React.FC<IWorkplaceDetails> = ({ loading, workplaceDetailsList }): ReactElement => {
  const intl = useIntl();

  return (
    <Container
      title={intl.formatMessage({
        defaultMessage: 'Details',
        description: 'Screen saver page details container header',
      })}
      style={{ height: '65vh' }}
    >
      {loading ? (
        <ListLoader data-testid="workplace-details-loading" n={5} />
      ) : (
        <List
          rowStyles={{ gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr', minHeight: '100px' }}
          labelStyles={{
            width: '100%',
            borderBottom: '1px solid #cccccc22',
            paddingTop: '7px',
            paddingBottom: '7px',
            fontSize: '22px',
            color: 'rgba(255, 255, 255, 0.5)',
          }}
          valueStyles={{
            height: '100%',
            fontSize: '32px',
            padding: '10px 0 5px 20px',
            letterSpacing: 1.15,
          }}
          items={workplaceDetailsList}
        />
      )}
    </Container>
  );
};
