import type { Moment } from 'moment';
import moment from 'moment';

import type { ShiftModelType, StatusChangeModelType } from '@/models';
import { getInstanceId, HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import type { HistoryDataType, HistoryItem } from '@/types';

export const sortHistoryItems = (items: HistoryItem[]): HistoryItem[] => {
  return items.sort((item1, item2) => item2.date.diff(item1.date) || -1);
};

export const sortHistoryStatuses = (items: StatusChangeModelType[]): StatusChangeModelType[] => {
  return items.sort((item1, item2) => {
    if (item1.finishedAt && item2.finishedAt) return item1.finishedAt.diff(item2.finishedAt);

    return -1;
  });
};

export const getRowKey = (item: HistoryItemModelType): string => {
  return `${item.type}-${item.date?.format('YYYY-MM-DD') || ''}-${item.instance?.id || ''}`;
};

const getCurrentShifts = (firstItemDate: Moment, shifts: ShiftModelType[]): HistoryItem[] => {
  const items: HistoryItem[] = [];

  const setTime = (date: Moment, shiftDate: Moment) => {
    date.set('hours', shiftDate.hours()).set('minutes', shiftDate.minutes()).set('seconds', shiftDate.seconds());
  };

  shifts.forEach((shift, index) => {
    const startedAt = moment(firstItemDate);
    const finishedAt = moment(firstItemDate);

    setTime(startedAt, moment(shift.startedAt));
    setTime(finishedAt, moment(shift.finishedAt));

    if (startedAt.isAfter(finishedAt)) {
      finishedAt.add(1, 'day');
    }
    const nextStartedAt = moment(shifts[(index + 1) % shifts.length].startedAt);

    if (startedAt.isBefore(firstItemDate)) {
      items.push({
        date: startedAt,
        instance: getInstanceId(shift),
        type: HistoryItemType.SHIFT_START,
      });
    }
    if (finishedAt.isBefore(firstItemDate) && !nextStartedAt.isSame(finishedAt)) {
      items.push({
        date: finishedAt,
        instance: getInstanceId(shift),
        type: HistoryItemType.SHIFT_END,
      });
    }
  });
  return sortHistoryItems(items);
};

const mergeData = (data: HistoryDataType[]) => {
  const items: HistoryItem[] = [];

  data.forEach((item) => {
    // eslint-disable-next-line no-underscore-dangle
    if (item.__typename === 'UserPresence') {
      if (item.startedAt && item.finishedAt) {
        items.push({
          date: item.startedAt,
          instance: getInstanceId(item),
          type: HistoryItemType.CHECK_IN,
        });
        items.push({
          date: item.finishedAt,
          instance: getInstanceId(item),
          type: HistoryItemType.CHECK_OUT,
        });
      } else {
        items.push({
          date: moment(item.startedAt),
          instance: getInstanceId(item),
          type: HistoryItemType.CHECK_IN,
        });
      }
      // eslint-disable-next-line no-underscore-dangle
    } else if (item.__typename === 'OrderExecution') {
      if (item.startedAt && item.finishedAt) {
        items.push({
          date: item.startedAt,
          instance: getInstanceId(item),
          type: HistoryItemType.ORDER_EXECUTION_START,
        });
        items.push({
          date: item.finishedAt,
          instance: getInstanceId(item),
          type: HistoryItemType.ORDER_EXECUTION_END,
        });
      } else {
        items.push({
          date: moment(item.startedAt),
          instance: getInstanceId(item),
          type: HistoryItemType.ORDER_EXECUTION_START,
        });
      }
    } else {
      items.push({
        date: moment(item.startedAt),
        instance: getInstanceId(item),
        type: HistoryItemType.STATUS_CHANGE,
      });
    }
  });
  return sortHistoryItems(items);
};

export const prepareHistoryItems = (data: HistoryDataType[], shifts: ShiftModelType[]): HistoryItem[] => {
  const initialItems = mergeData(data);
  const historyItems: HistoryItem[] = [];

  let currentDate = moment(initialItems[0]?.date);
  let currentShifts = getCurrentShifts(currentDate, shifts);

  initialItems.forEach((item, index) => {
    const itemDate = moment(item.date);
    const prevDate = currentDate;
    const currentDateHasChanged = !itemDate.isSame(currentDate, 'day');
    const isLastItem = index === initialItems.length - 1;

    currentDate = itemDate;

    let omittedShift = null;

    if (currentDateHasChanged) {
      if (currentShifts.length) {
        [omittedShift] = currentShifts;
      }
      currentShifts = getCurrentShifts(currentDate, shifts);
    }

    const currentShiftsIndex = currentShifts.findIndex((shift) => shift.date?.isBefore(itemDate));
    const shiftsItems = currentShifts.splice(0, currentShiftsIndex);

    if (omittedShift) {
      historyItems.push(omittedShift);
    }
    if (currentDateHasChanged) {
      historyItems.push({ date: prevDate, type: HistoryItemType.DATE_SEPARATOR } as HistoryItem);
    }
    if (shiftsItems) {
      historyItems.push(...shiftsItems);
    }
    historyItems.push(item);

    if (isLastItem && currentShifts.length) {
      historyItems.push(currentShifts[0]);
    }
  });
  return historyItems;
};
