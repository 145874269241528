import React from 'react';

import loader from '@/images/loader.svg';

import { CenteringContainer } from '..';

interface ILoaderProps {
  width: string;
  'data-testid'?: string;
}

export const Loader: React.FC<ILoaderProps> = ({ width, 'data-testid': dataTestId }) => {
  return (
    <CenteringContainer>
      <img data-testid={dataTestId} alt="loader" width={width} src={loader} />
    </CenteringContainer>
  );
};
