import ContentLoader from 'react-content-loader';

const CardLoader: React.FC = () => (
  <ContentLoader
    speed={1.5}
    width="100%"
    height="100%"
    viewBox="0 0 400 450"
    backgroundColor="transparent"
    foregroundColor="#999"
  >
    <rect x="5" y="45" rx="5" ry="5" width="395" height="400" />
    <rect x="5" y="20" rx="5" ry="5" width="395" height="20" />
  </ContentLoader>
);

export default CardLoader;
