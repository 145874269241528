import { ReasonModelType } from '@/models';

const buildPathToReason = (reasons: ReasonModelType[], reason: ReasonModelType): string => {
  let parentId = reason.parentId;
  let path = '';

  const findParentReason = (reasons: ReasonModelType[], parentId: string) => {
    return reasons.find((reason) => reason.id === parentId);
  };

  while (parentId) {
    const parentReason = findParentReason(reasons, parentId);

    if (parentReason) {
      if (path === '') {
        path = parentReason.name || '';
      } else {
        path = `${path} / ${parentReason.name}`;
      }
      parentId = parentReason.parentId;
    } else {
      parentId = null;
    }
  }
  return path;
};

export default buildPathToReason;
