import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import { useCompanyData } from '@/providers';

import { useStore } from '.';

export const useScreenSaver = (workplaceId?: string): void => {
  const store = useStore();
  const { data, loading } = useCompanyData();
  const navigate = useNavigate();

  const storeStatusChangesLength = Array.from(store.statusChanges).length;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!loading) {
      if (!storeStatusChangesLength || workplaceId === undefined) {
        Cookies.remove('screen-saver_idle-time');
        Cookies.remove('screen-saver_enabled');
        Cookies.remove('screen-saver_display-after-idle');
      } else {
        const idleTime = Cookies.get('screen-saver_idle-time');
        const screenSaverEnabled = Cookies.get('screen-saver_enabled') === 'true';
        const displayAfterIdle = Cookies.get('screen-saver_display-after-idle') === 'true';

        if (idleTime && screenSaverEnabled && displayAfterIdle) {
          let timeoutId: number;
          const clearEvents = () => {
            window.onload = null;
            window.onclick = null;
            document.onkeypress = null;
          };
          const inactivityTime = () => {
            const resetTimer = () => {
              clearTimeout(timeoutId);
              timeoutId = window.setTimeout(() => {
                navigate(`/workplaces/${workplaceId}/screen-saver`);
              }, +idleTime * 1000);
            };
            resetTimer();
            window.onload = resetTimer;
            window.onclick = resetTimer;
            document.onkeypress = resetTimer;
          };
          inactivityTime();
          // eslint-disable-next-line consistent-return
          return () => {
            clearEvents();
            clearTimeout(timeoutId);
          };
        }
      }
    }
  }, [data?.companyConfig, navigate, loading, store.statusChanges, workplaceId, storeStatusChangesLength]);
};

export default useScreenSaver;
