import styled, { css } from 'styled-components';

interface StatusChangeColorProps {
  backgroundColor?: string;
}

export const StatusChangeColor = styled.div<StatusChangeColorProps>`
  width: 12px;
  height: 12px;
  margin: 0 13px;
  flex-shrink: 0;
  border-radius: 50%;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

export const StatusChangeName = styled.span`
  grid-column: span 2 / 4;
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 2px;
  color: rgba(255, 255, 255, 0.5);
`;
