import { createOrderPayloadModelPrimitives } from '@/models/CreateOrderPayloadModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { orderModelPrimitives } from '@/models/OrderModel.base';

export default {
  ALL: orderModelPrimitives.toString(),
  CREATE_ORDER_PAYLOAD_ALL: createOrderPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .order(orderModelPrimitives)
    .toString(),
};
