import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WorkplaceModelType } from '@/models';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

export const useCurrentWorkplace = (): { currentWorkplace: WorkplaceModelType | undefined; workplaceID: string } => {
  const { data, loading } = useCompanyData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [currentWorkplace, setCurrentWorkplace] = useState<WorkplaceModelType>();

  useEffect(() => {
    if (!loading) setCurrentWorkplace(data?.workplaces?.find((workplace) => workplace.id === workplaceID));
  }, [data, loading, workplaceID]);

  return { currentWorkplace, workplaceID };
};
