import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { IconButton, Overlay } from '@/components/core';
import ListItemsLoader from '@/components/loaders/ListItemsLoader';
import { buildPathToReason } from '@/helpers';
import { isTransitionPermitted } from '@/helpers/isTransitionPermitted';
import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { mapStatusColorToTextColor } from '@/helpers/mapStatusColorToTextColor';
import { buildReasonURL } from '@/helpers/urls/buildReasonURL';
import aiIcon from '@/images/ai.svg';
import arrowCollapse from '@/images/arrowCollapse.svg';
import { AndonLightColor, TrainingStatus, useQuery } from '@/models';
import { useStatusChangeTransitionPermissions, useWorkplaceData } from '@/providers';
import { reasonsRecommendationQS } from '@/queries';
import { ReasonsParamTypes } from '@/types';

import {
  Container,
  Header,
  Item,
  ItemContainer,
  ItemContent,
  ItemIcon,
  ItemSubtitle,
  ItemTitle,
  List,
  MessageContainer,
  MessageContent,
  MessageIcon,
  MessageIconContainer,
  MessageText,
  MessageTitle,
} from './styled';

interface RecommendedReasonsListProps {
  workplaceId: string;
  toggleShowRecommendations: () => void;
}

const RecommendedReasonsList: React.FC<RecommendedReasonsListProps> = observer(
  ({ workplaceId, toggleShowRecommendations }) => {
    const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
    const {
      data: { reasons },
    } = useWorkplaceData();
    const { statusChangeTransitionPermissions } = useStatusChangeTransitionPermissions();
    const { data, loading } = useQuery((store) =>
      store.queryReasonsRecommendation({ workplaceId: workplaceId }, reasonsRecommendationQS.ALL),
    );
    const reasonsRecommendationTrainingStatus = data?.reasonsRecommendation.trainingStatus;
    const navigate = useNavigate();
    const intl = useIntl();

    const renderMessage = ({
      icon,
      title,
      text,
      type = 'info',
    }: {
      icon: string;
      title: string;
      text: string;
      type: 'info' | 'error';
    }) => {
      return (
        <MessageContainer>
          <MessageIconContainer type={type}>
            <MessageIcon src={icon} />
          </MessageIconContainer>
          <MessageContent>
            <MessageTitle type={type}>{title}</MessageTitle>
            <MessageText>{text}</MessageText>
          </MessageContent>
        </MessageContainer>
      );
    };

    return (
      <Container>
        <Header>
          <FormattedMessage
            defaultMessage="Statuses proposed based on workplace history"
            description="Reasons recommendation header text"
          />
          <IconButton src={arrowCollapse} onClick={() => toggleShowRecommendations()} />
        </Header>
        {loading ? (
          <ListItemsLoader />
        ) : reasonsRecommendationTrainingStatus === TrainingStatus.TRAINED ? (
          <List>
            {data?.reasonsRecommendation.reasons?.map((reason) => (
              <Item
                key={reason.id}
                textColor={mapStatusColorToTextColor(reason.statusColor as AndonLightColor)}
                backgroundColor={
                  loading ? 'transparent' : mapStatusColorToColorString(reason.statusColor as AndonLightColor)
                }
                onClick={() => {
                  navigate(`${buildReasonURL(workplaceID, reason.id)}?src=ai`);
                }}
                disabled={!isTransitionPermitted({ statusChangeTransitionPermissions, toReasonId: reason.id })}
              >
                <Overlay small>
                  <ItemContainer>
                    <ItemIcon src={aiIcon} />
                    <ItemContent>
                      <ItemTitle>{reason.name}</ItemTitle>
                      <ItemSubtitle>{buildPathToReason(reasons, reason)}</ItemSubtitle>
                    </ItemContent>
                  </ItemContainer>
                </Overlay>
              </Item>
            ))}
          </List>
        ) : reasonsRecommendationTrainingStatus === TrainingStatus.SERVER_ERROR ? (
          renderMessage({
            icon: aiIcon,
            title: intl.formatMessage({
              defaultMessage: 'Oops... something went wrong',
              description: 'Reasons recommendation server error text',
            }),
            text: intl.formatMessage({
              defaultMessage: 'The system encountered an error while fetching the data. Please try again later.',
              description: 'Reasons recommendation server error description text',
            }),
            type: 'error',
          })
        ) : (
          renderMessage({
            icon: aiIcon,
            title: intl.formatMessage({
              defaultMessage: 'Data analysis and collection is ongoing',
              description: 'Reasons recommendation data analysis ongoing text',
            }),
            text: intl.formatMessage({
              defaultMessage:
                'The system collects information about the way of working at this workplace. Recommended statuses will appear here when the system is ready.',
              description: 'Reasons recommendation data analysis ongoing description text',
            }),
            type: 'info',
          })
        )}
      </Container>
    );
  },
);

export default RecommendedReasonsList;
