import { useNavigate, useParams } from 'react-router-dom';

import { logout } from 'andoncloud-sdk';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { Loader } from '@/components/core/Loader';
import ExtendedListItemConsumer from '@/components/ExtendedListItemConsumer';
import { PresencesDetails } from '@/components/PresencesDetails';
import { WorkplaceDetails } from '@/components/WorkplaceDetails';
import WorkplaceFooter from '@/components/WorkplaceFooter';
import { useGetWorkplaceDetailsList } from '@/helpers/useGetWorkplaceDetailsList';
import { useGetWorkplacePresencesList } from '@/helpers/useGetWorkplacePresencesList';
import { useLivetimeKpiList } from '@/hooks/useLivetimeKpiList';
import { ScreenEnum } from '@/models';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

const ScreenSaverPage: React.FC = observer(() => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { data, loading } = useCompanyData();
  const workplaceConfig = data?.companyConfig?.workplacesConfigs?.find((config) => config.workplaceId === workplaceID);
  const defaultScreen = workplaceConfig?.defaultScreen;

  const { items, loading: livetimeKpiLoading } = useLivetimeKpiList();

  const navigate = useNavigate();

  const workplaceDetailsList = useGetWorkplaceDetailsList();
  const presencesList = useGetWorkplacePresencesList(workplaceID);

  if (Cookies.get('screen-saver_auto-sign-out') === 'true') {
    logout();
  }

  const handleStatusContainerClick = () => {
    switch (defaultScreen) {
      case ScreenEnum.STATUS_SCREEN:
        navigate(`/workplaces/${workplaceID}`);
        break;
      case ScreenEnum.STATUSES:
        navigate(`/workplaces/${workplaceID}/reasons`);
        break;
      case ScreenEnum.COUNTERS:
        navigate(`/workplaces/${workplaceID}/counters`);
        break;
      default:
        navigate(-1);
        break;
    }
  };

  return (
    <div style={{ height: '100vh', backgroundColor: '#1E1E1E' }}>
      <Row>
        <Col col={8}>
          {!livetimeKpiLoading && items.length ? (
            <ExtendedListItemConsumer onClick={handleStatusContainerClick} item={items[0]} large />
          ) : (
            <Loader width="500px" />
          )}
        </Col>
        <Col col={4} style={{ paddingLeft: 0 }}>
          <WorkplaceDetails loading={loading} workplaceDetailsList={workplaceDetailsList} />
          <PresencesDetails loading={loading} presencesList={presencesList} />
        </Col>
      </Row>
      <Row>
        <Col col={12}>{workplaceConfig && <WorkplaceFooter workplaceConfig={workplaceConfig} />}</Col>
      </Row>
    </div>
  );
});

export default ScreenSaverPage;
