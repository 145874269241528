/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ReasonModel, ReasonModelType } from "./ReasonModel"
import { ReasonModelSelector } from "./ReasonModel.base"
import { TrainingStatusEnumType } from "./TrainingStatusEnum"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  reasons: IObservableArray<ReasonModelType>;
}

/**
 * ReasonsRecommendationBase
 * auto generated base class for the model ReasonsRecommendationModel.
 */
export const ReasonsRecommendationModelBase = withTypedRefs<Refs>()(ModelBase
  .named('ReasonsRecommendation')
  .props({
    __typename: types.optional(types.literal("ReasonsRecommendation"), "ReasonsRecommendation"),
    reasons: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => ReasonModel)))),
    trainingStatus: types.union(types.undefined, TrainingStatusEnumType),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class ReasonsRecommendationModelSelector extends QueryBuilder {
  get trainingStatus() { return this.__attr(`trainingStatus`) }
  reasons(builder: string | ReasonModelSelector | ((selector: ReasonModelSelector) => ReasonModelSelector) | undefined) { return this.__child(`reasons`, ReasonModelSelector, builder) }
}
export function selectFromReasonsRecommendation() {
  return new ReasonsRecommendationModelSelector()
}

export const reasonsRecommendationModelPrimitives = selectFromReasonsRecommendation().trainingStatus
