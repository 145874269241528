import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

interface ButtonProps {
  $primary?: boolean;
  size?: 'small' | 'normal' | 'large';
  selected?: boolean;
  variant?: 'small' | 'medium' | 'large';
}

export const Button = styled(motion.button).attrs(() => ({
  variants: {
    pressed: { scale: 0.97 },
  },
}))<ButtonProps>`
  appearance: none;
  cursor: pointer;
  width: 100%;
  min-width: 100px;
  margin-top: 20px;
  min-height: 42px;
  font-family: 'Inter', system-ui;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.85);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
  background-color: ${({ theme }) => theme.colors.scorpion};
  transition: all 0.25s;

  ${({ size, selected }) => {
    return (
      size === 'large' &&
      css`
        min-width: unset;
        margin-top: 0px;
        display: flex;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
        border-radius: 4px;
        align-items: center;
        border: none;
        color: rgba(255, 255, 255, 0.85);
        font-weight: 600;
        font-size: 19px;
        line-height: 26px;
        padding: 26px 20px;
        background-color: ${({ theme }) => theme.colors.emperor};
        ${selected &&
        css`
          background: rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.2);
          color: rgba(255, 255, 255, 0.4);
        `}
      `
    );
  }}

  &:hover {
    filter: brightness(1.1);
  }
  &:hover:enabled {
    color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    filter: brightness(0.6);
    cursor: not-allowed;
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: #383838;
      color: ${({ theme }) => theme.colors.white};
      cursor: unset;
    `};
  ${(props) =>
    props.$primary &&
    css`
      background-color: ${({ theme }) => theme.colors.denim};
    `}
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 90%;
      line-height: 2.5em;
    `}
`;

export const ButtonIcon = styled.img`
  vertical-align: middle;
  margin-right: 0.5em;
`;

export const ButtonHint = styled.span`
  float: right;
  color: rgba(255, 255, 255, 0.5);
`;
