import React, { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { notify, Session, useLogin, useLoginStatus } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import { Moment } from 'moment';

import { Button, ButtonHint, CenteringContainer } from '@/components/core';
import List from '@/components/core/List';
import ListLoader from '@/components/loaders/ListLoader';
import WarningOverlay from '@/components/WarningOverlay';
import { createPresencesListItems } from '@/helpers/createPresencesListItems';
import { notifyServerError } from '@/helpers/errors';
import { useCurrentWorkplacePresences } from '@/hooks';
import { useQuery, UserPresenceModelType } from '@/models';
import HistoryItemModel, { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import usersPresencesMutations from '@/mutations/users-presences';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

import { StatusScreenElement, StatusScreenElementHeader, Title } from '../styled';

import RemoveAllPresencesConfirmationModal from './RemoveAllPresencesConfirmationModal';
import RemoveSinglePresenceConfirmationModal, {
  SinglePresenceModalConfig,
} from './RemoveSinglePresenceConfirmationModal';
import { PresencesButtonsContainer } from './styled';

const Presences: React.FC = observer((): ReactElement => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { authResponse } = useLoginStatus();
  const { data, loading } = useCompanyData();
  const { setQuery } = useQuery();
  const [minOperators, setMinOperators] = useState<number>(0);
  const currentWorkplacePresences = useCurrentWorkplacePresences();
  const [currentPresences, setCurrentPresences] = useState<UserPresenceModelType[]>([]);
  const userPresenceExists = (userId: string) =>
    currentPresences.findIndex((presence) => presence.user.id === userId) !== -1;
  const [allPresencesModalOpen, setAllPresencesModalOpen] = useState<boolean>(false);
  const [singlePresenceModalOpen, setSinglePresenceModalOpen] = useState<boolean>(false);
  const [singlePresenceModalConfig, setSinglePresenceModalConfig] = useState<SinglePresenceModalConfig>();
  const intl = useIntl();

  const currentPresencesCount = currentPresences.length;
  const missingOperators = currentPresencesCount < minOperators;

  const presencesButtonsStyles = { textAlign: 'left', padding: '15px', height: '100%', marginTop: 0 } as const;

  const login = useLogin('read write', {
    title: intl.formatMessage({
      defaultMessage: 'Adding presence',
      description: 'Presence auth modal title',
    }),
    subtitle: intl.formatMessage({
      defaultMessage: 'Check-in requires authorization',
      description: 'Presence auth modal subtitle',
    }),
    redirectPath: '#',
    storeSession: false,
    onSuccess: (session: Session) => {
      if (authResponse) {
        if (!userPresenceExists(session.user.id.toString())) {
          setQuery((rootStore) =>
            usersPresencesMutations.create({
              rootStore,
              workplaceId: workplaceID,
              authorData: authResponse.user,
              session,
              optimisticUpdate: (userPresence) => {
                setCurrentPresences((presences) => [...presences, userPresence]);
              },
              revertUpdate: (userPresence) => {
                setCurrentPresences((presences) => presences.filter((presence) => presence.id !== userPresence.id));
              },
              onSuccess: () => {
                notify.success(
                  intl.formatMessage({
                    defaultMessage: 'Presence added',
                    description: 'Presences notify add presence success',
                  }),
                );
              },
              onError: (error) => {
                notifyServerError(error, intl);
              },
            }),
          );
        } else {
          notify.error(
            intl.formatMessage({
              defaultMessage: 'You have already been checked in',
              description: 'Presences notify presence already exists',
            }),
          );
        }
      }
    },
  });

  useEffect(() => {
    if (!loading) {
      setMinOperators(data.workplaces.find((workplace) => workplace.id === workplaceID)?.minOperators || 0);
    }
  }, [workplaceID, data, loading]);

  useEffect(() => {
    if (!loading) {
      setCurrentPresences(currentWorkplacePresences?.filter((userPresence) => !userPresence.finishedAt) || []);
    }
  }, [currentWorkplacePresences, loading]);

  const toggleAllPresencesModal = () => {
    setAllPresencesModalOpen((open) => !open);
  };
  const toggleSinglePresenceModal = () => {
    setSinglePresenceModalOpen((open) => !open);
  };
  const onNewPresenceButtonClick = () => login();

  const onRemoveSinglePresenceButtonClick = (id: string) => {
    const userPresence = currentPresences.find((presence) => presence.id === id);

    toggleSinglePresenceModal();

    if (userPresence) {
      setSinglePresenceModalConfig(() => ({
        name: userPresence.user.name || '',
        onSuccess: () => {
          let createdhistoryItem: HistoryItemModelType;

          setQuery((rootStore) =>
            userPresence.remove({
              optimisticUpdate: () => {
                createdhistoryItem = HistoryItemModel.create({
                  date: userPresence.finishedAt as Moment,
                  instance: castToReferenceSnapshot(userPresence),
                  type: HistoryItemType.CHECK_OUT,
                });
                rootStore.addHistoryItem(createdhistoryItem);

                setCurrentPresences((presences) => presences.filter((presence) => presence.id !== userPresence.id));
              },
              revertUpdate: () => {
                rootStore.removeHistoryItem(createdhistoryItem);
                setCurrentPresences((presences) => [...presences, userPresence]);
              },
              onSuccess: () => {
                notify.success(
                  intl.formatMessage({
                    defaultMessage: 'User presence has been removed',
                    description: 'Remove user presence notify message success',
                  }),
                );
              },
              onError: (error) => {
                notifyServerError(error, intl);
              },
            }),
          );
          toggleSinglePresenceModal();
        },
      }));
    }
  };
  const onRemoveAllPresencesButtonClick = () => {
    toggleAllPresencesModal();
  };

  const handleRemoveAllPresences = () => {
    setQuery((rootStore) =>
      usersPresencesMutations.remove({
        rootStore,
        workplaceId: workplaceID,
        usersPresences: currentPresences,
        optimisticUpdate: () => {
          setCurrentPresences([]);
        },
        revertUpdate: (usersPresences) => {
          setCurrentPresences((presences) => [...presences, ...usersPresences]);
        },
        onSuccess: () => {
          notify.success(
            intl.formatMessage({
              defaultMessage: 'All presences have been removed',
              description: 'Remove all presences notify message success',
            }),
          );
        },
        onError: (error) => {
          notifyServerError(error, intl);
        },
      }),
    );
    toggleAllPresencesModal();
  };

  const renderPresencesButtons = (): ReactElement => {
    return (
      <>
        <Button
          data-testid="presences-header-create-presence-button"
          onClick={onNewPresenceButtonClick}
          style={presencesButtonsStyles}
        >
          {intl.formatMessage({
            defaultMessage: 'Create a presence',
            description: 'Presences header create presence button',
          })}
        </Button>
        <Button
          data-testid="presences-header-remove-all-presences-button"
          onClick={onRemoveAllPresencesButtonClick}
          disabled={!currentPresences?.length}
          style={presencesButtonsStyles}
        >
          {intl.formatMessage({
            defaultMessage: 'Remove all presences',
            description: 'Presences header remove all presences button',
          })}
          {!!currentPresences?.length && (
            <ButtonHint>
              <FormattedMessage
                description="Plural for person"
                defaultMessage={`{count, plural,
                  one {# person}
                  few {# people}
                  many {# people}
                  other {# people}
                }`}
                values={{ count: currentPresencesCount }}
              />
            </ButtonHint>
          )}
        </Button>
      </>
    );
  };

  const renderTooFewOperatorsMessage = () => {
    const message = (
      <FormattedMessage
        description="Too few operators message"
        defaultMessage="Missing {count, plural, one {# person} few {# people} many {# people} other {# people} } to fill the workplace"
        values={{ count: minOperators - currentPresencesCount }}
      />
    );
    return <WarningOverlay message={message} />;
  };

  if (loading) {
    return (
      <StatusScreenElement gridArea="PRESENCES">
        <StatusScreenElementHeader>
          <Title>{intl.formatMessage({ defaultMessage: 'Presences', description: 'Presences header' })}</Title>
        </StatusScreenElementHeader>
        <ListLoader data-testid="presences-list-loader" n={3} />
      </StatusScreenElement>
    );
  }
  return (
    <>
      <StatusScreenElement
        data-testid="PRESENCES-container"
        style={{ gridTemplateRows: 'auto auto 1fr', ...(missingOperators && { paddingBottom: '60px' }) }}
        gridArea="PRESENCES"
      >
        <StatusScreenElementHeader>
          <Title data-testid="presences-header-title">
            {intl.formatMessage({ defaultMessage: 'Presences', description: 'Presences header' })}
          </Title>
        </StatusScreenElementHeader>
        <PresencesButtonsContainer>{renderPresencesButtons()}</PresencesButtonsContainer>
        {currentPresences?.length ? (
          <React.Fragment>
            <List
              rowStyles={{ minHeight: '60px', maxHeight: '100px' }}
              rowHeight="25%"
              data-testid="presences-container"
              items={createPresencesListItems({ intl, presences: currentPresences, onRemoveSinglePresenceButtonClick })}
            />
          </React.Fragment>
        ) : (
          <CenteringContainer>
            {intl.formatMessage({
              defaultMessage: 'No current presences on this workplace',
              description: 'Presences empty list message',
            })}
          </CenteringContainer>
        )}
        {missingOperators && renderTooFewOperatorsMessage()}
      </StatusScreenElement>
      <RemoveAllPresencesConfirmationModal
        allPresencesModalOpen={allPresencesModalOpen}
        toggleAllPresencesModal={toggleAllPresencesModal}
        onSuccess={handleRemoveAllPresences}
      />
      <RemoveSinglePresenceConfirmationModal
        toggleSinglePresenceModal={toggleSinglePresenceModal}
        singlePresenceModalOpen={singlePresenceModalOpen}
        singlePresenceModalConfig={singlePresenceModalConfig}
      />
    </>
  );
});
export default Presences;
