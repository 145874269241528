import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { ClientError } from 'graphql-request';
import mergeWith from 'lodash.mergewith';
import pick from 'lodash.pick';
import { observable, reaction, toJS } from 'mobx';

import { getClientErrorText } from '@/helpers/errors';
import { defaultWorkplaceResults, useCompanyData } from '@/providers/data';
import {
  counterDirectoriesQS,
  countersQS,
  metricValuesQS,
  notificationsQS,
  ordersExecutionsQS,
  reasonsQS,
  statusChangesQS,
  usersPresencesQS,
} from '@/queries';
import { GET_ALL_STATUS_CHANGE_TRANSITION_PERMISSIONS } from '@/queries/status-change-transition-permission';
import { WorkplaceQueriesResults, WorkplaceQueriesResultsRefetchDataKeys } from '@/types';
import graphqlClient from '@/utils/graphql-client';

import { Period, StatusChangeTransitionPermissionModelType, useQuery } from '../models';

interface StatusChangeTransitionPermissionsResults {
  statusChangeTransitionPermissions: StatusChangeTransitionPermissionModelType[];
}

const useQueryWorkplaceData = (workplaceId: string): WorkplaceQueriesResults => {
  const { data: companyData } = useCompanyData();
  const displayedKpiItems = [...(companyData?.companyConfig?.displayedKpiItems || [])];

  const workplace = companyData?.workplaces?.find((workplace) => workplace.id === workplaceId);

  const results = observable({
    counters: useQuery((store) =>
      store.queryCounters({ filter: { workplace_id_equals: workplaceId } }, countersQS.ALL),
    ),
    counterDirectories: useQuery((store) =>
      store.queryCounterDirectories({ filter: { workplace_id_equals: workplaceId } }, counterDirectoriesQS.ALL),
    ),
    metricValues: useQuery((store) =>
      store.queryMetricValues(
        { workplaceIds: workplaceId ? [workplaceId] : [], metricIds: displayedKpiItems },
        metricValuesQS.ALL,
      ),
    ),
    notifications: useQuery((store) =>
      store.queryNotifications(
        { filter: { receiver_id_equals: workplaceId, channel_equals: 'internal', status_equals: 'created' } },
        notificationsQS.ALL,
      ),
    ),
    ordersExecutions: useQuery((store) =>
      store.queryOrdersExecutions(
        { filter: { workplace_id_equals: workplaceId, period: Period.CURRENT_SHIFT } },
        ordersExecutionsQS.ALL,
      ),
    ),
    reasons: useQuery((store) =>
      store.queryReasons({ filter: { reason_group_id_equals: workplace?.reasonGroupId } }, reasonsQS.ALL),
    ),
    statusChanges: useQuery((store) =>
      store.queryStatusChanges(
        { filter: { workplace_id_equals: workplaceId, period: Period.CURRENT_SHIFT } },
        statusChangesQS.ALL,
      ),
    ),
    usersPresences: useQuery((store) =>
      store.queryUsersPresences(
        { filter: { workplace_id_equals: workplaceId, period: Period.CURRENT_SHIFT } },
        usersPresencesQS.ALL,
      ),
    ),
  });
  const [mergedResults, setMergedResults] = useState<WorkplaceQueriesResults>({
    ...defaultWorkplaceResults,
    statusChangeTransitionPermissionsLoaded: false,
    loading: true,
  });
  const fetchStatusChangeTransitionPermissions = useCallback(
    (roleId: string): Promise<StatusChangeTransitionPermissionsResults> => {
      return graphqlClient.request(GET_ALL_STATUS_CHANGE_TRANSITION_PERMISSIONS, {
        filter: { workplace_id_equals: workplaceId, role_id_equals: roleId },
      });
    },
    [workplaceId],
  );

  const intl = useIntl();

  useEffect(() => {
    return reaction(
      () => toJS(results),
      (changedResults) => {
        if (!Object.values(changedResults).some((result) => result.query?.loading)) {
          setMergedResults(
            (currentResults: WorkplaceQueriesResults) =>
              mergeWith(
                { ...currentResults, loading: false },
                ...Object.values(changedResults).map((result) => pick(result.query, ['data', 'loading', 'error'])),
                (obj: unknown, src: unknown) => {
                  if (typeof obj === 'object') {
                    return { ...obj, ...(src as Record<string, unknown>) };
                  }
                  if (typeof obj === 'boolean') {
                    return obj || src;
                  }
                  return src;
                },
              ) as WorkplaceQueriesResults,
          );
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetch = (keys: WorkplaceQueriesResultsRefetchDataKeys) => {
    keys.forEach((key) => results[key].query?.refetch());
  };

  const updateStatusChangeTransitionPermissions = (data: StatusChangeTransitionPermissionsResults) => {
    setMergedResults((currentResults) => ({
      ...currentResults,
      data: {
        ...currentResults.data,
        statusChangeTransitionPermissions: data.statusChangeTransitionPermissions,
      },
      statusChangeTransitionPermissionsLoaded: true,
    }));
  };

  const getStatusChangeTransitionPermissions = useCallback(
    async (roleId: string) => {
      // initialize with empty array
      // default null means that there is no data and data fetching has not started yet
      setMergedResults((currentResults) => ({
        ...currentResults,
        data: {
          ...currentResults.data,
          statusChangeTransitionPermissions: [],
        },
      }));
      const data = await fetchStatusChangeTransitionPermissions(roleId);

      updateStatusChangeTransitionPermissions(data);
    },
    [fetchStatusChangeTransitionPermissions],
  );

  const refetchStatusChangeTransitionPermissions = async (roleId: string) => {
    setMergedResults((currentResults) => {
      return { ...currentResults, statusChangeTransitionPermissionsLoaded: false };
    });
    const data = await fetchStatusChangeTransitionPermissions(roleId);

    updateStatusChangeTransitionPermissions(data);
  };

  useEffect(() => {
    if (!mergedResults.loading) {
      setMergedResults((currentResults) => ({
        ...currentResults,
        statusChangeTransitionPermissionsLoaded: true,
      }));
    }
  }, [mergedResults.loading, getStatusChangeTransitionPermissions]);

  useEffect(() => {
    if (!mergedResults.loading && mergedResults.error) {
      notify.error(
        intl.formatMessage(
          {
            defaultMessage: 'An error occurred while fetching workplaces data: {error}',
            description: 'Workplaces data fetching error message',
          },
          { error: getClientErrorText(mergedResults.error as ClientError) },
        ),
      );
    }
  }, [mergedResults, intl]);

  return {
    ...mergedResults,
    getStatusChangeTransitionPermissions,
    refetchStatusChangeTransitionPermissions,
    refetch,
  };
};

export default useQueryWorkplaceData;
