import greenArrow from '@/images/greenArrow.svg';
import greenGear from '@/images/greenGear.svg';
import redArrow from '@/images/redArrow.svg';
import redGear from '@/images/redGear.svg';
import yellowArrow from '@/images/yellowArrow.svg';
import yellowGear from '@/images/yellowGear.svg';
import { ReasonModelType } from '@/models';

export const sortReasons = (reason1: ReasonModelType, reason2: ReasonModelType): number => {
  if (reason1.position && reason2.position) return reason1.position - reason2.position;
  return 0;
};

export const getReasonIcon = (reason: ReasonModelType): string => {
  if (!reason.leaf) {
    if (reason.statusColor === 'red') return redArrow;
    if (reason.statusColor === 'yellow') return yellowArrow;
    return greenArrow;
  }
  if (reason.statusColor === 'red') return redGear;
  if (reason.statusColor === 'yellow') return yellowGear;
  return greenGear;
};
