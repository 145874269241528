import { useIntl } from 'react-intl';

import { useOrderConfig, useProductConfig } from '@/hooks';
import { StatusChangeModelType } from '@/models';
import { HistoryItemDetail } from '@/types';

const useStatusChangeModalDetails = (status: StatusChangeModelType): HistoryItemDetail[] => {
  const orderConfig = useOrderConfig();
  const productConfig = useProductConfig();
  const intl = useIntl();

  let modalDetails: HistoryItemDetail[] = [];

  if (status.startedAt) {
    modalDetails = [
      {
        label: !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Order number',
              description: 'Status change modal details order number alternative text',
            })
          : intl.formatMessage({
              defaultMessage: 'Order number',
              description: 'Status change modal details order number',
            }),
        value: status?.order?.number,
      },
      {
        label: !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Product number',
              description: 'Status change modal details Product number alternative text',
            })
          : intl.formatMessage({
              defaultMessage: 'Product number',
              description: 'Status change modal details Product number',
            }),
        value: status?.product?.number,
      },

      {
        label: intl.formatMessage({
          defaultMessage: 'Status change beggining date',
          description: 'Status change modal details beggining date',
        }),
        value: status.startedAt.format('YYYY-MM-DD'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Status change beggining time',
          description: 'Status change modal details beggining time',
        }),
        value: status.startedAt.format('HH:mm:ss'),
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Note',
          description: 'Status change modal details note',
        }),
        value: status.note,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Author name',
          description: 'Status change modal details author',
        }),
        value: status.author?.name,
      },
    ];
    if (status.finishedAt) {
      modalDetails.push(
        {
          label: intl.formatMessage({
            defaultMessage: 'Status change ending date',
            description: 'Status change modal details ending date',
          }),
          value: status.finishedAt.format('YYYY-MM-DD'),
        } as HistoryItemDetail,
        {
          label: intl.formatMessage({
            defaultMessage: 'Status change ending time',
            description: 'Status change modal details ending time',
          }),
          value: status.finishedAt.format('HH:mm:ss'),
        } as HistoryItemDetail,
      );
    }
  }
  return modalDetails;
};

export default useStatusChangeModalDetails;
