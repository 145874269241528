import { ButtonImg, ButtonText, IconButton as StyledIconButton } from './styled';

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  src: string;
  size?: 'small' | 'normal';
  text?: string;
  'data-testid'?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  src,
  size,
  text,
  onClick,
  disabled,
  'data-testid': dataTestID,
}) => (
  <StyledIconButton
    type="button"
    size={size}
    hasText={!!text}
    whileTap="pressed"
    onClick={onClick}
    disabled={disabled}
    data-testid={dataTestID}
  >
    {!!text && <ButtonText>{text}</ButtonText>}
    <ButtonImg src={src} alt={text} size={size} />
  </StyledIconButton>
);
