/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FieldConfigModel, FieldConfigModelType } from "./FieldConfigModel"
import { FieldConfigModelSelector } from "./FieldConfigModel.base"
import { RootStoreType } from "./index"


/**
 * ProductConfigBase
 * auto generated base class for the model ProductConfigModel.
 */
export const ProductConfigModelBase = ModelBase
  .named('ProductConfig')
  .props({
    __typename: types.optional(types.literal("ProductConfig"), "ProductConfig"),
    alternativeName: types.union(types.undefined, types.boolean),
    formFieldsConfigs: types.union(types.undefined, types.array(types.late((): any => FieldConfigModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ProductConfigModelSelector extends QueryBuilder {
  get alternativeName() { return this.__attr(`alternativeName`) }
  formFieldsConfigs(builder: string | FieldConfigModelSelector | ((selector: FieldConfigModelSelector) => FieldConfigModelSelector) | undefined) { return this.__child(`formFieldsConfigs`, FieldConfigModelSelector, builder) }
}
export function selectFromProductConfig() {
  return new ProductConfigModelSelector()
}

export const productConfigModelPrimitives = selectFromProductConfig().alternativeName
