/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MutationErrorModel, MutationErrorModelType } from "./MutationErrorModel"
import { MutationErrorModelSelector } from "./MutationErrorModel.base"
import { OrderExecutionModel, OrderExecutionModelType } from "./OrderExecutionModel"
import { OrderExecutionModelSelector } from "./OrderExecutionModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  orderExecution: OrderExecutionModelType;
}

/**
 * StartOrderExecutionPayloadBase
 * auto generated base class for the model StartOrderExecutionPayloadModel.
 *
 * Autogenerated return type of StartOrderExecution
 */
export const StartOrderExecutionPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('StartOrderExecutionPayload')
  .props({
    __typename: types.optional(types.literal("StartOrderExecutionPayload"), "StartOrderExecutionPayload"),
    errors: types.union(types.undefined, types.null, types.array(types.late((): any => MutationErrorModel))),
    orderExecution: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => OrderExecutionModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class StartOrderExecutionPayloadModelSelector extends QueryBuilder {
  errors(builder: string | MutationErrorModelSelector | ((selector: MutationErrorModelSelector) => MutationErrorModelSelector) | undefined) { return this.__child(`errors`, MutationErrorModelSelector, builder) }
  orderExecution(builder: string | OrderExecutionModelSelector | ((selector: OrderExecutionModelSelector) => OrderExecutionModelSelector) | undefined) { return this.__child(`orderExecution`, OrderExecutionModelSelector, builder) }
}
export function selectFromStartOrderExecutionPayload() {
  return new StartOrderExecutionPayloadModelSelector()
}

export const startOrderExecutionPayloadModelPrimitives = selectFromStartOrderExecutionPayload()
