/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MutationErrorModel, MutationErrorModelType } from "./MutationErrorModel"
import { MutationErrorModelSelector } from "./MutationErrorModel.base"
import { RootStoreType } from "./index"


/**
 * RemoveWorkplaceUsersPresencesPayloadBase
 * auto generated base class for the model RemoveWorkplaceUsersPresencesPayloadModel.
 *
 * Autogenerated return type of RemoveWorkplaceUsersPresences
 */
export const RemoveWorkplaceUsersPresencesPayloadModelBase = ModelBase
  .named('RemoveWorkplaceUsersPresencesPayload')
  .props({
    __typename: types.optional(types.literal("RemoveWorkplaceUsersPresencesPayload"), "RemoveWorkplaceUsersPresencesPayload"),
    errors: types.union(types.undefined, types.null, types.array(types.late((): any => MutationErrorModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class RemoveWorkplaceUsersPresencesPayloadModelSelector extends QueryBuilder {
  errors(builder: string | MutationErrorModelSelector | ((selector: MutationErrorModelSelector) => MutationErrorModelSelector) | undefined) { return this.__child(`errors`, MutationErrorModelSelector, builder) }
}
export function selectFromRemoveWorkplaceUsersPresencesPayload() {
  return new RemoveWorkplaceUsersPresencesPayloadModelSelector()
}

export const removeWorkplaceUsersPresencesPayloadModelPrimitives = selectFromRemoveWorkplaceUsersPresencesPayload()
