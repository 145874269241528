/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum StatusScreenDisplayBlocksEnum {
  KPI="KPI",
LIVETIME="LIVETIME",
ORDERS="ORDERS",
PRESENCES="PRESENCES"
}

/**
* StatusScreenDisplayBlocksEnum
*/
export const StatusScreenDisplayBlocksEnumType = types.enumeration("StatusScreenDisplayBlocksEnum", [
        "KPI",
  "LIVETIME",
  "ORDERS",
  "PRESENCES",
      ])
