import styled from 'styled-components';

import { theme } from '@/theme';

export const PresencesButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: ${theme.colors.mineSchaftDark};
  grid-gap: 10px;
  margin-top: 5px;
  padding: 20px;
  height: 100px;
  border-bottom: 1px solid #424242;
`;
