/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  author: UserModelType;
  user: UserModelType;
}

/**
 * UserPresenceBase
 * auto generated base class for the model UserPresenceModel.
 */
export const UserPresenceModelBase = withTypedRefs<Refs>()(ModelBase
  .named('UserPresence')
  .props({
    __typename: types.optional(types.literal("UserPresence"), "UserPresence"),
    author: types.union(types.undefined, MSTGQLRef(types.late((): any => UserModel))),
    finishedAt: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    startedAt: types.union(types.undefined, types.string),
    user: types.union(types.undefined, MSTGQLRef(types.late((): any => UserModel))),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class UserPresenceModelSelector extends QueryBuilder {
  get finishedAt() { return this.__attr(`finishedAt`) }
  get id() { return this.__attr(`id`) }
  get startedAt() { return this.__attr(`startedAt`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
  author(builder: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector) | undefined) { return this.__child(`author`, UserModelSelector, builder) }
  user(builder: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector) | undefined) { return this.__child(`user`, UserModelSelector, builder) }
}
export function selectFromUserPresence() {
  return new UserPresenceModelSelector()
}

export const userPresenceModelPrimitives = selectFromUserPresence().finishedAt.startedAt.workplaceId
