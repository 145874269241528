import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';

import { ColumnHeader } from '../Reasons/ReasonsColumn/styled';

const Row = () => (
  <ContentLoader style={{}} width="100%" height={70} speed={1.5} backgroundColor="#282828" foregroundColor="#999">
    {/* Only SVG shapes */}
    <rect x="20" y="45" rx="4" ry="4" width="87%" height="10" />
  </ContentLoader>
);

export const LoadingColumn = (): ReactElement => (
  <div
    data-testid="column-loading"
    style={{ width: '33.33%', height: '100%', borderRight: '1px solid rgba(255, 255, 255, 0.3)' }}
  >
    <ColumnHeader data-testid="column-title-loading">
      <FormattedMessage defaultMessage="Loading..." description="Reasons column loading text" />
    </ColumnHeader>
    <Row />
    <br />
    <Row />
    <br />
    <Row />
    <br />
    <Row />
    <br />
    <Row />
    <br />
    <Row />
  </div>
);
