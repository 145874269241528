import { toJS } from 'mobx';

import { useStore } from '@/hooks';
import { ListItem } from '@/types';

// TODO remove workplaceID param
export const useGetWorkplacePresencesList = (workplaceID: string): ListItem[] => {
  const store = useStore();
  const storePresences = toJS(store.userPresences);
  const list: ListItem[] = [];
  storePresences.forEach((presence) => {
    if (presence.workplaceId === workplaceID && !presence.finishedAt) {
      list.push({ label: presence.user.name || '' });
    }
  });
  return list;
};
